// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Validation of ChangeLogEntry payload.
 * @see #/components/schemas/ChangeLogEntryRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface ChangeLogEntryRequest {
    /**
     * The name of changing Model.  * `AdditionalDevice` - Additional Devices * `Anamnesis` - Anamnesis * `AllergyIntolerance` - Allergy Intolerance * `BalanceTarget` - Balance Target * `BasicCare` - Basic Care * `DailyGoal` - Daily Goal * `Diagnosis` - Diagnosis * `Encounter` - Encounter * `EntryControl` - Entry Control * `LabValue` - Lab Value * `MedicationAdministration` - Medication Administration * `MedicationPrescription` - Medication Prescription * `Output` - Output * `Patient` - Patient * `PatientHistory` - Patient History * `PhysicalExamination` - Physical Examination * `ProcedurePrescription` - Procedure Prescription * `Procedure` - Procedure * `PreMedication` - Premedication * `VentilationParameter` - Ventilation Parameter * `VentilationProcedure` - Ventilation Procedure * `VitalSign` - Vital Sign * `WorkflowQuestionnaire` - Workflow Questionnaire * `WorkflowQuestionnaireResponse` - Workflow Questionnaire Response * `WoundStatus` - Wound Status * `InfectionStatus` - Infection Status * `SurgeryPrescription` - Surgery Prescription * `TherapyLimitations` - Therapy Limitations * `VaccinationStatus` - Vaccination Status * `Valuables` - Valuables
     *
     */
    model: ChangeLogEntryRequest.ModelEnum;
    /**
     * Model id for ChangeLogEntry.
     * @dataFormat uuid
     */
    model_id: string;
    /**
     * Encounter id for ChangedLogEntry.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner Id for persisted and discarded ChangedLogEntry
     * @dataFormat uuid
     */
    practitioner_id?: string | null;
    /**
     * Name of operation applied to model.  * `create` - Create * `update` - Update * `delete` - Delete
     *
     */
    operation: ChangeLogEntryRequest.OperationEnum;
    /**
     * Difference between old model and changed model.
     *
     */
    payload?: { [key: string]: any };
    /**
     * Status of staged changes.  * `unpersisted` - Unpersisted * `persisted` - Persisted * `discarded` - Discarded
     *
     */
    status?: ChangeLogEntryRequest.StatusEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for ChangeLogEntryRequest
 */
export namespace ChangeLogEntryRequest {
    export type ModelEnum =
        | 'AdditionalDevice'
        | 'Anamnesis'
        | 'AllergyIntolerance'
        | 'BalanceTarget'
        | 'BasicCare'
        | 'DailyGoal'
        | 'Diagnosis'
        | 'Encounter'
        | 'EntryControl'
        | 'LabValue'
        | 'MedicationAdministration'
        | 'MedicationPrescription'
        | 'Output'
        | 'Patient'
        | 'PatientHistory'
        | 'PhysicalExamination'
        | 'ProcedurePrescription'
        | 'Procedure'
        | 'PreMedication'
        | 'VentilationParameter'
        | 'VentilationProcedure'
        | 'VitalSign'
        | 'WorkflowQuestionnaire'
        | 'WorkflowQuestionnaireResponse'
        | 'WoundStatus'
        | 'InfectionStatus'
        | 'SurgeryPrescription'
        | 'TherapyLimitations'
        | 'VaccinationStatus'
        | 'Valuables';
    export const ModelEnum = {
        AdditionalDevice: 'AdditionalDevice' as ModelEnum,
        Anamnesis: 'Anamnesis' as ModelEnum,
        AllergyIntolerance: 'AllergyIntolerance' as ModelEnum,
        BalanceTarget: 'BalanceTarget' as ModelEnum,
        BasicCare: 'BasicCare' as ModelEnum,
        DailyGoal: 'DailyGoal' as ModelEnum,
        Diagnosis: 'Diagnosis' as ModelEnum,
        Encounter: 'Encounter' as ModelEnum,
        EntryControl: 'EntryControl' as ModelEnum,
        LabValue: 'LabValue' as ModelEnum,
        MedicationAdministration: 'MedicationAdministration' as ModelEnum,
        MedicationPrescription: 'MedicationPrescription' as ModelEnum,
        Output: 'Output' as ModelEnum,
        Patient: 'Patient' as ModelEnum,
        PatientHistory: 'PatientHistory' as ModelEnum,
        PhysicalExamination: 'PhysicalExamination' as ModelEnum,
        ProcedurePrescription: 'ProcedurePrescription' as ModelEnum,
        Procedure: 'Procedure' as ModelEnum,
        PreMedication: 'PreMedication' as ModelEnum,
        VentilationParameter: 'VentilationParameter' as ModelEnum,
        VentilationProcedure: 'VentilationProcedure' as ModelEnum,
        VitalSign: 'VitalSign' as ModelEnum,
        WorkflowQuestionnaire: 'WorkflowQuestionnaire' as ModelEnum,
        WorkflowQuestionnaireResponse: 'WorkflowQuestionnaireResponse' as ModelEnum,
        WoundStatus: 'WoundStatus' as ModelEnum,
        InfectionStatus: 'InfectionStatus' as ModelEnum,
        SurgeryPrescription: 'SurgeryPrescription' as ModelEnum,
        TherapyLimitations: 'TherapyLimitations' as ModelEnum,
        VaccinationStatus: 'VaccinationStatus' as ModelEnum,
        Valuables: 'Valuables' as ModelEnum,
    };
    export type OperationEnum = 'create' | 'update' | 'delete';
    export const OperationEnum = {
        Create: 'create' as OperationEnum,
        Update: 'update' as OperationEnum,
        Delete: 'delete' as OperationEnum,
    };
    export type StatusEnum = 'unpersisted' | 'persisted' | 'discarded';
    export const StatusEnum = {
        Unpersisted: 'unpersisted' as StatusEnum,
        Persisted: 'persisted' as StatusEnum,
        Discarded: 'discarded' as StatusEnum,
    };

    /**
     * ChangeLogEntryRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The name of changing Model.  * `AdditionalDevice` - Additional Devices * `Anamnesis` - Anamnesis * `AllergyIntolerance` - Allergy Intolerance * `BalanceTarget` - Balance Target * `BasicCare` - Basic Care * `DailyGoal` - Daily Goal * `Diagnosis` - Diagnosis * `Encounter` - Encounter * `EntryControl` - Entry Control * `LabValue` - Lab Value * `MedicationAdministration` - Medication Administration * `MedicationPrescription` - Medication Prescription * `Output` - Output * `Patient` - Patient * `PatientHistory` - Patient History * `PhysicalExamination` - Physical Examination * `ProcedurePrescription` - Procedure Prescription * `Procedure` - Procedure * `PreMedication` - Premedication * `VentilationParameter` - Ventilation Parameter * `VentilationProcedure` - Ventilation Procedure * `VitalSign` - Vital Sign * `WorkflowQuestionnaire` - Workflow Questionnaire * `WorkflowQuestionnaireResponse` - Workflow Questionnaire Response * `WoundStatus` - Wound Status * `InfectionStatus` - Infection Status * `SurgeryPrescription` - Surgery Prescription * `TherapyLimitations` - Therapy Limitations * `VaccinationStatus` - Vaccination Status * `Valuables` - Valuables
         * @type string (`dataFormat` is missing)
         */
        model: ChangeLogEntryRequest.ModelEnum;
        /**
         * Model id for ChangeLogEntry.
         * @dataFormat uuid
         */
        modelId: string;
        /**
         * Encounter id for ChangedLogEntry.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner Id for persisted and discarded ChangedLogEntry
         * @dataFormat uuid
         */
        practitionerId?: string | null;
        /**
         * Name of operation applied to model.  * `create` - Create * `update` - Update * `delete` - Delete
         * @type string (`dataFormat` is missing)
         */
        operation: ChangeLogEntryRequest.OperationEnum;
        /**
         * Difference between old model and changed model.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        payload?: { [key: string]: any };
        /**
         * Status of staged changes.  * `unpersisted` - Unpersisted * `persisted` - Persisted * `discarded` - Discarded
         * @type string (`dataFormat` is missing)
         */
        status?: ChangeLogEntryRequest.StatusEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * ChangeLogEntryRequest DTO
     * Transforms ChangeLogEntryRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend ChangeLogEntryRequest model */
        static toModel(obj: ChangeLogEntryRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend ChangeLogEntryRequest model */
        static toApi(obj: Model): ChangeLogEntryRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as ChangeLogEntryRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
