import { NgModule } from '@angular/core';
import { KeyboardModule } from '@mona/keyboard';
import { UiModule } from '@mona/ui';
import { WrongUserPersistDialogComponent } from './wrong-user-persist-dialog.component';

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [WrongUserPersistDialogComponent],
    exports: [WrongUserPersistDialogComponent],
    imports: [UiModule, KeyboardModule],
})
export class WrongUserPersistDialogModule {}
