// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Base Serializer for all Serializer implementations in Terminology.
 * @see #/components/schemas/MedicationTerminologyCreateRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationTerminologyCreateRequest {
    /**
     * The display name of Medicine
     *
     */
    display_name: string;
    /**
     * The practitioner.
     * @dataFormat uuid
     */
    last_changed_by: string;
    /**
     * The medication group Id.
     * @dataFormat uuid
     */
    group: string;
    /**
     * The category code.
     *
     */
    category_code: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationTerminologyCreateRequest
 */
export namespace MedicationTerminologyCreateRequest {
    /**
     * MedicationTerminologyCreateRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The display name of Medicine
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The practitioner.
         * @dataFormat uuid
         */
        lastChangedBy: string;
        /**
         * The medication group Id.
         * @dataFormat uuid
         */
        group: string;
        /**
         * The category code.
         * @type string (`dataFormat` is missing)
         */
        categoryCode: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationTerminologyCreateRequest DTO
     * Transforms MedicationTerminologyCreateRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationTerminologyCreateRequest model */
        static toModel(obj: MedicationTerminologyCreateRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationTerminologyCreateRequest model */
        static toApi(obj: Model): MedicationTerminologyCreateRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationTerminologyCreateRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
