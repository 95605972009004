import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, pluck, switchMap, take, tap } from 'rxjs/operators';
import { AuthFlow, AuthService } from '@mona/auth';
import { ConfigService } from '@mona/config';
import { FeatureFlagsService, FEATURE_FLAGS } from '@mona/flags';
import { Logger } from '@mona/shared/logger';
// INFO: this `eslint-disable` is allowed, to avoid circular dependency caused by barrel files
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AppError, NAVIGATION, NavigationService, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { DialogService } from '@mona/ui';
import { HealthStateMap } from '../../../../entities';
import { ApiHealthFacade } from '../../../../services';
import { ServerUrlDialogComponent, ServerUrlDialogData } from '../../../server-url-dialog';

/**
 * Diagnostics component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-diagnostics',
    templateUrl: './diagnostics.component.html',
    styleUrls: ['./diagnostics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticsComponent implements OnInit, OnDestroy {
    /**
     * Host css class
     */
    @HostBinding('class.mona-diagnostics') cmpClass = true;

    /**
     * Is core only check
     */
    @Input() isCoreOnlyCheck = false;
    /**
     * Allow change urls
     */
    @Input() allowChangeUrls = true;

    /**
     * Emits when if core is available / not available
     */
    @Output() coreAvailableChange: EventEmitter<boolean> = new EventEmitter();

    /**
     * Internet connection status
     */
    online$: Observable<boolean> = this.apiHealthFacade.online$;

    /** API HealthState Map */
    healthStateMap$: Observable<HealthStateMap> = this.apiHealthFacade.healthStateMap$;

    /**
     * Config
     */
    get config$() {
        return this.configService.config$;
    }

    /**
     * Is running diagnostics
     */
    isLoading$: Observable<boolean> = this.apiHealthFacade.healthStateMap$.pipe(
        map(healthStateMap => {
            return Object.values(healthStateMap).some(hs => hs.isLoading);
        }),
    );

    /**
     * RFID test string
     */
    rfidTestSerial: string;

    /**
     * Is camera checked
     */
    isCameraChecked: boolean;

    /**
     * Is sound checked
     */
    isSoundChecked: boolean;

    /**
     * Has telemedicine check
     */
    get hasTelemedicineCheck(): boolean {
        const {
            license: { telemedicine },
            features: { telemedicine: isTelemedicineEnabled },
        } = this.configService.config;
        return telemedicine && isTelemedicineEnabled;
    }

    selectedTabIndex = 0;

    private readonly logger = new Logger();

    /**
     * Constructor
     *
     * @param cdRef
     * @param navigationService
     * @param featureFlagService
     * @param apiHealthFacade
     * @param configService
     * @param authService
     * @param dialogService
     */
    constructor(
        private cdRef: ChangeDetectorRef,
        @Inject(NAVIGATION) private navigationService: NavigationService,
        @Inject(FEATURE_FLAGS) public featureFlagService: FeatureFlagsService,
        private apiHealthFacade: ApiHealthFacade,
        private configService: ConfigService,
        private authService: AuthService,
        private dialogService: DialogService,
    ) {}

    /**
     * NG Hook
     */
    ngOnInit(): void {
        this.runDiagnostics();
        this.emitCoreAvailabilityChange();
    }

    /**
     * NG Hook
     */
    ngOnDestroy() {
        this.apiHealthFacade.resetApiHealthWS();
    }

    /**
     * Run diagnostics
     */
    runDiagnostics(): void {
        const { baseUrl, coreWsUrl, telemedicineUrl, telemedicineWsUrl } = this.configService.get('api');
        this.apiHealthFacade.loadApiHealth(baseUrl);
        this.apiHealthFacade.checkApiHealthWS(coreWsUrl);

        if (!this.isCoreOnlyCheck) {
            if (this.hasTelemedicineCheck) {
                this.apiHealthFacade.loadApiHealth(telemedicineUrl);
                this.apiHealthFacade.checkApiHealthWS(telemedicineWsUrl);
            }
        }
    }

    /**
     * Show error details
     *
     * @param error
     */
    showErrorDetails(error: AppError | any): void {
        const appError = error instanceof AppError || error.originalError ? error : new AppError(error);
        this.dialogService.showErrorDialog(appError);
    }

    /**
     * Change core server url
     */
    changeCoreUrl(): void {
        const { baseUrl } = this.configService.get('api');
        const data: ServerUrlDialogData = {
            service: 'core',
            url: baseUrl,
            title: 'apps.settings.device.coreServerDialog.title',
            label: 'apps.settings.device.coreServerDialog.label',
            description: 'apps.settings.device.coreServerDialog.description',
            confirmBtn: 'apps.settings.device.coreServerDialog.confirm',
            cancelBtn: 'apps.settings.device.coreServerDialog.cancel',
            hintLabel: 'apps.settings.device.coreServerDialog.hint',
        };

        this.dialogService.open(ServerUrlDialogComponent, data).subscribe(({ url, wsUrl }) => {
            if (url) {
                if (baseUrl !== url) {
                    this.configService.update({
                        api: {
                            baseUrl: url,
                            coreWsUrl: wsUrl,
                        },
                    });
                    this.runDiagnostics();
                }
            }
        });
    }

    /**
     * Change telemedicine server url
     */
    changeTelemedicineUrl(): void {
        const { telemedicineUrl, telemedicineWsUrl } = this.configService.get('api');
        const data: ServerUrlDialogData = {
            service: 'telemedicine',
            url: telemedicineUrl,
            wsUrl: telemedicineWsUrl,
            title: 'apps.settings.device.telemedicineServerDialog.title',
            label: 'apps.settings.device.telemedicineServerDialog.label',
            description: 'apps.settings.device.telemedicineServerDialog.description',
            confirmBtn: 'apps.settings.device.telemedicineServerDialog.confirm',
            cancelBtn: 'apps.settings.device.telemedicineServerDialog.cancel',
            hintLabel: 'apps.settings.device.telemedicineServerDialog.hint',
        };

        this.dialogService.open(ServerUrlDialogComponent, data).subscribe(({ url, wsUrl }) => {
            if (url && wsUrl) {
                if (url !== telemedicineUrl && wsUrl !== telemedicineWsUrl) {
                    this.configService.update({
                        api: {
                            telemedicineUrl: url,
                            telemedicineWsUrl: wsUrl,
                        },
                    });
                    this.runDiagnostics();
                }
            }
        });
    }

    /**
     * Check rfid reader
     */
    checkRfidReader(): void {
        this.authService
            .authenticate(AuthFlow.verify, 0, true)
            .pipe(take(1))
            .subscribe(({ user }) => {
                if (user.rfid) {
                    this.rfidTestSerial = user.rfid;
                }
                this.cdRef.markForCheck();
            });
    }

    /**
     * Check camera
     */
    checkCamera(): void {
        this.navigationService
            .navigateToAuxilaryOutlet(['media', 'camera'], 'dialog', {})
            .afterClosed()
            .subscribe(({ result }) => {
                if (result) {
                    this.isCameraChecked = true;
                }
                this.cdRef.markForCheck();
            });
    }

    /**
     * Check sound
     */
    checkSound(): void {
        this.navigationService
            .navigateToAuxilaryOutlet(['media', 'microphone'], 'dialog', {})
            .afterClosed()
            .subscribe(({ result }) => {
                if (result) {
                    this.isSoundChecked = true;
                }
                this.cdRef.markForCheck();
            });
    }

    /**
     * Emits to outside when core availability is changed
     */
    private emitCoreAvailabilityChange(): void {
        this.configService
            .select('api.baseUrl')
            .pipe(
                switchMap(serverUrl => this.apiHealthFacade.healthStateByServer$(serverUrl).pipe(pluck('isConnected'))),
                distinctUntilChanged(),
                takeUntilDestroy(this),
            )
            .subscribe(this.coreAvailableChange);
    }
}
