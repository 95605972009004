// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Report Image from database.
 * @see #/components/schemas/ReportImage - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface ReportImage {
    /**
     * The Report Image Id
     * @dataFormat uuid
     */
    readonly id: string;
    /**
     * The image type (unique)
     *
     */
    image_type: string;
    /**
     * Base64 coded image
     *
     */
    image: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for ReportImage
 */
export namespace ReportImage {
    /**
     * ReportImage Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Report Image Id
         * @dataFormat uuid
         */
        readonly id: string;
        /**
         * The image type (unique)
         * @type string (`dataFormat` is missing)
         */
        imageType: string;
        /**
         * Base64 coded image
         * @type string (`dataFormat` is missing)
         */
        image: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * ReportImage DTO
     * Transforms ReportImage model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend ReportImage model */
        static toModel(obj: ReportImage): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend ReportImage model */
        static toApi(obj: Model): ReportImage {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as ReportImage;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
