import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { PageableModel, ParamsModel } from '@mona/models';
import { transformApiAdditionalDevices } from './transforms';

const baseApi = `/pdms`;

/**
 * API abstraction layer for the Additional Devices Parameters Api
 */
@Injectable({
    providedIn: 'root',
})
export class AdditionalDevicesApi {
    private readonly api = `${baseApi}/v2/additional-devices/`;

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get Pageable Vital Signs
     *
     * @param params
     * @param url
     */
    // PageableModel<AdditionalDevice> & { codes: string[] }
    getPageableAdditionalDevices(params?: ParamsModel, url: string = this.api): Observable<any> {
        return this.http.get<PageableModel<ApiModels.AdditionalDevice>>(url, { params }).pipe(
            map(({ results, ...rest }) => {
                return {
                    ...rest,
                    results: transformApiAdditionalDevices(results),
                };
            }),
        );
    }
}
