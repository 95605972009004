import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { AuthService } from '../../services';

/**
 * update RFID component
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-update-rfid',
    templateUrl: './update-rfid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateRfidComponent implements OnInit {
    /**
     * Constructor
     *
     * @param authService AuthService
     * @param dialogRef
     */
    constructor(private authService: AuthService, private dialogRef: MatDialogRef<any>) {}

    /** Lifecycle */
    ngOnInit(): void {
        this.watchRfidScan();
    }

    /** Listens rfid event (1) end closes dialog */
    watchRfidScan() {
        this.authService.rfidScanned$.pipe(take(1), takeUntilDestroy(this)).subscribe(rfid => {
            this.dialogRef.close(rfid);
        });
    }

    /** Close dialog */
    close() {
        this.dialogRef.close();
    }
}
