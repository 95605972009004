// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating VentilationParameter model structure.
 * @see #/components/schemas/PdmsVentilationParameter - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PdmsVentilationParameter {
    /**
     * The actual value.
     *
     */
    value: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The date when value was created.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The SNOMED code that represents the Ventilation-Parameter.
     *
     */
    code: string;
    /**
     * The unit of the value.
     *
     */
    unit?: string | null;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Ventilation Parameter unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PdmsVentilationParameter
 */
export namespace PdmsVentilationParameter {
    /**
     * PdmsVentilationParameter Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The actual value.
         * @type string (`dataFormat` is missing)
         */
        value: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The date when value was created.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The SNOMED code that represents the Ventilation-Parameter.
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The unit of the value.
         * @type string (`dataFormat` is missing)
         */
        unit?: string | null;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Ventilation Parameter unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PdmsVentilationParameter DTO
     * Transforms PdmsVentilationParameter model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PdmsVentilationParameter model */
        static toModel(obj: PdmsVentilationParameter): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PdmsVentilationParameter model */
        static toApi(obj: Model): PdmsVentilationParameter {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PdmsVentilationParameter;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
