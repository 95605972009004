// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * A serializer mixin which provides a nullable note field. This can be used to support explicit reset for note. This is meant to be used with update operations as only with update operations we can get an explicit reset.
 * @see #/components/schemas/BasicCare - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface BasicCare {
    /**
     * A free-text comment.
     *
     */
    note?: string | null;
    /**
     * The amount of minutes the procedure took to perform.
     * @dataFormat double
     */
    duration_minutes?: number;
    /**
     * The identifier of a practitioner that is set as the responsible nurse.
     * @dataFormat uuid
     */
    responsible_nurse_id: string;
    /**
     * code to identify the procedure.
     * @dataFormat uuid
     */
    code: string;
    /**
     * Timestamp when the procedure was performed.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The textual representation of the code.
     *
     */
    description: string;
    /**
     * The identifier of the related patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Basic-Care-Procedure unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for BasicCare
 */
export namespace BasicCare {
    /**
     * BasicCare Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * A free-text comment.
         * @type string (`dataFormat` is missing)
         */
        note?: string | null;
        /**
         * The amount of minutes the procedure took to perform.
         * @dataFormat double
         */
        durationMinutes?: number;
        /**
         * The identifier of a practitioner that is set as the responsible nurse.
         * @dataFormat uuid
         */
        responsibleNurseId: string;
        /**
         * code to identify the procedure.
         * @dataFormat uuid
         */
        code: string;
        /**
         * Timestamp when the procedure was performed.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The textual representation of the code.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The identifier of the related patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Basic-Care-Procedure unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * BasicCare DTO
     * Transforms BasicCare model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend BasicCare model */
        static toModel(obj: BasicCare): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend BasicCare model */
        static toApi(obj: Model): BasicCare {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as BasicCare;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
