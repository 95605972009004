/* eslint-disable prefer-const */
import { CustomApiModels, ApiModels } from '@mona/api';
import { DataUpdateMessage, DataUpdateMessageModel, DataUpdateMessageOperation } from '@mona/models';
import { isEmpty, isFunction } from '@mona/shared/utils';
import {
    transformApiLabValues,
    transformApiMedicationAdministration,
    transformApiMedicationPrescription,
    transformApiPatient,
    transformApiProcedure,
    transformApiProcedurePrescription,
    transformApiVitalSign,
    transformPartialApiVentilationParameter,
    transformApiPatientHistory,
    transformApiAdditionalDevice,
} from './change-log.transforms';

/**
 * Transforms API Encounter
 *
 * @param apiEncounter apiEncounter
 */
const transformApiEncounter = (apiEncounter: ApiModels.Encounter) => {
    const model = ApiModels.Encounter.DTO.toModel(apiEncounter);
    return model;
};

/**
 * Transforms API Bed
 *
 * @param apiBed apiBed
 */
const transformApiBed = (apiBed: ApiModels.Bed) => {
    return ApiModels.Bed.DTO.toModel(apiBed);
};

/**
 * Transforms API Ward
 *
 * @param apiWard apiWard
 */
const transformApiWard = (apiWard: ApiModels.Ward) => {
    return ApiModels.Ward.DTO.toModel(apiWard);
};

/**
 * Transforms API live data update message
 *
 * @param message ApiModels.DataUpdateMessage
 */
export const transformApiDataUpdateMessage = (
    message: CustomApiModels.DataUpdateMessage,
): DataUpdateMessage<DataUpdateMessageModel> => {
    const payloadTransformerMap: {
        [key: string]: AnyFn;
    } = {
        AdditionalDevice: transformApiAdditionalDevice,
        VitalSign: transformApiVitalSign,
        LabValue: transformApiLabValues,
        VentilationParameter: transformPartialApiVentilationParameter,
        Patient: transformApiPatient,
        Encounter: transformApiEncounter,
        Ward: transformApiWard,
        Bed: transformApiBed,
        MedicationPrescription: transformApiMedicationPrescription,
        ProcedurePrescription: transformApiProcedurePrescription,
        MedicationAdministration: transformApiMedicationAdministration,
        Procedure: transformApiProcedure,
        PatientHistory: transformApiPatientHistory,
    };
    let { model, model_id: modelId, operation, payload } = message;
    // transform payload
    payload =
        !isEmpty(payload) && isFunction(payloadTransformerMap[model]) ? payloadTransformerMap[model](payload) : null;

    if (operation !== DataUpdateMessageOperation.Delete) {
        payload['lastChangedBy'] = message.payload?.last_changed_by;
    }

    // hack 'cause BE sends `UPDATE` for delete operation for wards/beds (SOFT DELETE)
    operation = operation === 'UPDATE' && payload?.status === 'inactive' ? 'DELETE' : operation;

    return {
        model,
        modelId,
        operation,
        payload,
    } as DataUpdateMessage<DataUpdateMessageModel>;
};
