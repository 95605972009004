import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ReportType } from '@mona/models';
import { ReportTypesState } from '../../entities';
import * as ReportsActions from '../actions/reports.actions';

/**  EntityAdapter<Report> */
export const reportTypesAdapter: EntityAdapter<ReportType> = createEntityAdapter<ReportType>({
    selectId: reportType => reportType.value,
});

const initialState: ReportTypesState = reportTypesAdapter.getInitialState({
    selectedReportId: null,
    error: null,
    isLoading: false,
});

export const reportTypesReducer = createReducer(
    initialState,
    on(ReportsActions.loadReportTypes, (state, action) => ({ ...state, error: null, isLoading: true })),
    on(ReportsActions.loadReportTypesFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
    on(ReportsActions.loadReportTypesSuccess, (state, action) => ({
        ...reportTypesAdapter.setAll(action.reportTypes, state),
        error: null,
        isLoading: false,
    })),
    on(ReportsActions.clearReportTypes, state => reportTypesAdapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = reportTypesAdapter.getSelectors();
