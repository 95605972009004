import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportsEffects } from './state';
import { REPORTS_REDUCERS_TOKEN, reportsReducer } from './state/reducers';

/**
 * DataAccess Reports Module
 */
@NgModule({
    imports: [
        StoreModule.forFeature(REPORTS_REDUCERS_TOKEN, reportsReducer),
        EffectsModule.forFeature([ReportsEffects]),
    ],
})
export class DataAccessReportsModule {}
