// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.67
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-27T12:13:33.634978+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating resolve notification
 * @see #/components/schemas/ResolveNotificationRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-27T12:13:33.634978+02:00[Europe/Kiev]
 * @version 1.6.67
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface ResolveNotificationRequest {
    /**
     * Resolve type for the notification.  * `completed` - Completed * `dismissed` - Dismissed
     *
     */
    resolution: ResolveNotificationRequest.ResolutionEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for ResolveNotificationRequest
 */
export namespace ResolveNotificationRequest {
    export type ResolutionEnum = 'completed' | 'dismissed';
    export const ResolutionEnum = {
        Completed: 'completed' as ResolutionEnum,
        Dismissed: 'dismissed' as ResolutionEnum,
    };

    /**
     * ResolveNotificationRequest Frontend Model with camelCase properties
     * @since 2024-11-27T12:13:33.634978+02:00[Europe/Kiev]
     * @version 1.6.67
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Resolve type for the notification.  * `completed` - Completed * `dismissed` - Dismissed
         * @type string (`dataFormat` is missing)
         */
        resolution: ResolveNotificationRequest.ResolutionEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * ResolveNotificationRequest DTO
     * Transforms ResolveNotificationRequest model from/to API object
     * @since 2024-11-27T12:13:33.634978+02:00[Europe/Kiev]
     * @version 1.6.67
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend ResolveNotificationRequest model */
        static toModel(obj: ResolveNotificationRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend ResolveNotificationRequest model */
        static toApi(obj: Model): ResolveNotificationRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as ResolveNotificationRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
