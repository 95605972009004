// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * List serializer for procedure prescription
 * @see #/components/schemas/ProcedurePrescription - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface ProcedurePrescription {
    /**
     * The date when the period ended. If empty, the period is ongoing.
     * @dataFormat date-time
     */
    end_date?: string | null;
    /**
     * The code of the procedure category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Procedure/category
     *
     */
    category: string;
    /**
     * The actual textual representation of the procedure.
     *
     */
    description: string;
    /**
     * The code of the prescription frequency that describes how often the procedure should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
     * @dataFormat uuid
     */
    frequency: string;
    /**
     * Frequency times for the custom frequency.
     *
     */
    frequency_times?: Array<string>;
    /**
     * A required free-text note for the prescription.
     *
     */
    instructions: string;
    /**
     * Determines if the prescription is manually canceled before the planned end-date is reached and prior to the known full completion of the intended actions.
     *
     */
    is_revoked: boolean;
    /**
     * Determines if the prescription is approved.
     *
     */
    is_approved: boolean;
    /**
     * The date when the period started.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * id of the patient who is the subject of this prescription.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Procedure-Prescription unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for ProcedurePrescription
 */
export namespace ProcedurePrescription {
    /**
     * ProcedurePrescription Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The date when the period ended. If empty, the period is ongoing.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date | null;
        /**
         * The code of the procedure category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Procedure/category
         * @type string (`dataFormat` is missing)
         */
        category: string;
        /**
         * The actual textual representation of the procedure.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The code of the prescription frequency that describes how often the procedure should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
         * @dataFormat uuid
         */
        frequency: string;
        /**
         * Frequency times for the custom frequency.
         * @type Array<string> (`dataFormat` is missing)
         */
        frequencyTimes?: Array<string>;
        /**
         * A required free-text note for the prescription.
         * @type string (`dataFormat` is missing)
         */
        instructions: string;
        /**
         * Determines if the prescription is manually canceled before the planned end-date is reached and prior to the known full completion of the intended actions.
         * @type boolean (`dataFormat` is missing)
         */
        isRevoked: boolean;
        /**
         * Determines if the prescription is approved.
         * @type boolean (`dataFormat` is missing)
         */
        isApproved: boolean;
        /**
         * The date when the period started.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * id of the patient who is the subject of this prescription.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Procedure-Prescription unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * ProcedurePrescription DTO
     * Transforms ProcedurePrescription model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend ProcedurePrescription model */
        static toModel(obj: ProcedurePrescription): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend ProcedurePrescription model */
        static toApi(obj: Model): ProcedurePrescription {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as ProcedurePrescription;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
