import { SetOptional } from 'type-fest';
import { ApiModels } from '@mona/api';

/**
 * Change log entry
 */
export interface ChangeLogEntry<T = ChangeLogModel>
    extends SetOptional<ApiModels.ChangeLogEntry.Model, 'id' | 'encounterId' | 'createdAt'> {
    /**
     * Change payload with common atts to track by/when/whom
     */
    payload?: Partial<T>;
    /**
     * ID of the user who previously made the change (if any)
     */
    previous_user_id?: string;
}

/**
 * Change log model payload meta data
 */
export type ChangeLogModelPayloadMeta = {
    /** Encounter id */
    encounterId?: string;
    /** Patient id */
    patientId?: string;
    /** Last changed by */
    lastChangedBy: string;
    /** Last changed at */
    lastChangedAt: Date;
};

/**
 * Possible change log models keys
 */
export type ChangeLogModelKey = ApiModels.ChangeLogEntry.ModelEnum;

/**
 * Possible change log models
 */
export type ChangeLogModel = AnyObject & Entity;

/**
 * Change log operations - copy of {@link ApiModels.ChangeLogEntry.OperationEnum }
 *
 * IMPORTANT: Copy is needed to prevent JIT compliation error
 */
export type ChangeLogOperation = 'create' | 'update' | 'delete';
export const ChangeLogOperation = {
    Create: 'create' as ChangeLogOperation,
    Update: 'update' as ChangeLogOperation,
    Delete: 'delete' as ChangeLogOperation,
};
