// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowAnswerOption } from './workflowAnswerOption';

/**
 * Serializer for validating WorkflowResponseQuestion.
 * @see #/components/schemas/WorkflowResponseQuestion - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowResponseQuestion {
    /**
     * The identifier of Question-ID.
     *
     */
    id: string;
    /**
     * The title of the Workflow-Item-Answer.
     *
     */
    title?: string | null;
    /**
     * The description of the Workflow-Item-Answer.
     *
     */
    description?: string | null;
    /**
     * Item type of the question.
     *
     */
    item_type?: string | null;
    /**
     * The answer options of Workflow-Item-Answer.
     *
     */
    answer_options?: Array<WorkflowAnswerOption> | null;
    /**
     * The current value of the Workflow-Item-Answer.
     *
     */
    current_value?: string | null;
    /**
     * The answer suggestion of Workflow-Item-Answer.
     *
     */
    answer_suggestion?: WorkflowAnswerOption | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowResponseQuestion
 */
export namespace WorkflowResponseQuestion {
    /**
     * WorkflowResponseQuestion Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The identifier of Question-ID.
         * @type string (`dataFormat` is missing)
         */
        id: string;
        /**
         * The title of the Workflow-Item-Answer.
         * @type string (`dataFormat` is missing)
         */
        title?: string | null;
        /**
         * The description of the Workflow-Item-Answer.
         * @type string (`dataFormat` is missing)
         */
        description?: string | null;
        /**
         * Item type of the question.
         * @type string (`dataFormat` is missing)
         */
        itemType?: string | null;
        /**
         * The answer options of Workflow-Item-Answer.
         * @type Array<WorkflowAnswerOption> (`dataFormat` is missing)
         */
        @Type(() => WorkflowAnswerOption.Model) answerOptions?: Array<WorkflowAnswerOption.Model> | null;
        /**
         * The current value of the Workflow-Item-Answer.
         * @type string (`dataFormat` is missing)
         */
        currentValue?: string | null;
        /**
         * The answer suggestion of Workflow-Item-Answer.
         * @type WorkflowAnswerOption (`dataFormat` is missing)
         */
        @Type(() => WorkflowAnswerOption.Model) answerSuggestion?: WorkflowAnswerOption.Model | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowResponseQuestion DTO
     * Transforms WorkflowResponseQuestion model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowResponseQuestion model */
        static toModel(obj: WorkflowResponseQuestion): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowResponseQuestion model */
        static toApi(obj: Model): WorkflowResponseQuestion {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowResponseQuestion;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
