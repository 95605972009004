// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Premedication.
 * @see #/components/schemas/Premedication - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Premedication {
    /**
     * The code to identify the medication. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/code
     * @dataFormat uuid
     */
    code?: string;
    /**
     * The textual representation of the code.
     *
     */
    description?: string;
    /**
     * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
     * @dataFormat uuid
     */
    frequency?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    dose?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit?: string;
    /**
     * Premedication unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Premedication
 */
export namespace Premedication {
    /**
     * Premedication Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The code to identify the medication. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/code
         * @dataFormat uuid
         */
        code?: string;
        /**
         * The textual representation of the code.
         * @type string (`dataFormat` is missing)
         */
        description?: string;
        /**
         * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
         * @dataFormat uuid
         */
        frequency?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        dose?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unit?: string;
        /**
         * Premedication unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Premedication DTO
     * Transforms Premedication model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Premedication model */
        static toModel(obj: Premedication): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Premedication model */
        static toApi(obj: Model): Premedication {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Premedication;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
