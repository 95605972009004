// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validation the anamnesis part of the admission payload
 * @see #/components/schemas/AdmissionAnamnesisRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdmissionAnamnesisRequest {
    /**
     * The type of admission for this patient  * `planned-surgical` - PLANNED_SURGICAL * `emergency-surgical` - EMERGENCY_SURGICAL * `general-medical` - GENERAL_MEDICAL
     *
     */
    type_of_admission: AdmissionAnamnesisRequest.TypeOfAdmissionEnum;
    /**
     * The text part of the anamnesis
     *
     */
    text: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdmissionAnamnesisRequest
 */
export namespace AdmissionAnamnesisRequest {
    export type TypeOfAdmissionEnum = 'planned-surgical' | 'emergency-surgical' | 'general-medical';
    export const TypeOfAdmissionEnum = {
        PlannedSurgical: 'planned-surgical' as TypeOfAdmissionEnum,
        EmergencySurgical: 'emergency-surgical' as TypeOfAdmissionEnum,
        GeneralMedical: 'general-medical' as TypeOfAdmissionEnum,
    };

    /**
     * AdmissionAnamnesisRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The type of admission for this patient  * `planned-surgical` - PLANNED_SURGICAL * `emergency-surgical` - EMERGENCY_SURGICAL * `general-medical` - GENERAL_MEDICAL
         * @type string (`dataFormat` is missing)
         */
        typeOfAdmission: AdmissionAnamnesisRequest.TypeOfAdmissionEnum;
        /**
         * The text part of the anamnesis
         * @type string (`dataFormat` is missing)
         */
        text: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdmissionAnamnesisRequest DTO
     * Transforms AdmissionAnamnesisRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdmissionAnamnesisRequest model */
        static toModel(obj: AdmissionAnamnesisRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdmissionAnamnesisRequest model */
        static toApi(obj: Model): AdmissionAnamnesisRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdmissionAnamnesisRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
