import { ReportLocation, ReportType } from '@mona/models';

// INFO: we don't have api models from openapi yet
/**
 * INFO: add comment
 */
export type ApiReport = {
    /**
     * INFO: add comment
     */
    value: string;
    /**
     * INFO: add comment
     */
    display_name: string;
};

/**
 * INFO: add comment
 */
export type ApiLocation = {
    /*** INFO: add comment
     */
    value: string;
    /**
     * INFO: add comment
     */
    label: string;
};

/**
 * Transforms api report types
 *
 * @param apiReportType ApiModels.ReportType
 */
export const transformApiReportsType = (apiReportType: ApiReport): ReportType => {
    return {
        value: apiReportType.value as string,
        displayName: apiReportType.display_name as string,
    };
};

/**
 * Transform api report types
 *
 * @param apiExportReports ApiModels.ReportType[]
 */
export const transformApiReportTypes = (apiExportReports: ApiReport[]): ReportType[] => {
    return apiExportReports.map(apiExportReport => transformApiReportsType(apiExportReport));
};

/**e
 * Transforms api location
 *
 * @param apiLocation ApiModels.ReportLocation
 */
export const transformApiReportLocation = (apiLocation: ApiLocation): ReportLocation => {
    return {
        value: apiLocation.value as string,
        label: apiLocation.label as string,
    };
};

/**
 * Transform api locations
 *
 * @param apiExportLocations ApiModels.ReportLocation[]
 */
export const transformApiReportLocations = (apiExportLocations: ApiLocation[]): ReportLocation[] => {
    return apiExportLocations.map(apiExportLocation => transformApiReportLocation(apiExportLocation));
};
