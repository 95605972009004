import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RelativeContact } from '@mona/models';
import { RelativeContactsState } from '../entities';
import { RelativeContactsActions, RelativeContactsSelectors } from '../state';

/** * Relative contacts facade service */
@Injectable({
    providedIn: 'root',
})
export class DataAccessRelativeContactsFacade {
    /** get is loading from store */
    isLoading$: Observable<boolean> = this.store.select(RelativeContactsSelectors.selectIsLoading);

    relativeContacts$ = this.store.select(RelativeContactsSelectors.selectRelativeContactAll).pipe(
        // In order to sort contacts by recent updates
        map(contacts =>
            contacts.sort((prev, next) => new Date(next.lastUpdated).getTime() - new Date(prev.lastUpdated).getTime()),
        ),
    );

    /**
     * Constructor
     *
     * @param store Store
     */
    constructor(private store: Store<RelativeContactsState>) {}

    /** load relative contacts */
    loadRelativeContacts(): void {
        this.store.dispatch(RelativeContactsActions.loadRelativeContacts());
    }

    /**
     * handle delete relative contact
     *
     * @param id
     */
    handleDeleteRelativeContact(id: RelativeContact['id']): void {
        this.store.dispatch(RelativeContactsActions.handleDeleteRelativeContact({ id }));
    }

    /**
     * update relative contact
     *
     * @param relativeContact
     */
    upsertRelativeContact(relativeContact: RelativeContact): void {
        this.store.dispatch(
            RelativeContactsActions.upsertRelativeContact({
                relativeContact,
            }),
        );
    }
}
