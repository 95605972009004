// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating Health Settings response body.
 * @see #/components/schemas/HealthSettings - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface HealthSettings {
    /**
     * The name of the api service
     *
     */
    service?: string;
    /**
     * Minimum version of the terminal to use the service
     *
     */
    min_version?: string;
    /**
     * Is the service currently in maintenance mode or not
     *
     */
    maintenance?: boolean;
    /**
     * Current backend version
     *
     */
    backend_version?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for HealthSettings
 */
export namespace HealthSettings {
    /**
     * HealthSettings Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The name of the api service
         * @type string (`dataFormat` is missing)
         */
        service?: string;
        /**
         * Minimum version of the terminal to use the service
         * @type string (`dataFormat` is missing)
         */
        minVersion?: string;
        /**
         * Is the service currently in maintenance mode or not
         * @type boolean (`dataFormat` is missing)
         */
        maintenance?: boolean;
        /**
         * Current backend version
         * @type string (`dataFormat` is missing)
         */
        backendVersion?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * HealthSettings DTO
     * Transforms HealthSettings model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend HealthSettings model */
        static toModel(obj: HealthSettings): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend HealthSettings model */
        static toApi(obj: Model): HealthSettings {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as HealthSettings;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
