import {
    AdditionalDevice,
    Bed,
    Encounter,
    LabValue,
    MedicationAdministration,
    MedicationPrescription,
    Procedure,
    ProcedurePrescription,
    VentilationParameter,
    VitalSign,
    Ward,
} from '@mona/models';
import { ChangeLogEntry } from './change-log-entry.model';

/** Live data update message */
export interface DataUpdateMessage<T extends DataUpdateMessageModel> {
    /**
     * The name of Model.
     */
    model: DataUpdateMessageModelKey;

    /**
     * Model id
     */
    modelId: string;

    /**
     * Name of operation applied to model.
     */
    operation: DataUpdateMessageOperation;

    /**
     * Difference between old model and updated model
     */
    payload: Partial<T>;
}

/**
 * Data update message model name
 */
export type DataUpdateMessageModelKey =
    | 'Ward'
    | 'Bed'
    | 'Patient'
    | 'Encounter'
    | 'VitalSign'
    | 'AdditionalDevice'
    | 'LabValue'
    | 'VentilationParameter'
    | 'ChangeLogEntry'
    | 'MedicationPrescription'
    | 'ProcedurePrescription'
    | 'MedicationAdministration'
    | 'Procedure';

/**
 * Possible live data update models
 */
export type DataUpdateMessageModel =
    | Ward
    | Bed
    | Encounter
    | VitalSign
    | AdditionalDevice
    | LabValue
    | VentilationParameter
    | ChangeLogEntry
    | ProcedurePrescription
    | MedicationPrescription
    | MedicationAdministration
    | Procedure;

/**
 * Data update operations
 */
export enum DataUpdateMessageOperation {
    Create = 'CREATE',
    Update = 'UPDATE',
    Delete = 'DELETE',
}
