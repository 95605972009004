// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for validating VaccinationStatus model structure.
 * @see #/components/schemas/VaccinationStatus - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface VaccinationStatus {
    /**
     * Vaccination Date.
     * @dataFormat date-time
     */
    vaccination_date?: string;
    /**
     * Vaccination against.
     *
     */
    vaccination_against: string;
    /**
     * Vaccination manufacturer.
     *
     */
    verification_manufacturer?: string | null;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Diagnosis unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for VaccinationStatus
 */
export namespace VaccinationStatus {
    /**
     * VaccinationStatus Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Vaccination Date.
         * @dataFormat date-time
         */
        @TransformDate() vaccinationDate?: Date;
        /**
         * Vaccination against.
         * @type string (`dataFormat` is missing)
         */
        vaccinationAgainst: string;
        /**
         * Vaccination manufacturer.
         * @type string (`dataFormat` is missing)
         */
        verificationManufacturer?: string | null;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Diagnosis unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * VaccinationStatus DTO
     * Transforms VaccinationStatus model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend VaccinationStatus model */
        static toModel(obj: VaccinationStatus): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend VaccinationStatus model */
        static toApi(obj: Model): VaccinationStatus {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as VaccinationStatus;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
