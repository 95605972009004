// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowCreateItemRequest } from './workflowCreateItemRequest';

/**
 * Serializer for validating and creating Workflow/Smart workflows.
 * @see #/components/schemas/WorkflowCreateRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowCreateRequest {
    /**
     * The id of the Questionnaire.
     *
     */
    questionnaire_id: string;
    /**
     * The name of the Questionnaire.
     *
     */
    title: string;
    /**
     * The items of Workflow.
     *
     */
    questions: Array<WorkflowCreateItemRequest>;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowCreateRequest
 */
export namespace WorkflowCreateRequest {
    /**
     * WorkflowCreateRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The id of the Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        questionnaireId: string;
        /**
         * The name of the Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        title: string;
        /**
         * The items of Workflow.
         * @type Array<WorkflowCreateItemRequest> (`dataFormat` is missing)
         */
        @Type(() => WorkflowCreateItemRequest.Model) questions: Array<WorkflowCreateItemRequest.Model>;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowCreateRequest DTO
     * Transforms WorkflowCreateRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowCreateRequest model */
        static toModel(obj: WorkflowCreateRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowCreateRequest model */
        static toApi(obj: Model): WorkflowCreateRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowCreateRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
