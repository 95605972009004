import {
    AdditionalDevice,
    BasicCareProcedure,
    ChangeLogEntry,
    ChangeLogModel,
    ChangeLogModelPayloadMeta,
    ChangeLogOperation,
    Encounter,
    LabValue,
    MedicationAdministration,
    PatientOutput,
    Practitioner,
    Procedure,
    SortOrderEnum,
    VentilationParameter,
    VitalSign,
} from '@mona/models';
import { groupBy } from '@mona/shared/utils';

/**
 * Applies changes to array of instances
 *
 * @param instances T[] - raw API items
 * @param changes ChangeLogEntry<T>[] - sorted by `createdAt`
 * @param insertInTheBeginning (optional if need to unshift instead of push)
 */
export const applyInstancesChanges = <T extends ChangeLogModel>(
    instances: T[],
    changes: ChangeLogEntry<T>[],
    insertInTheBeginning = false,
): T[] => {
    if (!changes?.length) {
        return instances;
    }

    const changedInstances = [...instances];

    for (const change of changes) {
        if (change.operation === ChangeLogOperation.Create) {
            // create
            const item = {
                ...change.payload,
                id: change.modelId,
                hasChanges: true,
                lastChangedBy: change.practitionerId,
            } as any;

            // don't push item if already exists
            const itemAlreadyExists = changedInstances.find(ci => ci.id === item.id);
            // FIXME: !!!
            if (itemAlreadyExists) {
                continue;
            }

            if (insertInTheBeginning) {
                changedInstances.unshift(item);
            } else {
                changedInstances.push(item);
            }
        } else if (change.operation === ChangeLogOperation.Update) {
            // update
            const indexToUpdate = changedInstances.findIndex(item => item.id === change.modelId);
            changedInstances.splice(indexToUpdate, 1, {
                ...changedInstances[indexToUpdate],
                ...change.payload,
                hasChanges: true,
                lastChangedBy: change.practitionerId,
            });
        } else if (change.operation === ChangeLogOperation.Delete) {
            // remove
            const indexToRemove = changedInstances.findIndex(item => item.id === change.modelId);
            changedInstances.splice(indexToRemove, 1, {
                ...changedInstances[indexToRemove],
                isStageRemoved: true,
                hasChanges: true,
            });
        }
    }

    return changedInstances;
};

/**
 * Apply single instance change
 *
 * @param instance T
 * @param changes ChangeLogEntry<T>[]
 */
export const applyInstanceChanges = <T extends ChangeLogModel>(instance: T, changes: ChangeLogEntry<T>[]): T => {
    if (!changes?.length) {
        return instance;
    }

    let changedInstance: T = {
        ...instance,
    };

    for (const change of changes) {
        if (change.operation === ChangeLogOperation.Update) {
            changedInstance = {
                ...changedInstance,
                ...change.payload,
            };
        }
    }

    return changedInstance;
};

/**
 * Applies changes to entity map
 *
 * @param entityMap EntityMap<T>
 * @param changes ChangeLogEntry<T>[]
 */
export const applyEntityMapChanges = <T extends ChangeLogModel>(
    entityMap: EntityMap<T>,
    changes: ChangeLogEntry<T>[],
): EntityMap<T> => {
    if (!changes?.length) {
        return entityMap;
    }

    const changedMap = {
        ...entityMap,
    };

    for (const change of changes) {
        if (change.operation === ChangeLogOperation.Create) {
            // create
            const item = {
                ...change.payload,
                id: change.modelId,
                hasChanges: true,
            } as any;
            changedMap[item.id] = item;
        } else if (change.operation === ChangeLogOperation.Update) {
            // update
            changedMap[change.modelId] = {
                ...changedMap[change.modelId],
                ...change.payload,
                hasChanges: true,
            };
        } else if (change.operation === ChangeLogOperation.Delete) {
            // remove
            changedMap[change.modelId] = {
                ...changedMap[change.modelId],
                isStageRemoved: true,
                hasChanges: true,
            };
        }
    }

    return changedMap;
};

/**
 * Applies staged changes to entity grouped map
 *
 * @deprecated
 * @param groupedEntities VitalSignsMap | VentilationParametersMap
 * @param instancesMap EntityMap<VitalSign>
 * @param changes ChangeLogEntry<VitalSign>[]
 * @param groupingField string
 * @param sortingField string
 * @param groupingFieldCallback (value: any, entity: T) => string
 * @param sortOrder 'asc' | 'desc'
 */
export const applyGroupedEntitiesStagedChanges = <
    T extends
        | AdditionalDevice
        | VitalSign
        | VentilationParameter
        | LabValue
        | PatientOutput
        | MedicationAdministration
        | BasicCareProcedure
        | Procedure,
    GM extends AnyObject,
>(
    groupedEntities: GM,
    instancesMap: EntityMap<T>,
    changes: ChangeLogEntry<T>[],
    groupingField = 'code',
    sortingField = 'date',
    groupingFieldCallback?: (value: any, entity: T) => string,
    sortOrder = SortOrderEnum.desc,
): GM => {
    if (!changes?.length || !groupedEntities) {
        return groupedEntities;
    }

    // Result updated grouped map
    const updatedGroupedMap: GM = {
        ...groupedEntities,
    };

    // Updated instances map as helper for applying changes on newly created items
    const newlyCreatedItemsMap: EntityMap<T> = {};

    /**
     * For more efficiency we save types which should be re-sorted
     * Because we have create in past
     */
    const typesWithNewEntries: Set<string> = new Set();

    // Applying changes
    for (const change of changes) {
        if (change.operation === ChangeLogOperation.Create) {
            // create
            handleInstanceCreate(
                change,
                newlyCreatedItemsMap,
                updatedGroupedMap,
                typesWithNewEntries,
                groupingField,
                groupingFieldCallback,
            );
        } else {
            // update and delete
            handleInstanceUpdateDelete(
                change,
                instancesMap,
                newlyCreatedItemsMap,
                updatedGroupedMap,
                groupingField,
                groupingFieldCallback,
            );
        }
    }

    // Sorting entities that have new entries
    if (sortingField) {
        sortChangedEntries(updatedGroupedMap, typesWithNewEntries, sortingField, sortOrder);
    }

    return updatedGroupedMap;
};

/**
 * Contains shared logic for vital signs and lab values create
 *
 * @deprecated
 * @param change ChangeLogEntry<T>
 * @param newlyCreatedItemsMap EntityMap<T>
 * @param updatedGroupedMap VitalSignsMap | LabValuesMap
 * @param typesWithNewEntries Set<string>
 * @param groupingField string
 * @param groupingFieldCallback (value: any, entity: T) => string,
 */
const handleInstanceCreate = <
    T extends
        | AdditionalDevice
        | LabValue
        | VitalSign
        | VentilationParameter
        | PatientOutput
        | MedicationAdministration
        | BasicCareProcedure
        | Procedure,
>(
    change: ChangeLogEntry<T>,
    newlyCreatedItemsMap: EntityMap<T>,
    updatedGroupedMap: AnyObject,
    typesWithNewEntries: Set<string>,
    groupingField = 'code',
    groupingFieldCallback?: (value: any, entity: T) => string,
): void => {
    const newEntry = {
        ...change.payload,
        id: change.modelId,
        hasChanges: true,
    } as T;

    // Handle key
    const key = groupingFieldCallback
        ? groupingFieldCallback(newEntry[groupingField], newEntry)
        : newEntry[groupingField];
    const existingTypeEntries = updatedGroupedMap[key];

    // Handles also new entry of type
    updatedGroupedMap[key] = [...(existingTypeEntries || []), newEntry] as any[]; // T[]

    // Adds type to sort items at next step
    typesWithNewEntries.add(key);

    // Adds item to newly created items map
    newlyCreatedItemsMap[newEntry.id] = newEntry;
};

/**
 * Contains shared logic for vital signs and lab values update / delete logic
 *
 * @deprecated
 * @param change ChangeLogEntry<T>
 * @param instancesMap EntityMap<T>
 * @param newlyCreatedItemsMap EntityMap<T>
 * @param updatedGroupedMap VitalSignsMap | LabValuesMap
 * @param groupingField string
 * @param groupingFieldCallback (value: any, entity: T) => string,
 */
const handleInstanceUpdateDelete = <
    T extends
        | AdditionalDevice
        | LabValue
        | VitalSign
        | VentilationParameter
        | PatientOutput
        | MedicationAdministration
        | BasicCareProcedure
        | Procedure,
>(
    change: ChangeLogEntry<T>,
    instancesMap: EntityMap<T>,
    newlyCreatedItemsMap: EntityMap<T>,
    updatedGroupedMap: AnyObject,
    groupingField = 'code',
    groupingFieldCallback?: (value: any, entity: T) => string,
): void => {
    const updatedItem = instancesMap[change.modelId] || newlyCreatedItemsMap[change.modelId];
    // Handle key
    const key = groupingFieldCallback
        ? groupingFieldCallback(updatedItem?.[groupingField], updatedItem)
        : updatedItem?.[groupingField];

    if (!updatedItem) {
        return;
    }

    const updatedExistingTypeEntries = [...(updatedGroupedMap[key] || [])];
    const indexToUpdate = updatedExistingTypeEntries.findIndex(item => item.id === change.modelId);

    if (change.operation === ChangeLogOperation.Update) {
        updatedExistingTypeEntries.splice(indexToUpdate, 1, {
            ...updatedExistingTypeEntries[indexToUpdate],
            ...change.payload,
            hasChanges: true,
        });
    } else if (change.operation === ChangeLogOperation.Delete && indexToUpdate > -1) {
        updatedExistingTypeEntries.splice(indexToUpdate, 1, {
            ...updatedExistingTypeEntries[indexToUpdate],
            isStageRemoved: true,
            hasChanges: true,
        });
    }

    updatedGroupedMap[key] = updatedExistingTypeEntries as any[]; // T[]
};

/**
 * Contains shared sort logic for new entries of vital signs and lab values
 *
 * @deprecated
 * @param updatedGroupedMap VitalSignsMap | LabValuesMap
 * @param typesWithNewEntries Set<string>
 * @param sortingField sortingField
 * @param sortOrder asc | desc
 */
const sortChangedEntries = (
    updatedGroupedMap: AnyObject,
    typesWithNewEntries: Set<string>,
    sortingField = 'date',
    sortOrder: SortOrderEnum,
): void => {
    typesWithNewEntries.forEach(type => {
        updatedGroupedMap[type] = [...updatedGroupedMap[type]].sort((prev, next) => {
            if (sortOrder === SortOrderEnum.asc) {
                return next[sortingField].getTime() - prev[sortingField].getTime();
            } else {
                return prev[sortingField].getTime() - next[sortingField].getTime();
            }
        }) as any[]; // T[]
    });
};

/**
 * Merge data with persisted changes
 *
 * Changes array is reversed here, because e.g. we have 3 changes for same item id - CREATE, UPDATE, DELETE
 * most likely, for the same id, DELETE operation is last - by design user cannot edit afer delete
 * so we need to skip item from adding to `toUpdate` array if it exists (see test)
 *
 * @param data
 * @param changes
 * @returns data array with merged changes into it
 */
export function mergeDataWithPersistedChanges<T extends ChangeLogModel, C extends ChangeLogEntry>(
    data: T[],
    changes: C[],
): T[] {
    changes = mergeChangesOnModelId(changes) as any;

    for (let i = changes.length - 1; i >= 0; i--) {
        const change = changes[i];
        const dataItemIdx = data.findIndex((item: any) => item.id === change.modelId);

        if (change.operation === ChangeLogOperation.Delete) {
            dataItemIdx !== -1 && data.splice(dataItemIdx, 1);
        } else {
            if (dataItemIdx !== -1) {
                data[dataItemIdx] = { ...data[dataItemIdx], ...change.payload };
            } else {
                data.push({
                    ...data[dataItemIdx],
                    ...change.payload,
                    id: change.modelId,
                } as any);
            }
        }
    }

    return data;
}

/**
 * Get persisted changes data split by operation
 *
 * Changes array is reversed here, because e.g. we have 3 changes for same item id - CREATE, UPDATE, DELETE
 * most likely, for the same id, DELETE operation is last - by design user cannot edit afer delete
 * so we need to skip item from adding to `toUpdate` array if it exists (see test)
 *
 * @param data
 * @param changes
 * @returns object with toRemove and toUpdate arrays
 */
export function getPersistedChangesData<T extends ChangeLogModel, C extends ChangeLogEntry>(
    data: T[],
    changes: C[],
): { toRemove: T[]; toUpdate: T[] } {
    const toRemove: T[] = [];
    const toUpdate: T[] = [];

    const processedIds = new Set<string>();

    for (let i = changes.length - 1; i >= 0; i--) {
        const { modelId: id, payload, operation } = changes[i];

        if (processedIds.has(id)) {
            continue;
        }

        if (operation !== ChangeLogOperation.Create) {
            processedIds.add(id);
        }

        const foundDataItem = data.find((item: any) => item.id === id) || {};
        const newItem: T = { ...foundDataItem, ...payload, id } as any;

        (newItem as any).hasChanges = false;

        if (operation === ChangeLogOperation.Create) {
            data.push(newItem);
        }

        if (operation === ChangeLogOperation.Delete) {
            toRemove.push(newItem);
        } else {
            toUpdate.push(newItem);
        }
    }

    return {
        toRemove,
        toUpdate,
    };
}

/**
 * get changes data
 *
 * @param data
 * @param changes
 * @returns T[]
 */
export function getChangesData<T extends ChangeLogModel, C extends ChangeLogEntry>(data: T[], changes: C[]): T[] {
    const toUpdate: T[] = [];

    const processedIds = new Set<string>();

    for (let i = changes.length - 1; i >= 0; i--) {
        const { modelId: id, payload, operation } = changes[i];

        if (processedIds.has(id)) {
            continue;
        }

        processedIds.add(id);

        const foundDataItem = data.find((item: any) => item.id === id) || {};

        const newItem: T & { hasChanges: boolean; isStageRemoved?: boolean } = {
            id,
            ...foundDataItem,
            ...payload,
        } as any;

        newItem.hasChanges = true;
        if (operation === ChangeLogOperation.Delete) {
            newItem.isStageRemoved = true;
        }

        toUpdate.push(newItem);
    }

    return toUpdate;
}

/**
 * Extend payload with meta data
 *
 * @param payload
 * @param encounter
 * @param practitioner
 * @param addMilliseconds
 */
export function extendPayloadWithMetaData(
    payload: ChangeLogEntry<ChangeLogModel>['payload'],
    encounter: Encounter,
    practitioner: Practitioner,
    addMilliseconds?: number,
): ChangeLogEntry<ChangeLogModel>['payload'] & ChangeLogModelPayloadMeta {
    const lastChangedAt = new Date();
    lastChangedAt.setMilliseconds(addMilliseconds || 0);

    const extendedPayload = {
        ...payload,
        encounterId: encounter?.id,
        patientId: encounter?.patient?.id,
        lastChangedBy: practitioner?.id,
        lastChangedAt,
    };

    // additionaly check if responsibleNurseId should be filled with current user id
    if (payload?.responsibleNurseId) {
        extendedPayload['responsibleNurseId'] = practitioner?.id;
    }

    return extendedPayload;
}

/**
 * merge changes on modelId
 *
 * @param changes
 */
export function mergeChangesOnModelId(changes: ChangeLogEntry[]): ChangeLogEntry[] {
    if (!changes?.length) {
        return [];
    }

    const groupedByModelId = groupBy(changes, ch => ch.modelId, false, false);
    const mergedChanges: ChangeLogEntry[] = [];

    for (const key in groupedByModelId) {
        mergedChanges.push(
            groupedByModelId[key].reduce(
                (res, change) => {
                    res.operation = change.operation;
                    res.payload = { ...res.payload, ...change.payload };
                    return res;
                },
                { ...groupedByModelId[key][0] },
            ),
        );
    }

    return mergedChanges;
}
