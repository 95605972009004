import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

const CUSTOM_ICONS = [
    'alert',
    'allergy',
    'blood',
    'ce-number',
    'coronavirus',
    'edit-sign',
    'exclamation-mark',
    'factory',
    'health-metrics',
    'ifu',
    'infusion',
    'injection',
    'md',
    'medication',
    'nutrition',
    'patient-folder',
    'perfusor',
    'rfid-card',
    'rebase',
    'vaccines',
    'therapy-limitations',
    'editor-choice',
];

/**
 * INFO: add comment
 */
@Injectable({
    providedIn: 'root',
})
export class IconService {
    /**
     * Constructor
     *
     * @param matIconRegistry matIconRegistry
     */
    constructor(private matIconRegistry: MatIconRegistry) {}

    /**
     * Registers icons set with Material registry
     *
     * @param icons
     */
    registerIcons(icons: string[] = []) {
        this.matIconRegistry.addSvgIconSet(
            this.matIconRegistry['_sanitizer'].bypassSecurityTrustResourceUrl(`assets/icons/mdi.svg`),
        );

        for (const iconName of [...icons, ...CUSTOM_ICONS]) {
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.matIconRegistry['_sanitizer'].bypassSecurityTrustResourceUrl(`assets/icons/${iconName}.svg`),
            );
        }
    }
}
