// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowResponseAnswer } from './workflowResponseAnswer';
import { WorkflowResponseQuestion } from './workflowResponseQuestion';

/**
 * Serializer for validating WorkflowQuestionnaireResponse model structure.
 * @see #/components/schemas/WorkflowQuestionnaireResponse - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowQuestionnaireResponse {
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The questionnaire url of the Workflow-Questionnaire-Response.
     * @dataFormat uri
     */
    questionnaire_url: string;
    /**
     * The datetime of the Workflow-Questionnaire-Response.
     * @dataFormat date-time
     */
    date: string;
    /**
     * If the response is filled out completely.
     *
     */
    is_completed: boolean;
    /**
     * An array of Workflow-Questions.
     *
     */
    questions: Array<WorkflowResponseQuestion>;
    /**
     * An array of Workflow-Answers.
     *
     */
    answers: Array<WorkflowResponseAnswer>;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Workflow-Questionnaire-Response unique ID.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowQuestionnaireResponse
 */
export namespace WorkflowQuestionnaireResponse {
    /**
     * WorkflowQuestionnaireResponse Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The questionnaire url of the Workflow-Questionnaire-Response.
         * @dataFormat uri
         */
        questionnaireUrl: string;
        /**
         * The datetime of the Workflow-Questionnaire-Response.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * If the response is filled out completely.
         * @type boolean (`dataFormat` is missing)
         */
        isCompleted: boolean;
        /**
         * An array of Workflow-Questions.
         * @type Array<WorkflowResponseQuestion> (`dataFormat` is missing)
         */
        @Type(() => WorkflowResponseQuestion.Model) questions: Array<WorkflowResponseQuestion.Model>;
        /**
         * An array of Workflow-Answers.
         * @type Array<WorkflowResponseAnswer> (`dataFormat` is missing)
         */
        @Type(() => WorkflowResponseAnswer.Model) answers: Array<WorkflowResponseAnswer.Model>;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Workflow-Questionnaire-Response unique ID.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowQuestionnaireResponse DTO
     * Transforms WorkflowQuestionnaireResponse model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowQuestionnaireResponse model */
        static toModel(obj: WorkflowQuestionnaireResponse): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowQuestionnaireResponse model */
        static toApi(obj: Model): WorkflowQuestionnaireResponse {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowQuestionnaireResponse;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
