import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AdditionalDevice, PageableModel } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { AdditionalDeviceState } from '../../entities';
import { AdditionalDeviceAction } from '../actions';

export const additionalDevicesAdapter: EntityAdapter<AdditionalDevice> = createEntityAdapter<AdditionalDevice>({
    selectId: (item: AdditionalDevice) => item.id,
});

export const initialState: AdditionalDeviceState = additionalDevicesAdapter.getInitialState({
    error: null,
    isLoading: false,
    interval: EntriesInterval.HOUR_1,
    codes: [],
    pageableData: null,
});

export const additionalDeviceReducer = createReducer(
    initialState,
    on(AdditionalDeviceAction.setLoadingAction, (state, action) => ({ ...state, isLoading: action.isLoading })),
    on(AdditionalDeviceAction.setIntervalAction, (state, action) => ({ ...state, interval: action.interval })),
    on(AdditionalDeviceAction.loadAdditionalDevices, state => ({ ...state, isLoading: true })),
    on(
        AdditionalDeviceAction.loadAdditionalDevicesSuccess,
        (state, action: PageableModel<AdditionalDevice> & { codes: string[] }) => {
            const { results, ...pageableData } = action;
            const { codes, ...pageable } = pageableData;

            const updatedState = {
                ...state,
                pageableData: pageable,
                codes: pageableData.codes,
                isLoading: false,
                error: null,
            };

            return additionalDevicesAdapter.upsertMany(results, updatedState);
        },
    ),
    on(AdditionalDeviceAction.loadAdditionalDevicesFailed, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
    })),

    on(AdditionalDeviceAction.upsertAdditionalDevices, (state, { data }) =>
        additionalDevicesAdapter.upsertMany(data, { ...state, isLoading: false }),
    ),

    on(AdditionalDeviceAction.removeAdditionalDevices, (state, { ids }) =>
        additionalDevicesAdapter.removeMany(ids, { ...state, isLoading: false }),
    ),
    on(AdditionalDeviceAction.addChanges, (state, { toRemoveIds, toUpdateEntities }) => {
        const newState = additionalDevicesAdapter.removeMany(toRemoveIds, state);

        return additionalDevicesAdapter.upsertMany(toUpdateEntities, newState);
    }),
    on(AdditionalDeviceAction.clearAdditionalDevices, () => additionalDevicesAdapter.removeAll(initialState)),
);
