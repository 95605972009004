import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MONA_CONFIG_FILENAME } from '@environment';
import { WS_ENDPOINTS_LIST } from '@mona/api';
import { AuthModule } from '@mona/auth';
import { ConfigService, ConfigUpdater, CONFIG_UPDATER } from '@mona/config';
import { CoreModule } from '@mona/core';
import { DataAccessDeviceModule } from '@mona/device/data-access-device';
import { DeviceSharedModule } from '@mona/device/shared';
import { IpcMainEvent } from '@mona/events';
import { FeatureFlagsModule } from '@mona/flags';
import {
    KeyboardEnabledFn,
    KeyboardLocaleFn,
    KeyboardModule,
    KEYBOARD_ENABLED_GETTER,
    KEYBOARD_LOCALE_GETTER,
} from '@mona/keyboard';
import { PdmsShellModule } from '@mona/pdms/shell';
import { MonaRpcService } from '@mona/rpc';
import { Platform, PLATFORM, PlatformElectronGuard, SYNC_STORAGE } from '@mona/shared/utils';
import { TelemedicineSharedModule } from '@mona/telemedicine/shared';
import { TelemedicineShellModule } from '@mona/telemedicine/shell';
import { UiModule } from '@mona/ui';
import { VoiceSharedModule } from '@mona/voice/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/**
 * Config updater factory
 *
 * @param platform
 * @param rpcService
 * @param storage
 */
export function configUpdaterFactory(platform: Platform, rpcService: MonaRpcService, storage: Storage): ConfigUpdater {
    return platform.isElectron
        ? config => rpcService.send(IpcMainEvent.SET_CONFIG, config)
        : config => storage.setItem(MONA_CONFIG_FILENAME, JSON.stringify(config));
}
/**
 * Get configured keyboard status
 *
 * @param configService
 */
export function keyboardEnabledFactory(configService: ConfigService): KeyboardEnabledFn {
    return () => configService.get('isBedSide');
}

/**
 * Get configured keyboard status
 *
 * @param configService
 */
export function keyboardLocaleFactory(configService: ConfigService): KeyboardLocaleFn {
    return () => configService.get('keyboardLocale');
}

/** AppModule */
@NgModule({
    declarations: [AppComponent],
    imports: [
        // Global
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('custom.service-worker.js', {
            enabled: false,
        }),
        // Mona
        AppRoutingModule, // INFO: keep 1st in section
        CoreModule.forRoot(),
        UiModule.forRoot(),
        KeyboardModule.forRoot([
            {
                provide: KEYBOARD_ENABLED_GETTER,
                useFactory: keyboardEnabledFactory,
                deps: [ConfigService],
            },
            {
                provide: KEYBOARD_LOCALE_GETTER,
                useFactory: keyboardLocaleFactory,
                deps: [ConfigService],
            },
        ]),
        // Domains
        AuthModule,
        DataAccessDeviceModule,
        DeviceSharedModule,
        PdmsShellModule,
        TelemedicineShellModule,
        TelemedicineSharedModule,
        VoiceSharedModule,
        FeatureFlagsModule,
    ],
    providers: [
        {
            provide: CONFIG_UPDATER,
            useFactory: configUpdaterFactory,
            deps: [PLATFORM, MonaRpcService, SYNC_STORAGE],
        },
        {
            provide: WS_ENDPOINTS_LIST,
            useValue: ['api.coreWsUrl', 'api.telemedicineWsUrl'],
        },
        PlatformElectronGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
