import { AdditionalDevice, AdditionalDeviceCode } from './additional-device.model';
import { BalanceTarget } from './balance-target.model';
import { BasicCareProcedureCode } from './basic-care-procedure-type.model';
import { BasicCareProcedure } from './basic-care-procedure.model';
import { LabValueCode } from './lab-value-type.model';
import { LabValue } from './lab-value.model';
import { MedicationAdministration } from './medication-administration.model';
import { MedicationPrescription } from './medication-prescription.model';
import { OutputFactorCode } from './output-factor.model';
import { PatientOutput } from './output.model';
import { ProcedurePrescription } from './procedure-prescription.model';
import { Procedure } from './procedure.model';
import { TableDataValues } from './table-data-values.model';
import { VentilationParameterCode } from './ventilation-parameter-type.model';
import { VentilationParameter } from './ventilation-parameter.model';
import { VentilationProcedure } from './ventilation-procedure.model';
import { VitalSignCode } from './vital-sign-type.model';
import { VitalSign } from './vital-sign.model';

type VentilationTableEntries = any;

/** TableViewMode Enum */
export enum TableViewModeEnum {
    Interval = 1,
    Range = 2,
}

/**
 * Unit to detect range inputs
 */
export const RANGE_INPUTS_UNITS = ['ml/h'];

/**
 * Default balance unit
 */
export const BALANCE_UNIT = 'ml';

/**
 * Table data item type
 */
export enum TableDataItemDataType {
    /**
     * Integer value
     */
    INTEGER = 'integer',

    /**
     * Decimal value
     */
    DECIMAL = 'decimal',

    /**
     * Enum Value
     */
    ENUM = 'enum',

    /**
     * Group of values for other vital signs
     */
    GROUP = 'group',

    /**
     * Special type for active ventilation row
     */
    ACTIVE_VENTILATION = 'active-ventilation',

    /**
     * Special type for ventilation mode row
     */
    VENTILATION_MODE = 'ventilation-mode',

    /**
     * Special row type for inputs table blood products
     */
    BLOOD_PRODUCT = 'blood-product',

    /**
     * Special row for inputs with time range
     */
    INPUT_WITH_RANGE = 'input-with-range',

    /**
     * String input
     */
    TEXT = 'text',

    /**
     * Boolean input
     */
    BOOLEAN = 'boolean',

    /**
     * Quantity
     */
    QUANTITY = 'quantity',
}

/**
 * Get input type by category
 * @param item
 */
export function getInputTypeByCategory(item: MedicationPrescription): TableDataItemDataType {
    const { unitRate, unitAmount } = item;
    if (item.category === 'BLOOD_PRODUCTS') {
        return TableDataItemDataType.BLOOD_PRODUCT;
    } else if (RANGE_INPUTS_UNITS.includes(unitRate) || RANGE_INPUTS_UNITS.includes(unitAmount)) {
        return TableDataItemDataType.INPUT_WITH_RANGE;
    }

    return TableDataItemDataType.DECIMAL;
}

/**
 * Describes an entry for the table data source
 */
export interface TableDataItem<
    T extends
        | VitalSign
        | LabValue
        | VentilationParameter
        | VentilationProcedure
        | AdditionalDevice
        | PatientOutput
        | MedicationAdministration
        | MedicationPrescription
        | BalanceTarget
        | BasicCareProcedure
        | Procedure,
    R extends
        | VitalSign
        | LabValue
        | VentilationParameter
        | AdditionalDevice
        | PatientOutput
        | MedicationAdministration
        | Procedure = any, // FIXME
> {
    /**
     * Loinc Code (or string prescription id in inputs table) (table row identifier)
     */
    code:
        | VitalSignCode
        | LabValueCode
        | VentilationParameterCode
        | AdditionalDeviceCode
        | OutputFactorCode
        | BasicCareProcedureCode
        | string;

    /**
     * Parent code
     */
    parent?:
        | VitalSignCode
        | LabValueCode
        | VentilationParameterCode
        | AdditionalDeviceCode
        | OutputFactorCode
        | BasicCareProcedureCode
        | string;

    /**
     * Label displayed in name column
     */
    name: string;

    /**
     * Parent label displayed in name column
     */
    parentName?: string;

    /**
     * The values filled into the time columns.
     * Key of each value must be an iso date string.
     * The value is only displayed if it is listed in the displayed columns
     */
    values: TableDataValues<R>;

    /**
     * Raw data from API
     */
    data?: any[];

    /** Values length */
    size?: number;

    /**
     * Value type
     */
    dataType: TableDataItemDataType;

    /**
     * Readonly - disable add
     */
    readonly?: boolean;

    /**
     * Child items
     */
    children?: TableDataItem<T, R>[];

    /** Calculate & reneder lookup value (previous non-visible value) */
    lookup?: boolean;

    /** Filter-out (DO NOT show) lookup value (previous non-visible value) */
    lookupValueSkipFn?: (row: T, column: any, cell: any) => boolean;

    /**
     * If item is a group
     */
    isGroup?: boolean;

    /**
     * If a group should be displayed as extended or not
     */
    isExpanded?: boolean;

    /**
     * The color from the chart
     */
    color?: string;

    /**
     * The color from the chart
     *
     * @deprecated
     */
    chartColor?: string;

    /**
     * Ventilation entries
     * Only relevant for ventilation table
     */
    ventilationEntries?: T extends VentilationProcedure ? VentilationTableEntries : undefined;

    /**
     * Related prescription (only relevant for inputs table and procedures table)
     */
    relatedPrescription?: Partial<
        MedicationPrescription & { procedureCategoryCode?: ProcedurePrescription['category'] }
    >;

    /**
     * Unit
     */
    unit?: string;

    /**
     * Is row data automated
     * Automated rows are read-only
     */
    isAutomated?: boolean;

    /**
     * Is row data should not be filtered
     */
    skipFilter?: boolean;
}
