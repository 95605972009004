// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Patient in the Encounter.
 * @see #/components/schemas/PatientInEncounter - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PatientInEncounter {
    /**
     * Patient unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The patient number.
     *
     */
    patient_number: string;
    /**
     * The prefix part of the patient\'s name.
     *
     */
    prefix?: string | null;
    /**
     * The first name part of the patient\'s name.
     *
     */
    first_name: string;
    /**
     * The last name part of the patient\'s name.
     *
     */
    last_name: string;
    /**
     * The sex of the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
     *
     */
    gender: PatientInEncounter.GenderEnum;
    /**
     * The date of birth of the patient.
     * @dataFormat date-time
     */
    date_of_birth: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PatientInEncounter
 */
export namespace PatientInEncounter {
    export type GenderEnum = 'male' | 'female' | 'other' | 'unknown';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum,
        Other: 'other' as GenderEnum,
        Unknown: 'unknown' as GenderEnum,
    };

    /**
     * PatientInEncounter Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Patient unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The patient number.
         * @type string (`dataFormat` is missing)
         */
        patientNumber: string;
        /**
         * The prefix part of the patient\'s name.
         * @type string (`dataFormat` is missing)
         */
        prefix?: string | null;
        /**
         * The first name part of the patient\'s name.
         * @type string (`dataFormat` is missing)
         */
        firstName: string;
        /**
         * The last name part of the patient\'s name.
         * @type string (`dataFormat` is missing)
         */
        lastName: string;
        /**
         * The sex of the patient.  * `male` - MALE * `female` - FEMALE * `other` - OTHER * `unknown` - UNKNOWN
         * @type string (`dataFormat` is missing)
         */
        gender: PatientInEncounter.GenderEnum;
        /**
         * The date of birth of the patient.
         * @dataFormat date-time
         */
        @TransformDate() dateOfBirth: Date;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PatientInEncounter DTO
     * Transforms PatientInEncounter model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PatientInEncounter model */
        static toModel(obj: PatientInEncounter): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PatientInEncounter model */
        static toApi(obj: Model): PatientInEncounter {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PatientInEncounter;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
