// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

export interface SeedRunRequest {
    /**
     * Terminology seed package name.
     *
     */
    name?: string;
    /**
     * Terminology seed package version.
     *
     */
    version?: string;
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    access_permissions?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    additional_devices?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    administration_method?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    basic_care_procedures?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    blood_administration?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    care_check_types?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    daily_goal?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    diagnoses?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    discharge_reason?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    dosage_form?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication_category_field_config?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication_category?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    lab_values?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication_group?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication_unit?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    medication_solutions?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    output_factor?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    practitioner_groups?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    prescription_not_given_reason?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    prescription_frequency?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    practitioner_shift?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    procedure_category?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    questionnaire_item_type?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    prescription_frequency_times?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    report_image?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    report_type?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    report_template?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    relationship_role_type?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    vaccine_codes?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    ventilation_modes?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    ventilation_parameters?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    vital_signs?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    workflow_answer_score?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    workflow_answer_confirmation?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    workflow_types?: { [key: string]: any };
    /**
     * Whether to overwrite, updated and cascade delete.
     *
     */
    terminology_match?: { [key: string]: any };
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for SeedRunRequest
 */
export namespace SeedRunRequest {
    /**
     * SeedRunRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Terminology seed package name.
         * @type string (`dataFormat` is missing)
         */
        name?: string;
        /**
         * Terminology seed package version.
         * @type string (`dataFormat` is missing)
         */
        version?: string;
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        accessPermissions?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        additionalDevices?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        administrationMethod?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        basicCareProcedures?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        bloodAdministration?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        careCheckTypes?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        dailyGoal?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        diagnoses?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        dischargeReason?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        dosageForm?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medicationCategoryFieldConfig?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medicationCategory?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        labValues?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medication?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medicationGroup?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medicationUnit?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        medicationSolutions?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        outputFactor?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        practitionerGroups?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        prescriptionNotGivenReason?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        prescriptionFrequency?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        practitionerShift?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        procedureCategory?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        questionnaireItemType?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        prescriptionFrequencyTimes?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        reportImage?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        reportType?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        reportTemplate?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        relationshipRoleType?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        vaccineCodes?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        ventilationModes?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        ventilationParameters?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        vitalSigns?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        workflowAnswerScore?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        workflowAnswerConfirmation?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        workflowTypes?: { [key: string]: any };
        /**
         * Whether to overwrite, updated and cascade delete.
         * @type { [key: string]: any; } (`dataFormat` is missing)
         */
        terminologyMatch?: { [key: string]: any };

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * SeedRunRequest DTO
     * Transforms SeedRunRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend SeedRunRequest model */
        static toModel(obj: SeedRunRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend SeedRunRequest model */
        static toApi(obj: Model): SeedRunRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as SeedRunRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
