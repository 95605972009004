// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowAnswerOptionCreateUpdateRequest } from './workflowAnswerOptionCreateUpdateRequest';

/**
 * Serializer for validating and updating items Workflow/Smart workflows.
 * @see #/components/schemas/WorkflowUpdateItemRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowUpdateItemRequest {
    /**
     * The name of the Item Questionnaire.
     *
     */
    title?: string;
    required?: boolean;
    /**
     * The description of the Item Questionnaire.
     *
     */
    description?: string;
    /**
     * The type of Item Questionnaire.
     *
     */
    item_type?: string;
    /**
     * The answer options items of Workflow.
     *
     */
    answer_options?: Array<WorkflowAnswerOptionCreateUpdateRequest> | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowUpdateItemRequest
 */
export namespace WorkflowUpdateItemRequest {
    /**
     * WorkflowUpdateItemRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The name of the Item Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        title?: string;
        required?: boolean;
        /**
         * The description of the Item Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        description?: string;
        /**
         * The type of Item Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        itemType?: string;
        /**
         * The answer options items of Workflow.
         * @type Array<WorkflowAnswerOptionCreateUpdateRequest> (`dataFormat` is missing)
         */
        @Type(() => WorkflowAnswerOptionCreateUpdateRequest.Model)
        answerOptions?: Array<WorkflowAnswerOptionCreateUpdateRequest.Model> | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowUpdateItemRequest DTO
     * Transforms WorkflowUpdateItemRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowUpdateItemRequest model */
        static toModel(obj: WorkflowUpdateItemRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowUpdateItemRequest model */
        static toApi(obj: Model): WorkflowUpdateItemRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowUpdateItemRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
