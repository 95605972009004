<div class="mona-auth-dialog__container" #dialog>
    <div mat-dialog-content>
        <button mat-icon-button type="button" class="mona-auth-dialog__close" (click)="close()" *ngIf="showCloseBtn">
            <mat-icon>close</mat-icon>
        </button>

        <mat-tab-group [(selectedIndex)]="selectedTab" mat-stretch-tabs="false">
            <mat-tab class="mona-auth-dialog__tab" label="">
                <ng-template matTabContent>
                    <mona-rfid-form [isLoading]="isLoading$ | async">
                        <div *ngIf="data.authType === authType.All" class="text-center pb-3">
                            <button mat-button class="text-primary" (click)="selectedTab = 1">
                                {{ 'auth.signInDialog.orVerifyByUsername' | translate }}
                            </button>
                        </div>

                        <mona-changelog-tags
                            *ngIf="flow === authFlow.verify"
                            [changeLogTags]="changeLogTags"
                            [tagsTitle]="tagsTitle$ | async"
                        ></mona-changelog-tags>
                    </mona-rfid-form>
                </ng-template>
            </mat-tab>

            <mat-tab class="mona-auth-dialog__tab" label="">
                <ng-template matTabContent>
                    <mona-log-in-form
                        [isLoading]="isLoading$ | async"
                        [title]="loginTitle"
                        [submitTitle]="submitTitle"
                        [loginError]="authError$ | async"
                        (submitForm)="onSubmitForm($event)"
                    >
                        <div *ngIf="additionalMessage" class="text-center">
                            <p>{{ additionalMessage | translate }}</p>
                        </div>

                        <div *ngIf="data.authType === authType.All" class="text-center pb-3">
                            <button mat-button class="mona-auth-dialog__back" (click)="selectedTab = 0">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'oobe.common.back' | translate }}
                            </button>
                        </div>

                        <mona-changelog-tags
                            *ngIf="flow === authFlow.verify"
                            [changeLogTags]="changeLogTags"
                            [tagsTitle]="tagsTitle$ | async"
                        ></mona-changelog-tags>
                    </mona-log-in-form>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
