// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.62
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-22T13:06:11.488936+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { AdditionalDeviceTypeComponent } from './additionalDeviceTypeComponent';

/**
 * Serializes Vital Sign Types from database.
 * @see #/components/schemas/AdditionalDeviceType - reference to schema in open-api-spec.yaml
 * @since 2024-11-22T13:06:11.488936+02:00[Europe/Kiev]
 * @version 1.6.62
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface AdditionalDeviceType {
    readonly code: string;
    /**
     * The display name for the vital sign
     *
     */
    display_name: string;
    /**
     * The type for the vital sign  * `integer` - Ganzzahl * `decimal` - Decimal * `text` - Text * `boolean` - Boolean * `group` - Group
     *
     */
    type: AdditionalDeviceType.TypeEnum;
    /**
     * The unit for the vital sign (required for integer and decimal types)
     *
     */
    unit?: string;
    /**
     * The visibility for the vital sign
     *
     */
    is_visible?: boolean;
    readonly components: Array<AdditionalDeviceTypeComponent>;
    /**
     * Whether Vital Sign Types is automated.
     *
     */
    readonly is_automated: boolean;
    /**
     * Whether the additional device is always visible.
     *
     */
    is_always_visible?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for AdditionalDeviceType
 */
export namespace AdditionalDeviceType {
    export type TypeEnum = 'integer' | 'decimal' | 'text' | 'boolean' | 'group';
    export const TypeEnum = {
        Integer: 'integer' as TypeEnum,
        Decimal: 'decimal' as TypeEnum,
        Text: 'text' as TypeEnum,
        Boolean: 'boolean' as TypeEnum,
        Group: 'group' as TypeEnum,
    };

    /**
     * AdditionalDeviceType Frontend Model with camelCase properties
     * @since 2024-11-22T13:06:11.488936+02:00[Europe/Kiev]
     * @version 1.6.62
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        readonly code: string;
        /**
         * The display name for the vital sign
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The type for the vital sign  * `integer` - Ganzzahl * `decimal` - Decimal * `text` - Text * `boolean` - Boolean * `group` - Group
         * @type string (`dataFormat` is missing)
         */
        type: AdditionalDeviceType.TypeEnum;
        /**
         * The unit for the vital sign (required for integer and decimal types)
         * @type string (`dataFormat` is missing)
         */
        unit?: string;
        /**
         * The visibility for the vital sign
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;
        @Type(() => AdditionalDeviceTypeComponent.Model)
        readonly components: Array<AdditionalDeviceTypeComponent.Model>;
        /**
         * Whether Vital Sign Types is automated.
         * @type boolean (`dataFormat` is missing)
         */
        readonly isAutomated: boolean;
        /**
         * Whether the additional device is always visible.
         * @type boolean (`dataFormat` is missing)
         */
        isAlwaysVisible?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * AdditionalDeviceType DTO
     * Transforms AdditionalDeviceType model from/to API object
     * @since 2024-11-22T13:06:11.488936+02:00[Europe/Kiev]
     * @version 1.6.62
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend AdditionalDeviceType model */
        static toModel(obj: AdditionalDeviceType): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend AdditionalDeviceType model */
        static toApi(obj: Model): AdditionalDeviceType {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as AdditionalDeviceType;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
