// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { AdmissionAllergyIntoleranceRequest } from './admissionAllergyIntoleranceRequest';
import { AdmissionAnamnesisRequest } from './admissionAnamnesisRequest';
import { AdmissionPhysicalExaminationRequest } from './admissionPhysicalExaminationRequest';
import { AdmissionPreMedicationRequest } from './admissionPreMedicationRequest';
import { AdmissionDiagnosisRequest } from './admissionDiagnosisRequest';
import { AdmissionVitalSignsRequest } from './admissionVitalSignsRequest';
import { AdmissionPatientRequest } from './admissionPatientRequest';

/**
 * Serializer for validating patient admission data.
 * @see #/components/schemas/PatientAdmissionRequest - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface PatientAdmissionRequest {
    /**
     * The RFID to authenticate the user.
     *
     */
    rfid: string;
    /**
     * Bed unique id.
     * @dataFormat uuid
     */
    bed_id: string;
    /**
     * The identifying description of the patient\'s reason for the encounter.
     *
     */
    admission_diagnosis?: string;
    /**
     * Patient payload used for admission.
     *
     */
    patient?: AdmissionPatientRequest | null;
    /**
     * The unique id of an existing patient.
     * @dataFormat uuid
     */
    patient_id?: string;
    /**
     * Vital signs payload used for admission.
     *
     */
    vital_signs?: AdmissionVitalSignsRequest | null;
    /**
     * Anamnesis payload for admission.
     *
     */
    anamnesis?: AdmissionAnamnesisRequest | null;
    /**
     * Physical examination payload for admission.
     *
     */
    physical_examination?: AdmissionPhysicalExaminationRequest | null;
    /**
     * Diagnosis payload for admission.
     *
     */
    diagnoses?: Array<AdmissionDiagnosisRequest>;
    /**
     * AllergyIntolerance for Admission
     *
     */
    allergies_intolerances?: Array<AdmissionAllergyIntoleranceRequest>;
    /**
     * PreMedication for Admission
     *
     */
    pre_medication?: Array<AdmissionPreMedicationRequest>;
    /**
     * Parent encounter id.
     * @dataFormat uuid
     */
    parent_encounter?: string;
    /**
     * Case id.
     *
     */
    case_id?: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for PatientAdmissionRequest
 */
export namespace PatientAdmissionRequest {
    /**
     * PatientAdmissionRequest Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The RFID to authenticate the user.
         * @type string (`dataFormat` is missing)
         */
        rfid: string;
        /**
         * Bed unique id.
         * @dataFormat uuid
         */
        bedId: string;
        /**
         * The identifying description of the patient\'s reason for the encounter.
         * @type string (`dataFormat` is missing)
         */
        admissionDiagnosis?: string;
        /**
         * Patient payload used for admission.
         * @type AdmissionPatientRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionPatientRequest.Model) patient?: AdmissionPatientRequest.Model | null;
        /**
         * The unique id of an existing patient.
         * @dataFormat uuid
         */
        patientId?: string;
        /**
         * Vital signs payload used for admission.
         * @type AdmissionVitalSignsRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionVitalSignsRequest.Model) vitalSigns?: AdmissionVitalSignsRequest.Model | null;
        /**
         * Anamnesis payload for admission.
         * @type AdmissionAnamnesisRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionAnamnesisRequest.Model) anamnesis?: AdmissionAnamnesisRequest.Model | null;
        /**
         * Physical examination payload for admission.
         * @type AdmissionPhysicalExaminationRequest (`dataFormat` is missing)
         */
        @Type(() => AdmissionPhysicalExaminationRequest.Model)
        physicalExamination?: AdmissionPhysicalExaminationRequest.Model | null;
        /**
         * Diagnosis payload for admission.
         * @type Array<AdmissionDiagnosisRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionDiagnosisRequest.Model) diagnoses?: Array<AdmissionDiagnosisRequest.Model>;
        /**
         * AllergyIntolerance for Admission
         * @type Array<AdmissionAllergyIntoleranceRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionAllergyIntoleranceRequest.Model)
        allergiesIntolerances?: Array<AdmissionAllergyIntoleranceRequest.Model>;
        /**
         * PreMedication for Admission
         * @type Array<AdmissionPreMedicationRequest> (`dataFormat` is missing)
         */
        @Type(() => AdmissionPreMedicationRequest.Model) preMedication?: Array<AdmissionPreMedicationRequest.Model>;
        /**
         * Parent encounter id.
         * @dataFormat uuid
         */
        parentEncounter?: string;
        /**
         * Case id.
         * @type string (`dataFormat` is missing)
         */
        caseId?: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * PatientAdmissionRequest DTO
     * Transforms PatientAdmissionRequest model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend PatientAdmissionRequest model */
        static toModel(obj: PatientAdmissionRequest): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend PatientAdmissionRequest model */
        static toApi(obj: Model): PatientAdmissionRequest {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as PatientAdmissionRequest;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
