import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { MessageService } from '@mona/ui';
import { ReportsApi } from '../../infrastructure';
import * as ReportsActions from '../actions/reports.actions';

/**
 * Export reports effects
 */
@Injectable({
    providedIn: 'root',
})
export class ReportsEffects {
    /**
     * Load reports types effect
     */

    loadReportTypesList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportsActions.loadReportTypes),
            switchMap(() => this.reportsApi.loadReportsList()),
            map(reportTypes => ReportsActions.loadReportTypesSuccess({ reportTypes })),
            catchError((error: unknown) => of(ReportsActions.loadReportTypesFailure({ error }))),
        ),
    );
    /**
     * Load report export locations
     */
    loadReportExportLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportsActions.loadReportsExportLocations),
            switchMap(() => this.reportsApi.loadReportsExportLocations()),
            map(locations => ReportsActions.loadReportsExportLocationsSuccess({ locations })),
            catchError((error: unknown) => of(ReportsActions.loadReportsExportLocationsFailure({ error }))),
        ),
    );
    /**
     * Export full report success effect
     */
    exportFullReportSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportsActions.exportReportSuccess),
                tap(() => {
                    this.messageService.successToast(this.translateService.instant('apps.reports.success'));
                }),
            ),
        { dispatch: false },
    );
    /**
     * Export full report failed effect
     */
    exportFullReportFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ReportsActions.exportReportFailed),
                tap(error => this.messageService.errorToast(this.translateService.instant('apps.reports.failed'))),
            ),
        { dispatch: false },
    );
    /** timezone value as string (example Germany/Berlin, Kiev/Ukraine) */
    private readonly timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /**
     * Export Full report effect
     */

    exportFullReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ReportsActions.exportReport),
            withCurrentEncounterId(),
            switchMap(([{ reportType, reportLocation }, encounterId]) =>
                this.reportsApi
                    .exportReport(reportType, reportLocation, encounterId, this.timezoneString)
                    .pipe(catchError((error: unknown) => of(ReportsActions.exportReportFailed({ error })))),
            ),
            map(() => ReportsActions.exportReportSuccess()),
        ),
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param reportsApi AvailableReportsApi
     * @param authService AuthService
     * @param messageService MessageService
     * @param translateService TranslateService
     */
    constructor(
        private store: Store,
        private actions$: Actions,
        private reportsApi: ReportsApi,
        private authService: AuthService,
        private messageService: MessageService,
        private translateService: TranslateService,
    ) {}
}
