import { combineReducers } from '@ngrx/store';
import { locationsReducer } from './locations.reducer';
import { reportTypesReducer } from './report.reducer';

export const REPORTS_REDUCERS_TOKEN = 'reports';

export const reportsReducer = combineReducers({
    reportTypes: reportTypesReducer,
    locations: locationsReducer,
});
