import { NgModule } from '@angular/core';
import { UiModule } from '@mona/ui';
import { UpdateRfidComponent } from './update-rfid.component';

/**
 * UpdateRfidModule
 */
@NgModule({
    declarations: [UpdateRfidComponent],
    exports: [UpdateRfidComponent],
    imports: [UiModule],
})
export class UpdateRfidModule {}
