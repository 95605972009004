// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Medication Solution from database.
 * @see #/components/schemas/MedicationSolution - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationSolution {
    /**
     * The mona code for the Medication Solution.
     * @dataFormat uuid
     */
    readonly code: string;
    readonly display_name: string;
    /**
     * The Medication Solution SNOMED code.
     *
     */
    snomed_code?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationSolution
 */
export namespace MedicationSolution {
    /**
     * MedicationSolution Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The mona code for the Medication Solution.
         * @dataFormat uuid
         */
        readonly code: string;
        readonly displayName: string;
        /**
         * The Medication Solution SNOMED code.
         * @type string (`dataFormat` is missing)
         */
        snomedCode?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationSolution DTO
     * Transforms MedicationSolution model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationSolution model */
        static toModel(obj: MedicationSolution): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationSolution model */
        static toApi(obj: Model): MedicationSolution {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationSolution;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
