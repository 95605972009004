import { Injectable } from '@angular/core';
import { TerminologyService } from './terminology.service';

/**
 * Terminology New Service
 */
@Injectable({
    providedIn: 'root',
})
export class CombinedTerminologyService {
    /**
     * Terminology New Service Constructor
     *
     * @param terminologyService
     */
    constructor(private terminologyService: TerminologyService) {}

    /**
     * Load Terminologies
     *
     * @param force
     */
    loadTerminologies(force = false): void {
        if (force) {
            this.terminologyService.invalidateCache();
        }
        this.terminologyService.loadOutputFactors();
        this.terminologyService.loadProcedureCategories();
        this.terminologyService.loadPrescriptionFrequencyTimes();
        this.terminologyService.loadBasicCareProcedureTypes();
        this.terminologyService.loadPractitionerShifts();
        this.terminologyService.loadCareCheckTypes({ is_visible: true });
        this.terminologyService.loadVitalSignTypes({ is_visible: true });
        this.terminologyService.loadVentilationParameterTypes({ is_visible: true });
        this.terminologyService.loadVentilationModes({ is_visible: true });
        this.terminologyService.loadLabValueTypes({ is_visible: true });
        this.terminologyService.loadPrescriptionTerminology();
        this.terminologyService.loadDischargeReasons();
        this.terminologyService.loadGlasgowComaScaleValues();
        this.terminologyService.loadTaskListShiftFilters();
        this.terminologyService.loadDailyGoalsTerminology();
        this.terminologyService.loadPrescriptionNotGivenReasons();
        this.terminologyService.loadVaccineCodes();
        this.terminologyService.loadRelationshipRoleTypes();
        this.terminologyService.loadAdditionalDevicesTypes();
    }
}
