// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowAnswerOptionConfirmation } from './workflowAnswerOptionConfirmation';

/**
 * Serializer that is used for validating WorkflowAnswerOption.
 * @see #/components/schemas/WorkflowAnswerOption - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowAnswerOption {
    /**
     * The display text of Workflow-Answer-Option.
     *
     */
    display: string;
    /**
     * The score of Workflow-Answer-Option.
     *
     */
    score?: number | null;
    /**
     * The confirmation of Workflow-Answer-Option.
     *
     */
    confirmation?: WorkflowAnswerOptionConfirmation | null;
    /**
     * The code of Workflow-Answer-Option.
     *
     */
    code?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowAnswerOption
 */
export namespace WorkflowAnswerOption {
    /**
     * WorkflowAnswerOption Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The display text of Workflow-Answer-Option.
         * @type string (`dataFormat` is missing)
         */
        display: string;
        /**
         * The score of Workflow-Answer-Option.
         * @type number (`dataFormat` is missing)
         */
        score?: number | null;
        /**
         * The confirmation of Workflow-Answer-Option.
         * @type WorkflowAnswerOptionConfirmation (`dataFormat` is missing)
         */
        @Type(() => WorkflowAnswerOptionConfirmation.Model)
        confirmation?: WorkflowAnswerOptionConfirmation.Model | null;
        /**
         * The code of Workflow-Answer-Option.
         * @type string (`dataFormat` is missing)
         */
        code?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowAnswerOption DTO
     * Transforms WorkflowAnswerOption model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowAnswerOption model */
        static toModel(obj: WorkflowAnswerOption): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowAnswerOption model */
        static toApi(obj: Model): WorkflowAnswerOption {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowAnswerOption;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
