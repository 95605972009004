import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UiEchartsModule } from '@mona/shared/chart';
import { UtilsModule } from '@mona/shared/utils';
import {
    UiAppBarModule,
    UiButtonExtensionModule,
    UiCardModule,
    UiCalendarSliderModule,
    UiDialogModule,
    UiDrawerModule,
    UiDropdownToolbarModule,
    UiFormsModule,
    UiEmptyStateModule,
    UiInfoListItemModule,
    UiLoadingOverlayModule,
    UiLogoModule,
    UiMessageModule,
    UiOverlayContainerModule,
    UiSettingsLayoutModule,
    UiUserSettingsLayoutModule,
    UiSpacerModule,
    UiTableModule,
    UiThreeLinerModule,
    UiUserMenuModule,
    UiTabBarModule,
    UiTemplateRefModule,
} from './components';
import { UiDirectivesModule } from './directives';
import { MaterialModule } from './material.module';
import { IconService } from './services';

///////////////////////////////////////////////////////////
//                                                       //
// Use this only for modules that are needed globally    //
//                                                       //
// Do not declare anything here. Only import and export! //
//                                                       //
///////////////////////////////////////////////////////////

/**
 * List of modules that are needed globally
 */
const UI_MODULES = [
    // 3rd-party
    MaterialModule,
    NgxMaskModule,
    NgxPermissionsModule,
    ContentLoaderModule,
    // Mona
    UtilsModule,
    UiAppBarModule,
    UiDirectivesModule,
    UiButtonExtensionModule,
    UiCalendarSliderModule,
    UiCardModule,
    UiDialogModule,
    UiDrawerModule,
    UiDropdownToolbarModule,
    UiFormsModule,
    UiEmptyStateModule,
    UiInfoListItemModule,
    UiLoadingOverlayModule,
    UiLogoModule,
    UiMessageModule,
    UiOverlayContainerModule,
    UiSettingsLayoutModule,
    UiUserSettingsLayoutModule,
    UiSpacerModule,
    UiTableModule,
    UiTabBarModule,
    UiThreeLinerModule,
    UiUserMenuModule,
    UiTemplateRefModule,
];

/**
 * Icons preload factory
 *
 * @param {IconService} iconService
 */
export function iconsPreloadFactory(iconService: IconService) {
    return () => Promise.resolve(iconService.registerIcons());
}

/** Shared Module */
@NgModule({
    imports: [...UI_MODULES],
    exports: [...UI_MODULES],
})
export class UiModule {
    /** static method to be used in AppModule */
    static forRoot(): ModuleWithProviders<UiModule> {
        return {
            ngModule: UiModule,
            providers: [
                //
                ...MaterialModule.forRoot().providers,
                ...NgxMaskModule.forRoot().providers,
                ...UiEchartsModule.forRoot({
                    echarts: () => import('echarts/index.common'),
                }).providers,
                //
                { provide: APP_INITIALIZER, useFactory: iconsPreloadFactory, deps: [IconService], multi: true },
            ],
        };
    }
}
