import { ApiModels } from '@mona/api';
import { AdditionalDevice } from '@mona/models';

/**
 * Transforms api additional devices
 * @param apiAdditionalDevices AdditionalDevices
 */
export const transformApiAdditionalDevice = (apiAdditionalDevices: ApiModels.AdditionalDevice): AdditionalDevice => {
    return ApiModels.AdditionalDevice.DTO.toModel(apiAdditionalDevices);
};

/**
 * Transform api additional devicess
 * @param apiAdditionalDevicess ApiModels.PdmsAdditionalDevices[]
 */
export const transformApiAdditionalDevices = (
    apiAdditionalDevicess: ApiModels.AdditionalDevice[],
): AdditionalDevice[] => {
    return apiAdditionalDevicess.map(apiAdditionalDevices => transformApiAdditionalDevice(apiAdditionalDevices));
};
