import { createAction, props } from '@ngrx/store';
import { Encounter, MonaNotification, NotificationResolution } from '@mona/models';
import { AsyncAction, FailedAction, SucceededAction } from '@mona/store';

/**
 * Notifications actions
 */
export class NotificationsAction {
    /**
     * Init notifications subscription
     */
    static initNotificationSub = createAction('NOTIFICATIONS:INIT_NOTIFICATIONS_SUB');
    /**
     * Load notifications action
     */
    static loadNotificationsAction: AsyncAction<
        {
            encounterId: EntityId<Encounter>;
        },
        MonaNotification[]
    > = {
        action: createAction(
            'NOTIFICATIONS:LOAD_NOTIFICATIONS',
            props<{
                encounterId: EntityId<Encounter>;
            }>(),
        ),

        succeededAction: createAction(
            'NOTIFICATIONS:LOAD_NOTIFICATIONS_SUCCEEDED',
            props<SucceededAction<MonaNotification[]>>(),
        ),

        failedAction: createAction('NOTIFICATIONS:LOAD_NOTIFICATIONS_FAILED', props<FailedAction>()),

        clearAction: createAction('NOTIFICATIONS:LOAD_NOTIFICATIONS_CLEAR'),
    };

    /**
     * Load notifications action
     */
    static resolveNotificationAction: AsyncAction<
        {
            notification: MonaNotification;
            resolution: NotificationResolution;
            rfid?: string;
        },
        MonaNotification
    > = {
        action: createAction(
            'NOTIFICATIONS:RESOLVE_NOTIFICATION',
            props<{
                notification: MonaNotification;
                rfid: string;
                resolution: NotificationResolution;
            }>(),
        ),

        succeededAction: createAction(
            'NOTIFICATIONS:RESOLVE_NOTIFICATION_SUCCEEDED',
            props<SucceededAction<MonaNotification>>(),
        ),

        failedAction: createAction('NOTIFICATIONS:RESOLVE_NOTIFICATION_FAILED', props<FailedAction>()),

        clearAction: createAction('NOTIFICATIONS:RESOLVE_NOTIFICATION_CLEAR'),
    };

    /**
     * Add/update notification action
     */
    static addUpdateNotificationAction = createAction(
        'NOTIFICATIONS:ADD_UPDATE_NOTIFICATIONS_SUCCEEDED',
        props<SucceededAction<MonaNotification>>(),
    );

    /**
     * Change read status action
     */
    static changeReadStatusAction: AsyncAction<
        {
            notificationIds: string[];
            readStatus: boolean;
        },
        MonaNotification
    > = {
        action: createAction(
            'NOTIFICATIONS:CHANGE_READ_STATUS',
            props<{
                notificationIds: string[];
                readStatus: boolean;
            }>(),
        ),
        succeededAction: createAction(
            'NOTIFICATIONS:CHANGE_READ_STATUS_SUCCEEDED',
            props<SucceededAction<MonaNotification>>(),
        ),
        failedAction: createAction(
            'NOTIFICATIONS:CHANGE_READ_STATUS_FAILED',
            props<FailedAction>(),
        ),
        clearAction: createAction('NOTIFICATIONS:CHANGE_READ_STATUS_CLEAR'),
    };
}
