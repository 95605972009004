// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer that is used for creating/updating CareCheck-Items inside EntryControl model structure.
 * @see #/components/schemas/CareCheck - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface CareCheck {
    /**
     * Code of The type of the care-check.
     * @dataFormat uuid
     */
    type: string;
    /**
     * The location on the patient\'s body where the check was performed.
     *
     */
    location: string;
    /**
     * The size of the checked object.
     *
     */
    size: string;
    /**
     * Result of visual inspection of the checked object and the affected location.
     *
     */
    visual_inspection: string;
    /**
     * Date when the checked object was installed.
     * @dataFormat date
     */
    installation_date: string;
    /**
     * Date when the checked object was last changed.
     * @dataFormat date
     */
    last_change: string;
    /**
     * If the care-check is approved by the author.
     *
     */
    is_approved: boolean;
    /**
     * A free-text comment for the check.
     *
     */
    note?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for CareCheck
 */
export namespace CareCheck {
    /**
     * CareCheck Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Code of The type of the care-check.
         * @dataFormat uuid
         */
        type: string;
        /**
         * The location on the patient\'s body where the check was performed.
         * @type string (`dataFormat` is missing)
         */
        location: string;
        /**
         * The size of the checked object.
         * @type string (`dataFormat` is missing)
         */
        size: string;
        /**
         * Result of visual inspection of the checked object and the affected location.
         * @type string (`dataFormat` is missing)
         */
        visualInspection: string;
        /**
         * Date when the checked object was installed.
         * @dataFormat date
         */
        @TransformDate() installationDate: Date;
        /**
         * Date when the checked object was last changed.
         * @dataFormat date
         */
        @TransformDate() lastChange: Date;
        /**
         * If the care-check is approved by the author.
         * @type boolean (`dataFormat` is missing)
         */
        isApproved: boolean;
        /**
         * A free-text comment for the check.
         * @type string (`dataFormat` is missing)
         */
        note?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * CareCheck DTO
     * Transforms CareCheck model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend CareCheck model */
        static toModel(obj: CareCheck): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend CareCheck model */
        static toApi(obj: Model): CareCheck {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as CareCheck;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
