// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for medication prescription list view.
 * @see #/components/schemas/MedicationPrescription - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationPrescription {
    /**
     * The date when the period ended. If empty, the period is ongoing.
     * @dataFormat date-time
     */
    end_date?: string | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
     *
     */
    category: string;
    /**
     * The code to identify the medication. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/code
     * @dataFormat uuid
     */
    code: string;
    /**
     * The textual representation of the code.
     *
     */
    description: string;
    /**
     * The code of the medication dosage form. Custom-Mona-Coding-System https://fhir.mona.icu/CodeSystem/mona-medication-dosage-form
     *
     */
    dosage_form?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    amount?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_amount?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_amount_code?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    rate?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_rate?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_rate_code?: string;
    /**
     * The quantity or ratio for the amount, ratio or volume
     * @dataFormat double
     */
    volume?: number;
    /**
     * Units for amount, ratio and volume.
     *
     */
    unit_volume?: string;
    /**
     * Units codes for amount, ratio and volume.
     *
     */
    unit_volume_code?: string;
    /**
     * The solution code for the volume and unit_volume
     *
     */
    solution_code?: string;
    /**
     * The code of the medication administration method that describes how the medication should be administered. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/MedicationAdministration/method
     *
     */
    method?: string;
    /**
     * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
     * @dataFormat uuid
     */
    frequency: string;
    /**
     * Frequency times for the custom frequency.
     *
     */
    frequency_times?: Array<string>;
    /**
     * A free-text note for the prescription.
     *
     */
    instructions?: string | null;
    /**
     * Determines if the prescription is manually canceled before the planned end-date is reached. (This does not tell if the prescription period has ended!)
     *
     */
    is_stopped: boolean;
    /**
     * Determines if the prescription is approved by a doctor.
     *
     */
    is_approved: boolean;
    /**
     * The date when the period started.
     * @dataFormat date-time
     */
    start_date: string;
    /**
     * The identifier of the related patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * Medication-Prescription unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationPrescription
 */
export namespace MedicationPrescription {
    /**
     * MedicationPrescription Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The date when the period ended. If empty, the period is ongoing.
         * @dataFormat date-time
         */
        @TransformDate() endDate?: Date | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * The code of the medication category. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/category
         * @type string (`dataFormat` is missing)
         */
        category: string;
        /**
         * The code to identify the medication. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Medication/code
         * @dataFormat uuid
         */
        code: string;
        /**
         * The textual representation of the code.
         * @type string (`dataFormat` is missing)
         */
        description: string;
        /**
         * The code of the medication dosage form. Custom-Mona-Coding-System https://fhir.mona.icu/CodeSystem/mona-medication-dosage-form
         * @type string (`dataFormat` is missing)
         */
        dosageForm?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        amount?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitAmount?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitAmountCode?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        rate?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitRate?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitRateCode?: string;
        /**
         * The quantity or ratio for the amount, ratio or volume
         * @dataFormat double
         */
        volume?: number;
        /**
         * Units for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitVolume?: string;
        /**
         * Units codes for amount, ratio and volume.
         * @type string (`dataFormat` is missing)
         */
        unitVolumeCode?: string;
        /**
         * The solution code for the volume and unit_volume
         * @type string (`dataFormat` is missing)
         */
        solutionCode?: string;
        /**
         * The code of the medication administration method that describes how the medication should be administered. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/MedicationAdministration/method
         * @type string (`dataFormat` is missing)
         */
        method?: string;
        /**
         * The code of the prescription frequency that describes how often the administration should take place. Custom-Mona-Coding-System https://fhir.mona.icu/NamingSystem/Prescription/timing
         * @dataFormat uuid
         */
        frequency: string;
        /**
         * Frequency times for the custom frequency.
         * @type Array<string> (`dataFormat` is missing)
         */
        frequencyTimes?: Array<string>;
        /**
         * A free-text note for the prescription.
         * @type string (`dataFormat` is missing)
         */
        instructions?: string | null;
        /**
         * Determines if the prescription is manually canceled before the planned end-date is reached. (This does not tell if the prescription period has ended!)
         * @type boolean (`dataFormat` is missing)
         */
        isStopped: boolean;
        /**
         * Determines if the prescription is approved by a doctor.
         * @type boolean (`dataFormat` is missing)
         */
        isApproved: boolean;
        /**
         * The date when the period started.
         * @dataFormat date-time
         */
        @TransformDate() startDate: Date;
        /**
         * The identifier of the related patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * Medication-Prescription unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationPrescription DTO
     * Transforms MedicationPrescription model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationPrescription model */
        static toModel(obj: MedicationPrescription): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationPrescription model */
        static toApi(obj: Model): MedicationPrescription {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationPrescription;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
