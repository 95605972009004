import { createAction, props } from '@ngrx/store';
import { AdditionalDevice, ParamsModel, PageableModel } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';

/**
 * Additional Devices actions
 */
export class AdditionalDeviceAction {
    static setIntervalAction = createAction(
        'ENCOUNTER:SET_ADDITIONAL_DEVICES_INTERVAL',
        props<{ interval: EntriesInterval }>(),
    );

    static setLoadingAction = createAction('ENCOUNTER:SET_ADDITIONAL_DEVICES_LOADING', props<{ isLoading: boolean }>());

    static loadAdditionalDevices = createAction(
        'ENCOUNTER:LOAD_ADDITIONAL_DEVICES',
        props<{ params?: ParamsModel; url?: string }>(),
    );

    static loadAdditionalDevicesSuccess = createAction(
        'ENCOUNTER:LOAD_ADDITIONAL_DEVICES_SUCCESS',
        props<PageableModel<AdditionalDevice> & { codes: string[] }>(),
    );

    static loadAdditionalDevicesFailed = createAction(
        'ENCOUNTER:LOAD_ADDITIONAL_DEVICES_FAILED',
        props<{ error: unknown }>(),
    );

    static upsertAdditionalDevices = createAction(
        'ENCOUNTER:UPSERT_ADDITIONAL_DEVICES',
        props<{ data: AdditionalDevice[] }>(),
    );
    static removeAdditionalDevices = createAction('ENCOUNTER:REMOVE_ADDITIONAL_DEVICES', props<{ ids: string[] }>());
    static clearAdditionalDevices = createAction('ENCOUNTER:CLEAR_ADDITIONAL_DEVICES');
    static addChanges = createAction(
        'ENCOUNTER:ADD_ADDITIONAL_DEVICES_CHANGES',
        props<{ toUpdateEntities: AdditionalDevice[]; toRemoveIds: string[] }>(),
    );
}
