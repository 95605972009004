import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { ApiHealthFacade } from '@mona/api';
import { AuthService, LockScreenPortalService } from '@mona/auth';
import { FeatureFlagsService, FEATURE_FLAGS } from '@mona/flags';
import { Bed, DataUpdateMessageOperation, DynamicPdmsPermissionEnum, Encounter, Ward } from '@mona/models';
import { PdmsUpdatesWsService } from '@mona/pdms/data-access-changelog';
import { ENCOUNTER_INFO } from '@mona/pdms/data-access-combined';
import { DataAccessEncountersFacade } from '@mona/pdms/data-access-encounters';
import { DataAccessFhirConfigFacade } from '@mona/pdms/data-access-fhir';
import { PractitionersFacade } from '@mona/pdms/data-access-practitioners';
import { ReportsFacade } from '@mona/pdms/data-access-reports';
import { CombinedTerminologyService } from '@mona/pdms/data-access-terminology';
import { DataAccessWardsFacade } from '@mona/pdms/data-access-wards';
import { EncounterInfoComponent } from '@mona/pdms/shared';
import { WithLogger } from '@mona/shared/logger';
import { isEmpty, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';

/**
 * Shell component
 */
@TakeUntilDestroy
@Component({
    template: `
        <router-outlet></router-outlet>
    `,
    styles: [
        `
            :host {
                flex: 1;
                display: flex;
                width: 100%;
                height: calc(100vh - 72px);
                overflow-y: hidden;
            }
        `,
    ],
})
@WithLogger({
    scope: 'PDMS',
})
export class PdmsShellComponent implements OnInit, OnDestroy {
    /**
     * Constructor
     *
     * @param featureFlags
     * @param apiHealthFacade
     * @param dataAccessWardsFacade
     * @param dataAccessEncountersFacade
     * @param fhirConfigFacade
     * @param pdmsUpdatesWsService
     * @param terminologyNewService
     * @param practitionersFacade
     * @param reportsFacade
     * @param ngxPermissionsService
     * @param authService
     * @param lockScreenPortalService
     */
    constructor(
        @Inject(FEATURE_FLAGS) private featureFlags: FeatureFlagsService,
        private apiHealthFacade: ApiHealthFacade,
        private dataAccessWardsFacade: DataAccessWardsFacade,
        private dataAccessEncountersFacade: DataAccessEncountersFacade,
        private fhirConfigFacade: DataAccessFhirConfigFacade,
        private pdmsUpdatesWsService: PdmsUpdatesWsService,
        private terminologyNewService: CombinedTerminologyService,
        private practitionersFacade: PractitionersFacade,
        private reportsFacade: ReportsFacade,
        private ngxPermissionsService: NgxPermissionsService,
        private authService: AuthService,
        private lockScreenPortalService: LockScreenPortalService,
    ) {}

    /**
     * Ng Hook
     */
    ngOnInit() {
        this.loadPdmsData();
        this.handlePDMSUpdatesFromSubscription();
        this.handleConnectionReestablished();
        this.handleDynamicPermissions();
        this.handleAssignedBed();
    }

    private handleAssignedBed() {
        this.dataAccessWardsFacade.assignedBedId$
            .pipe(
                concatLatestFrom(bedId => {
                    return bedId ? this.dataAccessWardsFacade.getBedWithWard(bedId) : of(null);
                }),
                tap(([bedId, assignedBed]) => {
                    if (bedId && assignedBed) {
                        const injector = Injector.create({
                            providers: [
                                {
                                    provide: ENCOUNTER_INFO,
                                    useValue: { bed: assignedBed, ward: assignedBed.ward },
                                },
                            ],
                        });
                        const componentPortal = new ComponentPortal(EncounterInfoComponent, null, injector);
                        this.lockScreenPortalService.setLockScreenLocation(componentPortal);
                    } else {
                        this.lockScreenPortalService.clearLockScreenLocation();
                    }
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /**
     * handle dynamic permissions
     */
    private handleDynamicPermissions() {
        this.authService.user$
            .pipe(
                skipWhile(user => isEmpty(user)),
                takeUntilDestroy(this),
            )
            .subscribe(() => {
                this.addDynamicPermissions();
            });
    }

    /**
     * add custom permissions
     */
    addDynamicPermissions(): void {
        this.ngxPermissionsService.addPermission(
            DynamicPdmsPermissionEnum.INPUT_OUTPUTS_ACCESS,
            (_, permissionsObject) => {
                return (
                    !!permissionsObject['prescription_medication_view'] && !!permissionsObject['inputs_outputs_view']
                );
            },
        );

        this.ngxPermissionsService.addPermission(
            DynamicPdmsPermissionEnum.CARE_PROCEDURES_ACCESS,
            (_, permissionsObject) => {
                return (
                    !!permissionsObject['prescription_medication_view'] && !!permissionsObject['care_procedures_view']
                );
            },
        );

        this.ngxPermissionsService.addPermission(DynamicPdmsPermissionEnum.TASK_LIST_ACCESS, (_, permissionsObject) => {
            return !!permissionsObject['prescription_medication_view'] && !!permissionsObject['care_procedures_view'];
        });
    }

    /**
     * Load PDMS data
     */
    private loadPdmsData(): void {
        this.fhirConfigFacade.loadFhirConfig();
        this.dataAccessWardsFacade.loadWards();
        this.dataAccessWardsFacade.loadBeds();
        this.practitionersFacade.loadPractitioners();

        // NOTE: postpone loading data not first necessity
        setTimeout(() => {
            this.reportsFacade.loadReports();
            this.reportsFacade.loadReportLocations();
            this.terminologyNewService.loadTerminologies();
        }, 1000);
    }

    /**
     * Handle encounters updates from subscription
     */
    private handlePDMSUpdatesFromSubscription() {
        this.pdmsUpdatesWsService
            .connect()
            .pipe(
                tap(message => {
                    switch (message.model) {
                        case 'Encounter':
                            switch (message.operation) {
                                case DataUpdateMessageOperation.Create:
                                case DataUpdateMessageOperation.Update:
                                    this.dataAccessEncountersFacade.insertOrUpdateEncounter(
                                        message.payload as Encounter,
                                    );
                                    break;
                                case DataUpdateMessageOperation.Delete:
                                    this.dataAccessEncountersFacade.removeEncounter(message.payload as Encounter);
                                    break;
                            }
                            break;
                        case 'Ward':
                            switch (message.operation) {
                                case DataUpdateMessageOperation.Create:
                                case DataUpdateMessageOperation.Update:
                                    this.dataAccessWardsFacade.insertOrUpdateWard(message.payload as Ward);
                                    break;
                                case DataUpdateMessageOperation.Delete:
                                    this.dataAccessWardsFacade.removeWard(message.payload as Ward);
                                    break;
                            }
                            break;
                        case 'Bed':
                            switch (message.operation) {
                                case DataUpdateMessageOperation.Create:
                                case DataUpdateMessageOperation.Update:
                                    this.dataAccessWardsFacade.insertOrUpdateBed(message.payload as Bed);
                                    break;
                                case DataUpdateMessageOperation.Delete:
                                    this.dataAccessWardsFacade.removeBed(message.payload as Bed);
                                    break;
                            }
                            break;
                    }
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }

    /** @ignore */
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy(): void {
        //
    }

    /**
     * Reconnects to WebSocket services
     * Had to reconnect after connection reestablished as after long offline status
     * existing ws reconnection logic does not work anymore
     */
    private handleConnectionReestablished(): void {
        this.apiHealthFacade.connectionReestablished$
            .pipe(
                tap(() => {
                    this.dataAccessWardsFacade.loadWards();
                    this.dataAccessWardsFacade.loadBeds();
                    this.dataAccessEncountersFacade.loadAllEncounters();
                    // Init subscription via websockets
                    this.handlePDMSUpdatesFromSubscription();
                }),
                takeUntilDestroy(this),
            )
            .subscribe();
    }
}
