// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Filled Template from database.
 * @see #/components/schemas/Reports - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Reports {
    /**
     * The Filled Report Id
     * @dataFormat uuid
     */
    readonly id: string;
    report_type: string;
    /**
     * UUID of Encounter
     * @dataFormat uuid
     */
    encounter_id: string;
    report: string;
    /**
     * `patient.admission` - Admission Report * `patient.transfer` - Transfer Report * `patient.discharge` - Discharge Report * `manual.full_report` - Full Report * `manual.basic_care` - Basic Care Report * `manual.workflow` - Workflow Report * `manual.entry_control` - Entry Control Report * `manual.input` - Input Report * `manual.lab_value` - Lab Value Report * `manual.medication_prescription` - Medication Prescription Report * `manual.output` - Output Report * `manual.patient_history` - Patient History Report * `manual.procedure` - Procedure Report * `manual.procedure_prescription` - Procedure Prescription Report * `manual.ventilation` - Ventilation Report * `manual.vital_sign` - Vital Sign Report * `manual.balance` - Balance Report * `disaster.full_report` - Disaster Full Report
     *
     */
    report_event: Reports.ReportEventEnum;
    /**
     * Patient Id.
     * @dataFormat uuid
     */
    patient_id?: string | null;
    /**
     * Patient first name.
     *
     */
    patient_name?: string | null;
    /**
     * Patient last name.
     *
     */
    patient_last_name?: string | null;
    report_data?: { [key: string]: any };
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Reports
 */
export namespace Reports {
    export type ReportEventEnum =
        | 'patient.admission'
        | 'patient.transfer'
        | 'patient.discharge'
        | 'manual.full_report'
        | 'manual.basic_care'
        | 'manual.workflow'
        | 'manual.entry_control'
        | 'manual.input'
        | 'manual.lab_value'
        | 'manual.medication_prescription'
        | 'manual.output'
        | 'manual.patient_history'
        | 'manual.procedure'
        | 'manual.procedure_prescription'
        | 'manual.ventilation'
        | 'manual.vital_sign'
        | 'manual.balance'
        | 'disaster.full_report';
    export const ReportEventEnum = {
        PatientAdmission: 'patient.admission' as ReportEventEnum,
        PatientTransfer: 'patient.transfer' as ReportEventEnum,
        PatientDischarge: 'patient.discharge' as ReportEventEnum,
        ManualFullReport: 'manual.full_report' as ReportEventEnum,
        ManualBasicCare: 'manual.basic_care' as ReportEventEnum,
        ManualWorkflow: 'manual.workflow' as ReportEventEnum,
        ManualEntryControl: 'manual.entry_control' as ReportEventEnum,
        ManualInput: 'manual.input' as ReportEventEnum,
        ManualLabValue: 'manual.lab_value' as ReportEventEnum,
        ManualMedicationPrescription: 'manual.medication_prescription' as ReportEventEnum,
        ManualOutput: 'manual.output' as ReportEventEnum,
        ManualPatientHistory: 'manual.patient_history' as ReportEventEnum,
        ManualProcedure: 'manual.procedure' as ReportEventEnum,
        ManualProcedurePrescription: 'manual.procedure_prescription' as ReportEventEnum,
        ManualVentilation: 'manual.ventilation' as ReportEventEnum,
        ManualVitalSign: 'manual.vital_sign' as ReportEventEnum,
        ManualBalance: 'manual.balance' as ReportEventEnum,
        DisasterFullReport: 'disaster.full_report' as ReportEventEnum,
    };

    /**
     * Reports Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Filled Report Id
         * @dataFormat uuid
         */
        readonly id: string;
        reportType: string;
        /**
         * UUID of Encounter
         * @dataFormat uuid
         */
        encounterId: string;
        report: string;
        /**
         * `patient.admission` - Admission Report * `patient.transfer` - Transfer Report * `patient.discharge` - Discharge Report * `manual.full_report` - Full Report * `manual.basic_care` - Basic Care Report * `manual.workflow` - Workflow Report * `manual.entry_control` - Entry Control Report * `manual.input` - Input Report * `manual.lab_value` - Lab Value Report * `manual.medication_prescription` - Medication Prescription Report * `manual.output` - Output Report * `manual.patient_history` - Patient History Report * `manual.procedure` - Procedure Report * `manual.procedure_prescription` - Procedure Prescription Report * `manual.ventilation` - Ventilation Report * `manual.vital_sign` - Vital Sign Report * `manual.balance` - Balance Report * `disaster.full_report` - Disaster Full Report
         * @type string (`dataFormat` is missing)
         */
        reportEvent: Reports.ReportEventEnum;
        /**
         * Patient Id.
         * @dataFormat uuid
         */
        patientId?: string | null;
        /**
         * Patient first name.
         * @type string (`dataFormat` is missing)
         */
        patientName?: string | null;
        /**
         * Patient last name.
         * @type string (`dataFormat` is missing)
         */
        patientLastName?: string | null;
        reportData?: { [key: string]: any };

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Reports DTO
     * Transforms Reports model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Reports model */
        static toModel(obj: Reports): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Reports model */
        static toApi(obj: Model): Reports {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Reports;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
