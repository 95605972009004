// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { MedicationCategoryConfig } from './medicationCategoryConfig';

/**
 * Serializes Medication Category from database.
 * @see #/components/schemas/MedicationCategory - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface MedicationCategory {
    /**
     * The medication category code(Id)
     *
     */
    code: string;
    /**
     * The display name of medication category
     *
     */
    display_name: string;
    /**
     * icon name for this medication category.
     *
     */
    icon?: string | null;
    readonly field_config: MedicationCategoryConfig | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for MedicationCategory
 */
export namespace MedicationCategory {
    /**
     * MedicationCategory Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The medication category code(Id)
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The display name of medication category
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * icon name for this medication category.
         * @type string (`dataFormat` is missing)
         */
        icon?: string | null;
        @Type(() => MedicationCategoryConfig.Model) readonly fieldConfig: MedicationCategoryConfig.Model | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * MedicationCategory DTO
     * Transforms MedicationCategory model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend MedicationCategory model */
        static toModel(obj: MedicationCategory): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend MedicationCategory model */
        static toApi(obj: Model): MedicationCategory {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as MedicationCategory;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
