import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Bed, Encounter, Patient, Ward } from '@mona/models';
import { isEmpty } from '@mona/shared/utils';
import { transformApiEncounter, transformApiEncounters } from './transforms';

/**
 * API abstraction layer for the encounters API
 */
@Injectable({
    providedIn: 'root',
})
export class EncountersApi {
    apiBase = '/pdms/encounters/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get encounters
     *
     * @param locationId EntityId<Ward>
     * @param locationType Location type have to be only bed or ward
     * @param active boolean
     * @param patientId EntityId<Patient>
     */
    getEncounters(
        locationId?: EntityId<Ward | Bed>,
        locationType?: 'bed' | 'ward',
        active?: boolean,
        patientId?: EntityId<Patient>,
    ): Observable<Encounter[]> {
        const params = HttpService.buildHttpParams({
            include_patient: true,
            location_id: locationId && locationType ? locationId : null,
            location_type: locationId && locationType ? locationType : null,
            active,
            patient_id: patientId,
        });
        return this.http
            .get<ApiModels.Encounter[]>(this.apiBase, {
                params,
            })
            .pipe(map(encounters => transformApiEncounters(encounters)));
    }

    /**
     * Get encounter
     *
     * @param id EntityId<Encounter>
     */
    getEncounterById(id: EntityId<Encounter>): Observable<Encounter> {
        const params = HttpService.buildHttpParams({
            include_patient: true,
            include_stays: true,
        });
        return this.http
            .get<ApiModels.Encounter>(`${this.apiBase}${id}/`, { params })
            .pipe(map(encounter => transformApiEncounter(encounter)));
    }

    /**
     * Relocate an encounter
     *
     * @param encounterId encounterId
     * @param bedId bedId
     */
    relocateEncounter(encounterId: EntityId<Encounter>, bedId: EntityId<Bed>): Observable<void> {
        return this.http.post<void>(`${this.apiBase}${encounterId}/relocate/`, {
            bed_id: bedId,
        });
    }

    /**
     * End an encounter
     *
     * @param encounterId EntityId<Encounter>
     * @param discharge_reason discharge_reason
     * @param custom_text custom_text
     */
    endEncounter(encounterId: EntityId<Encounter>, discharge_reason: string, custom_text?: string): Observable<void> {
        const body: ApiModels.DischargeRequest = {
            discharge_reason,
        };

        if (!isEmpty(custom_text)) {
            body['custom_text'] = custom_text;
        }

        return this.http.post<void>(`${this.apiBase}${encounterId}/discharge/`, body);
    }
}
