import { ApiModels } from '@mona/api';
import { AdditionalDeviceType } from '@mona/models';

/**
 * Transforms api additional device types
 *
 * @param apiAdditionalDeviceType
 */
export const transformApiAdditionalDeviceType = (
    apiAdditionalDeviceType: ApiModels.AdditionalDeviceType,
): AdditionalDeviceType => {
    const item: any = {
        id: apiAdditionalDeviceType.code,
        code: apiAdditionalDeviceType.code,
        displayName: apiAdditionalDeviceType.display_name,
        type: apiAdditionalDeviceType.type as ApiModels.AdditionalDeviceType.TypeEnum,
        unit: apiAdditionalDeviceType.unit,
        isAutomated: apiAdditionalDeviceType.is_automated,
        isVisible: apiAdditionalDeviceType.is_visible,
        isAlwaysVisible: apiAdditionalDeviceType.is_always_visible,
    };

    if (apiAdditionalDeviceType.components) {
        (item.components as any) = transformApiAdditionalDeviceTypes(
            apiAdditionalDeviceType.components as ApiModels.AdditionalDeviceType[],
        );
    }

    return item;
};

/**
 * Transform api additional device types
 *
 * @param apiAdditionalDeviceTypes
 */
export const transformApiAdditionalDeviceTypes = (
    apiAdditionalDeviceTypes: ApiModels.AdditionalDeviceType[],
): AdditionalDeviceType[] => {
    return apiAdditionalDeviceTypes.map(apiAdditionalDeviceType =>
        transformApiAdditionalDeviceType(apiAdditionalDeviceType),
    );
};
