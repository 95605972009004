import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { startOfDay } from 'date-fns';
import { interval, merge, ObservableInput } from 'rxjs';
import {
    delay,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { Encounter } from '@mona/models';
import { ChangeLogAction, ChangeLogSelectors } from '@mona/pdms/data-access-changelog';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { EncountersActions, selectSelectedEncounter } from '@mona/pdms/data-access-encounters';
import { handleEffectError, RouterActions } from '@mona/store';
import { PatientHistoriesApi } from '../../infrastructure';
import { EncounterAction, setEncounterSelectedDate } from '../actions';

/**
 * Encounter effects
 */
@Injectable()
export class EncounterEffects {
    /**
     * Navigate inside encounter module by path
     */
    navigateInsideSelectedEncounter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EncounterAction.navigateInsideSelectedEncounter),
            withCurrentEncounterId(),
            map(([{ slug, query }, currentEncounterId]) =>
                RouterActions.navigateAction({
                    path: ['pdms/encounter', currentEncounterId, ...slug.split('/')],
                    query,
                }),
            ),
        );
    });
    /**
     * Load encounter history effect
     */
    loadEncounterHistory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncounterAction.loadEncounterHistoryAction.action),
            switchMap(action => this.loadEncounterHistory(action)),
        ),
    );
    /** Listen change persist success & reload encounter to get the latest data */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectSelectedEncounter),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, encounter, changesMap]) => {
                    if (changesMap['Encounter'] || changesMap['Patient'] || changesMap['VitalSign']) {
                        this.store$.dispatch(EncountersActions.loadSingleEncounter({ encounterId: encounter.id }));
                    }
                }),
            ),
        { dispatch: false },
    );
    private startIntervalStream$ = merge(
        this.actions$.pipe(ofType('AUTH:LOGIN_SUCCESS'), withCurrentEncounterId()),
        this.actions$.pipe(
            ofType(EncountersActions.selectEncounter),
            map(({ encounterId }) => encounterId),
            distinctUntilChanged(),
            filter(encounterId => !!encounterId),
        ),
    );
    private stopIntervalStream$ = merge(
        this.actions$.pipe(ofType('AUTH:LOGOUT_SUCCESS')),
        this.actions$.pipe(
            ofType(EncountersActions.selectEncounter),
            map(({ encounterId }) => encounterId),
            distinctUntilChanged(),
            filter(encounterId => !encounterId),
        ),
    );
    /**
     * Watch date by tracking each hour and update encounter selected date
     * - start interval when encounter selected (navigated) or when user logs in (unlock)
     * - set date for today and start hour timer
     * - stop interval when or when encounter is deselected (navigated away) or user logs out (lock)
     */
    watchDateForEncounterSelectedDate$ = createEffect(
        () =>
            this.startIntervalStream$.pipe(
                tap(() => this.store$.dispatch(setEncounterSelectedDate({ selectedDate: startOfDay(new Date()) }))),
                switchMap(() => {
                    return interval(this.hoursMilliseconds).pipe(
                        startWith(() => new Date()),
                        delay(this.remainingMilliseconds - 1), // Delay until the next hour starts
                        map(() => startOfDay(new Date())),
                        takeUntil(this.stopIntervalStream$),
                    );
                }),
                tap((selectedDate: Date | null) => {
                    this.store$.dispatch(setEncounterSelectedDate({ selectedDate }));
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param store$
     * @param actions$ Actions
     * @param patientHistoriesApi PatientHistoriesApi
     */
    constructor(private store$: Store, private actions$: Actions, private patientHistoriesApi: PatientHistoriesApi) {}

    /**
     * milliseconds - as getter
     */
    get remainingMilliseconds(): number {
        return (60 - new Date().getMinutes()) * (60 - new Date().getSeconds()) * (1000 - new Date().getMilliseconds());
    }

    /**
     * hours in milliseconds - as getter
     */
    get hoursMilliseconds(): number {
        return 60 * 60 * 1000;
    }

    /**
     * Loads encounter history
     *
     * @param action action
     * @param action.id
     */
    loadEncounterHistory(action: { id: EntityId<Encounter> }): ObservableInput<Action> {
        return this.patientHistoriesApi.getPatientHistories(action.id).pipe(
            map(payload => EncounterAction.loadEncounterHistoryAction.succeededAction({ payload })),
            handleEffectError(EncounterAction.loadEncounterHistoryAction.failedAction),
        );
    }
}
