import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AdditionalDevice, PageableModel, VentilationParameter, VentilationProcedure } from '@mona/models';
import {
    applyInstancesChanges,
    ChangeLogAction,
    ChangeLogSelectors,
    ChangeLogState,
    getChangesData,
    getPersistedChangesData,
} from '@mona/pdms/data-access-changelog';
import { getCurrentEncounterProp, withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { Logger } from '@mona/shared/logger';
import { AdditionalDevicesApi, VentilationParametersApi, VentilationProceduresApi } from '../../infrastructure';
import { AdditionalDeviceAction } from '../actions';
import { selectAllAdditionalDevices } from '../selectors';

/**
 * Ventilation effects
 */
@Injectable()
export class AdditionalDevicesEffects {
    private logger = new Logger();
    /**
     * Load ventilation parameters effect
     */
    loadAdditionalDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdditionalDeviceAction.loadAdditionalDevices),
            withCurrentEncounterId(),
            switchMap(([{ params, url }, encounter_id]) =>
                this.additionalDevicesApi.getPageableAdditionalDevices({ ...params, encounter_id }, url).pipe(
                    catchError(error => {
                        this.logger.error('Error loading AdditionalDevices', error);
                        return of({ results: [], codes: [] } as PageableModel<AdditionalDevice> & {
                            codes: string[];
                        });
                    }),
                ),
            ),
            concatLatestFrom(() => this.store$.select(ChangeLogSelectors.getChangesMap)),
            map(([data, changesMap]) => {
                const changes = changesMap['AdditionalDevice'] || [];
                if (changes.length) {
                    const { results } = data;
                    data.results = applyInstancesChanges<AdditionalDevice>(results, changes, true);
                }
                return AdditionalDeviceAction.loadAdditionalDevicesSuccess(data);
            }),
            catchError(error => of(AdditionalDeviceAction.loadAdditionalDevicesFailed({ error }))),
        ),
    );

    /** Listen change persist success & add one - realistic update */
    onChangeSaved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    ChangeLogAction.saveChangeAction.succeededAction,
                    ChangeLogAction.saveChangesAction.succeededAction,
                ),
                withLatestFrom(
                    this.store$.select(selectAllAdditionalDevices),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, allAdditionalDevicesData, changesMap]) => {
                    const changes = changesMap['AdditionalDevice'] || [];
                    if (changes.length) {
                        const data = getChangesData(allAdditionalDevicesData, changes);
                        this.store$.dispatch(AdditionalDeviceAction.upsertAdditionalDevices({ data }));
                    }
                }),
            ),
        { dispatch: false },
    );

    /** Listen change persist success & add one - realistic update */
    onChangePersisted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChangeLogAction.persistChangesAction.succeededAction),
                withLatestFrom(
                    this.store$.select(selectAllAdditionalDevices),
                    this.store$.select(ChangeLogSelectors.getChangesMap),
                ),
                tap(([, data, changesMap]) => {
                    const changes = changesMap['AdditionalDevice'] || [];

                    if (changes.length) {
                        const { toRemove, toUpdate } = getPersistedChangesData(data, changes);

                        this.store$.dispatch(
                            AdditionalDeviceAction.addChanges({
                                toUpdateEntities: toUpdate || [],
                                toRemoveIds: toRemove.map(({ id }) => id) || [],
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param actions$ Actions
     * @param additionalDevicesApi AdditionalDevicesApi
     * @param store$
     */
    constructor(
        private actions$: Actions,
        private additionalDevicesApi: AdditionalDevicesApi,
        private store$: Store<{ changelogData: ChangeLogState }>,
    ) {}
}
