import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { marked } from 'marked';

/** MarkDown Preview Component */
@Component({
    selector: 'mona-markdown-preview',
    template: `
        <div [innerHTML]="markdown"></div>
    `,
    styles: [
        `
            ::ng-deep.markdown-image {
                max-width: 100%;
            }

            ::ng-deep.fake-link {
                pointer-events: none;
                color: inherit;
                text-decoration: underline;
            }
            :host ::ng-deep p {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        `,
    ],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownPreviewComponent {
    /** markdown content */
    markdown = '';

    /** input content which sets as markdown */
    @Input()
    set content(value: string) {
        const renderer = new marked.Renderer();
        renderer.image = ({ href, title, text }) => {
            return `
                <img
                    class="markdown-image"
                    src="${href}"
                    alt="${text || ''}"
                />
            `;
        };

        renderer.link = ({ title, text }) => {
            return `
                <a
                    href="javascript:void(0);"
                    class="fake-link"
                    title="${title}"
                >
                    ${text}
                </a>
            `;
        };

        this.markdown = marked(value, {
            async: false,
            renderer,
        });
    }
}
