import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mapTo, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { Bed, Encounter, Ward } from '@mona/models';
import { BedsActions, BedsSelectors, WardsActions, WardsSelectors } from '@mona/pdms/data-access-wards'; // eslint-disable-line
import { notEmpty, SYNC_STORAGE } from '@mona/shared/utils';
import { RouterActions, RouterSelectors } from '@mona/store';
import { MessageService } from '@mona/ui';
import { EncountersApi } from '../../infrastructure';
import * as EncountersActions from '../actions/encounters.actions';
import * as EncountersSelectors from '../selectors/encounters.selectors';

const ENCOUNTER_ERRORS = {
    // Happens when you try to relocate encounter to the bed which is already occupied
    RELOCATE_BED_OCCUPIED: 'encounter.bed_already_occupied',
    OPERATION_CANCELED: 'encounter.operation_canceled',
};

/**
 * Encounters effects
 */
@Injectable({ providedIn: 'root' })
export class EncountersEffects {
    /** Select assigned encounter ID */
    assignedEncounterId$: Observable<EntityId<Encounter>> = this.store.select(
        EncountersSelectors.selectAssignedEncounterId,
    );
    /**
     * Get full bed with ward object fron payload `bedId` and dispatch set adn load actions
     */
    initAssignedEncounter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BedsActions.setAssignedBedId),
            withLatestFrom(this.store.select(EncountersSelectors.selectAllEncountersGroupedByBed).pipe(notEmpty())),
            mergeMap(([{ bedId }, encounters]) => {
                const encounter = encounters[bedId];
                return encounter
                    ? [
                          EncountersActions.upsertEncounter({ encounter }),
                          EncountersActions.setAssignedEncounter({ encounterId: encounter.id }),
                      ]
                    : [EncountersActions.setAssignedEncounter({ encounterId: null })];
            }),
            catchError(error => of(EncountersActions.loadSingleEncounterFailed({ error }))),
        );
    });
    /**
     * Store assigned encounter id in browser's storage
     *
     * Was added as quick'n'dirty solution after decoupling layout & navbar from pdms
     * Reload wards url to trigger navbar change
     */
    setAssignedEncounter$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EncountersActions.setAssignedEncounter),
                withLatestFrom(this.store.select(RouterSelectors.selectUrl)),
                tap(([{ encounterId }, url]) => {
                    this.storageService.setItem('pdms.assignedEncounterId', encounterId);

                    if (url.includes('pdms/wards')) {
                        // FIXME: see description
                        this.store.dispatch(
                            RouterActions.navigateAction({
                                path: ['pdms/wards'],
                                extras: {
                                    state: {
                                        skipDiscardDialog: true,
                                    },
                                    skipLocationChange: true,
                                },
                            }),
                        );
                    }
                }),
            ),
        { dispatch: false },
    );
    /**
     * Load encounter by id effect
     */
    selectEncounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.selectEncounter),
            filter(({ encounterId }) => !!encounterId),
            switchMap(({ encounterId }) => this.encountersApi.getEncounterById(encounterId)),
            map(encounter => EncountersActions.loadSingleEncounterSuccess({ encounter })),
            catchError(error => of(EncountersActions.loadSingleEncounterFailed({ error }))),
        ),
    );
    /**
     * Load current encounter effect
     */
    loadSingleEncounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.loadSingleEncounter),
            filter(({ encounterId }) => !!encounterId),
            switchMap(({ encounterId }) => this.encountersApi.getEncounterById(encounterId)),
            map(encounter => EncountersActions.loadSingleEncounterSuccess({ encounter })),
            catchError(error => of(EncountersActions.loadSingleEncounterFailed({ error }))),
        ),
    );
    /**
     * Load current encounter success effect
     */
    loadSingleEncounterSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.loadSingleEncounterSuccess),
            switchMap(({ encounter }) => {
                return [EncountersActions.setCurrentEncounter({ encounter })];
            }),
        ),
    );
    loadAllEncounters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.loadEncounters),
            concatMap(({ wardId, active }) => this.encountersApi.getEncounters(wardId, wardId ? 'ward' : null, active)),
            map(encounters => EncountersActions.loadEncountersSuccess({ encounters })),
            catchError(error => of(EncountersActions.loadEncountersFailed({ error }))),
        ),
    );
    /**
     * Relocate encounters effect
     *
     * if `shouldIncludeEncountersReloading` - Reload ward encounter as part of relocate action when relocating from patient list screen
     * else - Do not reload ward encounter as part of relocate action when relocating from encounter screen
     */
    relocateEncounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.relocateEncounter),
            switchMap(action => this.authService.authenticate().pipe(map(({ user }) => ({ ...action, user })))),
            switchMap(action => {
                if (action.user) {
                    return this.encountersApi.relocateEncounter(action.encounter.id, action.bedId).pipe(mapTo(action));
                } else {
                    // INFO: relocate can be "canceled" in case no rfid identification was provided
                    // so we need to dispatch CLEAR action here & return EMPTY obs to unblock actions stream
                    this.store.dispatch(EncountersActions.relocateEncounterClear());
                    return EMPTY;
                }
            }),
            mergeMap(({ encounter, shouldIncludeEncountersReloading, wardId, bedId }) => {
                return [
                    EncountersActions.relocateEncounterSuccess({ encounterId: encounter.id, wardId, bedId }),
                    WardsActions.selectWard({ wardId }),
                    BedsActions.selectBed({ bedId }),
                ];
            }),
            catchError(error => {
                switch (error.errorCode) {
                    case ENCOUNTER_ERRORS.OPERATION_CANCELED:
                        return of(EncountersActions.relocateEncounterClear());
                    case ENCOUNTER_ERRORS.RELOCATE_BED_OCCUPIED:
                    default:
                        this.messageService.errorToast('errors.encounter.relocateBedOccupied');
                        return of(EncountersActions.relocateEncounterFailed({ error }));
                }
            }),
        ),
    );
    /**
     * End encounter effect
     */
    endEncounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.endEncounter),
            switchMap(action =>
                this.authService.authenticate().pipe(
                    map(({ user }) => ({ ...action, user })),
                    filter(action => !!action.user),
                ),
            ),
            switchMap(({ encounterId, user, discharge_reason, custom_text }) => {
                return this.encountersApi.endEncounter(encounterId, discharge_reason, custom_text).pipe(
                    map(
                        () => ({
                            encounterId,
                            error: null,
                        }),
                        catchError(error => {
                            return of({ encounterId, error });
                        }),
                    ),
                );
            }),
            map(({ encounterId, error }) => {
                if (encounterId && !error) {
                    const assignedEncounterId = this.storageService.getItem('pdms.assignedEncounterId');
                    if (encounterId === assignedEncounterId) {
                        this.store.dispatch(EncountersActions.setAssignedEncounter({ encounterId: null }));
                    }

                    return EncountersActions.endEncounterSuccess({ encounterId });
                } else {
                    return EncountersActions.endEncounterFailed({ error });
                }
            }),
        ),
    );
    /**
     * Selector for assigned bed id
     */
    private assignedBedId$: Observable<EntityId<Bed>> = this.store.select(BedsSelectors.selectAssignedBedId);
    /**
     * Set assigned encounter after relocate encounter succeeded
     *
     */
    relocateEncounterSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EncountersActions.relocateEncounterSuccess),
                concatLatestFrom(() => [this.assignedBedId$, this.assignedEncounterId$]),
                tap(([{ encounterId, bedId }, assignedBedId, assignedEncounterId]) => {
                    this.messageService.infoToast('apps.settings.messages.saveSuccess');
                    if (assignedBedId) {
                        if (assignedBedId === bedId) {
                            this.store.dispatch(EncountersActions.setAssignedEncounter({ encounterId }));
                        }
                        if (encounterId === assignedEncounterId) {
                            this.store.dispatch(EncountersActions.setAssignedEncounter({ encounterId: null }));
                        }
                    }
                }),
            ),
        { dispatch: false },
    );
    /**
     * Selector for assigned ward id
     */
    private assignedWardId$: Observable<EntityId<Ward>> = this.store.select(WardsSelectors.selectAssignedWardId);
    /**
     * End and relocate encounter succeeded effect
     *
     */
    reloadSingleEncounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EncountersActions.endEncounterSuccess),
            concatLatestFrom(() => [this.assignedBedId$, this.assignedWardId$]),
            tap(() => this.messageService.infoToast('apps.settings.messages.saveSuccess')),
            map(() =>
                RouterActions.navigateAction({
                    path: ['pdms/wards'],
                    extras: {
                        state: {
                            skipDiscardDialog: true,
                        },
                    },
                }),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param storageService
     * @param store
     * @param actions$ Actions
     * @param encountersApi encountersApi
     * @param messageService
     * @param authService
     */
    constructor(
        @Inject(SYNC_STORAGE) private storageService: Storage,
        private store: Store<any>,
        private actions$: Actions,
        private encountersApi: EncountersApi,
        private messageService: MessageService,
        private authService: AuthService,
    ) {}
}
