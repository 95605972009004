import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportsState } from '../../entities';
import { REPORTS_REDUCERS_TOKEN } from '../reducers';
import { reportLocationsAdapter } from '../reducers/locations.reducer';
import { reportTypesAdapter } from '../reducers/report.reducer';

const reportTypesSelectors = reportTypesAdapter.getSelectors();
const reportLocationsSelectors = reportLocationsAdapter.getSelectors();

const selectReportsFeature = createFeatureSelector<ReportsState>(REPORTS_REDUCERS_TOKEN);

// report types
const selectReportTypesState = createSelector(selectReportsFeature, s => s.reportTypes);
export const selectReportTypes = createSelector(selectReportTypesState, reportTypesSelectors.selectAll);
export const selectReportTypesIsLoading = createSelector(selectReportsFeature, s => s.reportTypes.isLoading);

// same for locations
const selectLocationsState = createSelector(selectReportsFeature, s => s.locations);
export const selectLocations = createSelector(selectLocationsState, reportLocationsSelectors.selectAll);
export const selectLocationsIsLoading = createSelector(selectReportsFeature, s => s.locations.isLoading);
