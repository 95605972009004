import { ActionReducerMap } from '@ngrx/store';
import { makeAsyncActionReducer, makeEntitiesReducer } from '@mona/store';
import { NotificationsState } from '../../entities';
import { NotificationsAction } from '../actions/notifications.actions';

export const notificationsFeatureKey = 'notifications';

/**
 * Notifications reducer map
 */
export const reducer: ActionReducerMap<NotificationsState> = {
    notifications: makeEntitiesReducer(NotificationsAction.loadNotificationsAction, false, [
        NotificationsAction.addUpdateNotificationAction,
        NotificationsAction.changeReadStatusAction.succeededAction,
    ]),
    resolveNotificationAction: makeAsyncActionReducer(NotificationsAction.resolveNotificationAction),
} as any;
