// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Vital Sign Type Components from database.
 * @see #/components/schemas/VentilationComponent - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface VentilationComponent {
    /**
     * The code of the ventilation parameter
     *
     */
    code: string;
    /**
     * The display name of the ventilation parameter
     *
     */
    display_name: string;
    /**
     * The type of the ventilation parameter  * `integer` - Ganzzahl * `decimal` - Decimal * `text` - Text * `boolean` - Boolean
     *
     */
    type: VentilationComponent.TypeEnum;
    /**
     * The unit of the ventilation parameter
     *
     */
    unit?: string | null;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for VentilationComponent
 */
export namespace VentilationComponent {
    export type TypeEnum = 'integer' | 'decimal' | 'text' | 'boolean';
    export const TypeEnum = {
        Integer: 'integer' as TypeEnum,
        Decimal: 'decimal' as TypeEnum,
        Text: 'text' as TypeEnum,
        Boolean: 'boolean' as TypeEnum,
    };

    /**
     * VentilationComponent Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The code of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        code: string;
        /**
         * The display name of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        displayName: string;
        /**
         * The type of the ventilation parameter  * `integer` - Ganzzahl * `decimal` - Decimal * `text` - Text * `boolean` - Boolean
         * @type string (`dataFormat` is missing)
         */
        type: VentilationComponent.TypeEnum;
        /**
         * The unit of the ventilation parameter
         * @type string (`dataFormat` is missing)
         */
        unit?: string | null;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * VentilationComponent DTO
     * Transforms VentilationComponent model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend VentilationComponent model */
        static toModel(obj: VentilationComponent): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend VentilationComponent model */
        static toApi(obj: Model): VentilationComponent {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as VentilationComponent;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
