// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializes Daily Goals from database.
 * @see #/components/schemas/TerminologyDailyGoal - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface TerminologyDailyGoal {
    readonly id: string;
    /**
     * The text for the daily goal.
     *
     */
    text?: string | null;
    is_default?: boolean;
    show_textbox?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for TerminologyDailyGoal
 */
export namespace TerminologyDailyGoal {
    /**
     * TerminologyDailyGoal Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        readonly id: string;
        /**
         * The text for the daily goal.
         * @type string (`dataFormat` is missing)
         */
        text?: string | null;
        isDefault?: boolean;
        showTextbox?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * TerminologyDailyGoal DTO
     * Transforms TerminologyDailyGoal model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend TerminologyDailyGoal model */
        static toModel(obj: TerminologyDailyGoal): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend TerminologyDailyGoal model */
        static toApi(obj: Model): TerminologyDailyGoal {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as TerminologyDailyGoal;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
