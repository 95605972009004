import { Component, NgModule } from '@angular/core';
import { UtilsModule } from '@mona/shared/utils';

/**
 * Root user settings component
 */
@Component({
    selector: 'ui-user-settings-layout',
    templateUrl: './user-settings-layout.component.html',
})
export class UserSettingsLayoutComponent {}

/**
 * INFO: add comment
 */
@NgModule({
    declarations: [UserSettingsLayoutComponent],
    imports: [UtilsModule],
    exports: [UserSettingsLayoutComponent],
})
export class UiUserSettingsLayoutModule {}
