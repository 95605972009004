// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for WorkflowType Model Structure.
 * @see #/components/schemas/WorkflowType - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowType {
    /**
     * The workflow type ID.
     * @dataFormat uuid
     */
    readonly id: string;
    /**
     * The type of the workflow type.
     *
     */
    workflow_type: string;
    /**
     * The questionnaire id.
     *
     */
    questionnaire_id: string;
    readonly url: string;
    /**
     * The visibility for current values column.
     *
     */
    show_current_values?: boolean;
    /**
     * Determines if scores are attached to the answers
     *
     */
    show_scores?: boolean;
    /**
     * The visibility for the workflow type.
     *
     */
    is_visible?: boolean;
    /**
     * Determines if created manually
     *
     */
    is_user_created?: boolean;
    readonly editable: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowType
 */
export namespace WorkflowType {
    /**
     * WorkflowType Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The workflow type ID.
         * @dataFormat uuid
         */
        readonly id: string;
        /**
         * The type of the workflow type.
         * @type string (`dataFormat` is missing)
         */
        workflowType: string;
        /**
         * The questionnaire id.
         * @type string (`dataFormat` is missing)
         */
        questionnaireId: string;
        readonly url: string;
        /**
         * The visibility for current values column.
         * @type boolean (`dataFormat` is missing)
         */
        showCurrentValues?: boolean;
        /**
         * Determines if scores are attached to the answers
         * @type boolean (`dataFormat` is missing)
         */
        showScores?: boolean;
        /**
         * The visibility for the workflow type.
         * @type boolean (`dataFormat` is missing)
         */
        isVisible?: boolean;
        /**
         * Determines if created manually
         * @type boolean (`dataFormat` is missing)
         */
        isUserCreated?: boolean;
        readonly editable: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowType DTO
     * Transforms WorkflowType model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowType model */
        static toModel(obj: WorkflowType): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowType model */
        static toApi(obj: Model): WorkflowType {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowType;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
