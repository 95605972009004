// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { GlasgowComaScale } from './glasgowComaScale';
import { CareCheck } from './careCheck';

/**
 * Serializer for validating EntryControl model structure.
 * @see #/components/schemas/EntryControl - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface EntryControl {
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The date the entry control belongs to.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The shift the entry control belongs to.  * `morning` - MORNING * `midday` - MIDDAY * `evening` - EVENING
     *
     */
    shift: EntryControl.ShiftEnum;
    /**
     * The id of the practitioner that was responsible in the current shift.
     * @dataFormat uuid
     */
    responsible_nurse_id: string;
    /**
     * The report of the responsible nurse at the end of the shift.
     *
     */
    care_report?: string | null;
    /**
     * If the whole entry-control is approved by the author.
     *
     */
    is_approved: boolean;
    /**
     * Check of monitor-alarm.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
     *
     */
    monitor_alarm: EntryControl.MonitorAlarmEnum;
    /**
     * Check of orders.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
     *
     */
    orders: EntryControl.OrdersEnum;
    /**
     * Check of ventilation-alarm.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
     *
     */
    ventilation_alarm: EntryControl.VentilationAlarmEnum;
    /**
     * Check of zero-point-alignment.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
     *
     */
    zero_point_alignment: EntryControl.ZeroPointAlignmentEnum;
    /**
     * Check of running-rate-control.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
     *
     */
    running_rate_control: EntryControl.RunningRateControlEnum;
    /**
     * Check of cuff water level
     *
     */
    cuff_cm_h2o?: number | null;
    /**
     * Prophylaxis of risk of thrombosis.
     *
     */
    risk_of_thrombosis?: boolean | null;
    /**
     * Prophylaxis of pain risk.
     *
     */
    risk_of_pain?: boolean | null;
    /**
     * Prophylaxis of risk of falling.
     *
     */
    risk_of_falling?: boolean | null;
    /**
     * Prophylaxis of risk of contractures.
     *
     */
    risk_of_contractures?: boolean | null;
    /**
     * Prophylaxis of risk of decubitus.
     *
     */
    risk_of_decubitus?: boolean | null;
    /**
     * Prophylaxis of risk of pneumonia.
     *
     */
    risk_of_pneumonia?: boolean | null;
    /**
     * An array of care-checks.
     *
     */
    care_checks: Array<CareCheck>;
    /**
     * An array of Glasgow Comma Scales.
     *
     */
    glasgow_coma_scale: Array<GlasgowComaScale>;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string | null;
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * Timestamp when this entity was last modified.
     * @dataFormat date-time
     */
    last_changed_at: string;
    /**
     * Entry-Control unique id.
     * @dataFormat uuid
     */
    id: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for EntryControl
 */
export namespace EntryControl {
    export type ShiftEnum = 'morning' | 'midday' | 'evening';
    export const ShiftEnum = {
        Morning: 'morning' as ShiftEnum,
        Midday: 'midday' as ShiftEnum,
        Evening: 'evening' as ShiftEnum,
    };
    export type MonitorAlarmEnum = 'ok' | 'not-ok' | 'not-applicable';
    export const MonitorAlarmEnum = {
        Ok: 'ok' as MonitorAlarmEnum,
        NotOk: 'not-ok' as MonitorAlarmEnum,
        NotApplicable: 'not-applicable' as MonitorAlarmEnum,
    };
    export type OrdersEnum = 'ok' | 'not-ok' | 'not-applicable';
    export const OrdersEnum = {
        Ok: 'ok' as OrdersEnum,
        NotOk: 'not-ok' as OrdersEnum,
        NotApplicable: 'not-applicable' as OrdersEnum,
    };
    export type VentilationAlarmEnum = 'ok' | 'not-ok' | 'not-applicable';
    export const VentilationAlarmEnum = {
        Ok: 'ok' as VentilationAlarmEnum,
        NotOk: 'not-ok' as VentilationAlarmEnum,
        NotApplicable: 'not-applicable' as VentilationAlarmEnum,
    };
    export type ZeroPointAlignmentEnum = 'ok' | 'not-ok' | 'not-applicable';
    export const ZeroPointAlignmentEnum = {
        Ok: 'ok' as ZeroPointAlignmentEnum,
        NotOk: 'not-ok' as ZeroPointAlignmentEnum,
        NotApplicable: 'not-applicable' as ZeroPointAlignmentEnum,
    };
    export type RunningRateControlEnum = 'ok' | 'not-ok' | 'not-applicable';
    export const RunningRateControlEnum = {
        Ok: 'ok' as RunningRateControlEnum,
        NotOk: 'not-ok' as RunningRateControlEnum,
        NotApplicable: 'not-applicable' as RunningRateControlEnum,
    };

    /**
     * EntryControl Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The date the entry control belongs to.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The shift the entry control belongs to.  * `morning` - MORNING * `midday` - MIDDAY * `evening` - EVENING
         * @type string (`dataFormat` is missing)
         */
        shift: EntryControl.ShiftEnum;
        /**
         * The id of the practitioner that was responsible in the current shift.
         * @dataFormat uuid
         */
        responsibleNurseId: string;
        /**
         * The report of the responsible nurse at the end of the shift.
         * @type string (`dataFormat` is missing)
         */
        careReport?: string | null;
        /**
         * If the whole entry-control is approved by the author.
         * @type boolean (`dataFormat` is missing)
         */
        isApproved: boolean;
        /**
         * Check of monitor-alarm.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
         * @type string (`dataFormat` is missing)
         */
        monitorAlarm: EntryControl.MonitorAlarmEnum;
        /**
         * Check of orders.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
         * @type string (`dataFormat` is missing)
         */
        orders: EntryControl.OrdersEnum;
        /**
         * Check of ventilation-alarm.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
         * @type string (`dataFormat` is missing)
         */
        ventilationAlarm: EntryControl.VentilationAlarmEnum;
        /**
         * Check of zero-point-alignment.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
         * @type string (`dataFormat` is missing)
         */
        zeroPointAlignment: EntryControl.ZeroPointAlignmentEnum;
        /**
         * Check of running-rate-control.  * `ok` - OK * `not-ok` - NOT_OK * `not-applicable` - N_A
         * @type string (`dataFormat` is missing)
         */
        runningRateControl: EntryControl.RunningRateControlEnum;
        /**
         * Check of cuff water level
         * @type number (`dataFormat` is missing)
         */
        cuffCmH2o?: number | null;
        /**
         * Prophylaxis of risk of thrombosis.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfThrombosis?: boolean | null;
        /**
         * Prophylaxis of pain risk.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfPain?: boolean | null;
        /**
         * Prophylaxis of risk of falling.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfFalling?: boolean | null;
        /**
         * Prophylaxis of risk of contractures.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfContractures?: boolean | null;
        /**
         * Prophylaxis of risk of decubitus.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfDecubitus?: boolean | null;
        /**
         * Prophylaxis of risk of pneumonia.
         * @type boolean (`dataFormat` is missing)
         */
        riskOfPneumonia?: boolean | null;
        /**
         * An array of care-checks.
         * @type Array<CareCheck> (`dataFormat` is missing)
         */
        @Type(() => CareCheck.Model) careChecks: Array<CareCheck.Model>;
        /**
         * An array of Glasgow Comma Scales.
         * @type Array<GlasgowComaScale> (`dataFormat` is missing)
         */
        @Type(() => GlasgowComaScale.Model) glasgowComaScale: Array<GlasgowComaScale.Model>;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string | null;
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * Timestamp when this entity was last modified.
         * @dataFormat date-time
         */
        @TransformDate() lastChangedAt: Date;
        /**
         * Entry-Control unique id.
         * @dataFormat uuid
         */
        id: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * EntryControl DTO
     * Transforms EntryControl model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend EntryControl model */
        static toModel(obj: EntryControl): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend EntryControl model */
        static toApi(obj: Model): EntryControl {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as EntryControl;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
