/* eslint-disable jsdoc/require-jsdoc */
import { createAction, props } from '@ngrx/store';
import { ReportLocation, ReportType } from '@mona/models';

export const loadReportTypes = createAction('REPORTS:LOAD_AVAILABLE_REPORT_TYPES');

export const loadReportTypesSuccess = createAction(
    'REPORTS:LOAD_AVAILABLE_REPORT_TYPES_SUCCEEDED',
    props<{ reportTypes: ReportType[] }>(),
);

export const loadReportTypesFailure = createAction(
    'REPORTS:LOAD_AVAILABLE_REPORT_TYPES_FAILED',
    props<{ error?: any }>(),
);

export const clearReportTypes = createAction('REPORTS:LOAD_AVAILABLE_REPORT_TYPES_CLEAR');

export const exportReport = createAction(
    'REPORTS:EXPORT_FULL_REPORT',
    props<{ reportType: string; reportLocation: string }>(),
);
export const exportReportSuccess = createAction('REPORTS:EXPORT_FULL_REPORT_SUCCEEDED');
export const exportReportFailed = createAction('REPORTS:EXPORT_FULL_REPORT_FAILED', props<{ error?: any }>());

export const loadReportsExportLocations = createAction('REPORTS:LOAD_REPORTS_EXPORT_LOCATIONS');

export const loadReportsExportLocationsSuccess = createAction(
    'REPORTS:LOAD_REPORTS_EXPORT_LOCATIONS_SUCCEEDED',
    props<{ locations: ReportLocation[] }>(),
);

export const loadReportsExportLocationsFailure = createAction(
    'REPORTS:LOAD_REPORTS_EXPORT_LOCATIONS_FAILED',
    props<{ error?: any }>(),
);

//clear
export const clearLocations = createAction('REPORTS:LOAD_REPORTS_EXPORT_LOCATIONS_CLEAR');
