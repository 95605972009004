/* eslint-disable no-prototype-builtins */
import { format } from 'date-fns';
import { SetOptional } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';
import { ApiModels } from '@mona/api';
import {
    Allergy,
    AdditionalDevice,
    Anamnesis,
    BalanceTarget,
    BasicCareProcedure,
    ChangeLogEntry,
    ChangeLogModel,
    ChangeLogModelKey,
    DailyGoal,
    Diagnosis,
    Encounter,
    EncounterHistoryEntry,
    EntryControl,
    InfectionStatus,
    LabValue,
    MedicationPrescription,
    Patient,
    PatientOutput,
    PhysicalExamination,
    PreMedication,
    Procedure,
    ProcedurePrescription,
    SurgeryPrescription,
    TherapyLimitations,
    VaccinationStatus,
    Valuables,
    VentilationParameter,
    VentilationProcedure,
    VitalSign,
    Workflow,
    WoundStatus,
} from '@mona/models';
import { compactObject, isEmpty, isFunction } from '@mona/shared/utils';

/**
 * Transforms patient to API model
 *
 * @param patient Partial<Patient>
 */
export const transformToPartialApiPatient = (patient: Partial<Patient>): Partial<ApiModels.Patient> => {
    const model = ApiModels.Patient.DTO.toApi(patient as any);
    model.date_of_birth = format(new Date(model.date_of_birth), 'yyyy-MM-dd');
    return compactObject(model);
};

/**
 * Transforms to partial api additional device
 *
 * @param additionalDevice Partial<AdditionalDevice>
 */
export const transformToPartialApiAdditionalDevice = (
    additionalDevice: Partial<AdditionalDevice>,
): Partial<ApiModels.AdditionalDevice> => {
    const model = ApiModels.Patient.DTO.toApi(additionalDevice as any);
    return compactObject(model);
};

/**
 * Transforms to partial api anamnesis
 *
 * @param anamnesis Partial<Anamnesis>
 */
export const transformToPartialApiAnamnesis = (anamnesis: Partial<Anamnesis>): Partial<ApiModels.Anamnesis> => {
    const model = ApiModels.Patient.DTO.toApi(anamnesis as any);
    return compactObject(model);
};

/**
 * Transforms to api partial vital sign
 *
 * @param vitalSign Partial<VitalSign>
 */
export const transformToPartialApiVitalSign = (vitalSign: Partial<VitalSign>): Partial<ApiModels.VitalSign> => {
    const model = ApiModels.VitalSign.DTO.toApi(vitalSign as any);
    return compactObject(model);
};

/**
 * Transforms to api partial allergy
 *
 * @param allergy Partial<Allergy>
 */
export const transformToPartialApiAllergy = (allergy: Partial<Allergy>): Partial<ApiModels.AllergyIntolerance> => {
    const model = ApiModels.AllergyIntolerance.DTO.toApi(allergy as any);
    return compactObject(model);
};

/**
 * Transforms to api partial vaccination
 *
 * @param vaccination Partial<VaccinationStatus>
 */
export const transformToPartialApiVaccinations = (
    vaccination: Partial<VaccinationStatus>,
): Partial<ApiModels.VaccinationStatus> => {
    const model = ApiModels.VaccinationStatus.DTO.toApi(vaccination as any);
    const compactedObject = compactObject(model);
    if (!compactedObject?.verification_manufacturer) {
        compactedObject.verification_manufacturer = '';
    }
    return compactedObject;
};

/**
 * Transforms to api partial infection
 *
 * @param infection Partial<Infection>
 */
export const transformToPartialApiInfection = (
    infection: Partial<InfectionStatus>,
): Partial<ApiModels.InfectionStatus> => {
    const model = ApiModels.InfectionStatus.DTO.toApi(infection as any);
    const compactedObject = compactObject(model);
    if (!compactedObject?.infection_annotation) {
        compactedObject.infection_annotation = '';
    }
    return compactedObject;
};

/**
 * Transforms to api partial valuable
 *
 * @param valuable Partial<Valuables>
 */
export const transformToPartialApiValuable = (valuable: Partial<Valuables>): Partial<ApiModels.Valuables> => {
    const model = ApiModels.Valuables.DTO.toApi(valuable as any);
    return compactObject(model);
};

/**
 * Transforms to api partial wound status
 *
 * @param wound Partial<WoundStatus>
 */
export const transformToPartialApiWound = (wound: Partial<WoundStatus>): Partial<ApiModels.WoundStatus> => {
    const model = ApiModels.WoundStatus.DTO.toApi(wound as any);
    return compactObject(model);
};

/**
 * Transforms to api partial surgery
 *
 * @param surgery Partial<SurgeryPrescription>
 */
export const transformToPartialApiSurgeryPrescription = (
    surgery: Partial<SurgeryPrescription>,
): Partial<ApiModels.SurgeryPrescription> => {
    const model = ApiModels.SurgeryPrescription.DTO.toApi(surgery as any);
    return compactObject(model);
};

/**
 * Transforms to api partial therapy limitation
 *
 * @param therapyLimitations Partial<TherapyLimitations>
 */
export const transformToPartialApiTherapyLimitations = (
    therapyLimitations: Partial<TherapyLimitations>,
): Partial<ApiModels.TherapyLimitations> => {
    const model = ApiModels.TherapyLimitations.DTO.toApi(therapyLimitations as any);
    return compactObject(model);
};

/**
 * Transforms to api partial diagnosis
 *
 * @param diagnosis Partial<Diagnosis>
 */
export const transformToPartialApiDiagnosis = (diagnosis: Partial<Diagnosis>): Partial<ApiModels.Diagnosis> => {
    const model = ApiModels.Diagnosis.DTO.toApi(diagnosis as any);

    if (!model.icd_10_code) {
        model.icd_10_code = model['icd10_code'];
        delete model['icd10_code'];
    }

    return compactObject(model);
};

/**
 * Transforms to api partial pre medication
 *
 * @param preMedication Partial<PreMedication>
 */
export const transformToPartialApiPreMedication = (
    preMedication: Partial<PreMedication>,
): Partial<ApiModels.Premedication> => {
    const model = ApiModels.Premedication.DTO.toApi(preMedication as any);
    return compactObject(model);
};

/**
 * Transforms to api partial physical examination
 *
 * @param physicalExamination Partial<PhysicalExamination>
 */
export const transformToPartialApiPhysicalExamination = (
    physicalExamination: Partial<PhysicalExamination>,
): Partial<ApiModels.PhysicalExamination> => {
    const model = ApiModels.PhysicalExamination.DTO.toApi(physicalExamination as any);
    return compactObject(model);
};

/**
 * Transforms to api partial encounter history entry
 *
 * @param historyEntry EncounterHistoryEntry
 */
export const transformToPartialApiHistory = (
    historyEntry: Partial<EncounterHistoryEntry>,
): Partial<ApiModels.PatientHistory> => {
    const model = ApiModels.PatientHistory.DTO.toApi(historyEntry as any);
    if (historyEntry.text) {
        model.value = historyEntry.text;
        delete (model as any).text;
    }
    return compactObject(model);
};

/**
 * Transforms to partial api encounter
 *
 * @param encounter Partial<Encounter>
 */
export const transformToPartialApiEncounter = (encounter: Partial<Encounter>): Partial<ApiModels.Encounter> => {
    const model = ApiModels.Encounter.DTO.toApi(encounter as any);
    return compactObject(model);
};

/**
 * Transforms to api lab values
 *
 * @param labValue Partial<LabValue>
 */
export const transformToPartialApiLabValue = (labValue: Partial<LabValue>): Partial<ApiModels.LabValue> => {
    const model = ApiModels.LabValue.DTO.toApi(labValue as any);
    return compactObject(model);
};

/**
 * Transforms to api partial ventilation parameter
 *
 * @param ventilationParameter Partial<VentilationParameter>
 */
export const transformToPartialApiVentilationParameter = (
    ventilationParameter: Partial<VentilationParameter>,
): Partial<ApiModels.PdmsVentilationParameter> => {
    const model = ApiModels.PdmsVentilationParameter.DTO.toApi(ventilationParameter as any);
    return compactObject(model);
};

/**
 * Transforms to api partial ventilation procedure
 *
 * @param ventilationProcedure Partial<VentilationProcedure>
 */
export const transformToPartialApiVentilationProcedure = (
    ventilationProcedure: Partial<VentilationProcedure>,
): Partial<ApiModels.VentilationProcedure> => {
    const model = ApiModels.VentilationProcedure.DTO.toApi(ventilationProcedure as any);
    return compactObject(model);
};

/**
 * Transforms to partial medication prescription
 *
 * @param medicationPrescription Partial<MedicationPrescription>
 */
export const transformToPartialApiMedicationPrescription = (
    medicationPrescription: Partial<MedicationPrescription>,
): Partial<ApiModels.MedicationPrescription> => {
    const model = ApiModels.MedicationPrescription.DTO.toApi(medicationPrescription as any);
    return compactObject(model);
};

/**
 * Transforms to partial procedure prescription
 *
 * @param procedurePrescription Partial<ProcedurePrescription>
 */
export const transformToPartialApiProcedurePrescription = (
    procedurePrescription: Partial<ProcedurePrescription>,
): Partial<ApiModels.ProcedurePrescription> => {
    const model = ApiModels.ProcedurePrescription.DTO.toApi(procedurePrescription as any);
    return compactObject(model);
};

/**
 * Transforms to partial output
 *
 * @param output Partial<PatientOutput>
 */
export const transformToPartialApiOutput = (output: Partial<PatientOutput>): Partial<ApiModels.Output> => {
    const model = ApiModels.Output.DTO.toApi(output as any);
    return compactObject(model);
};

/**
 * Transforms to partial medication administration
 *
 * @param medicationAdministration Partial<MedicationAdministration>
 */
export const transformToPartialApiMedicationAdministration = (
    medicationAdministration,
): Partial<ApiModels.MedicationAdministration> => {
    const model = ApiModels.MedicationAdministration.DTO.toApi(medicationAdministration);
    return compactObject(model);
};

/**
 * Transforms to partial balance target
 *
 * @param balanceTarget Partial<BalanceTarget>
 */
export const transformToPartialApiBalanceTarget = (
    balanceTarget: Partial<BalanceTarget>,
): Partial<ApiModels.BalanceTarget> => {
    const model = ApiModels.BalanceTarget.DTO.toApi(balanceTarget as any);
    return compactObject(model);
};

/**
 * Transforms to partial basic care procedure
 *
 * @param basicCareProcedure Partial<BasicCareProcedure>
 */
export const transformToPartialApiBasicCareProcedure = (
    basicCareProcedure: Partial<BasicCareProcedure>,
): Partial<ApiModels.BasicCare> => {
    const model = ApiModels.BasicCare.DTO.toApi(basicCareProcedure as any);
    return compactObject(model, ['note']);
};

/**
 * Transforms to partial procedure
 *
 * @param procedure Partial<Procedure>
 */
export const transformToPartialApiProcedure = (procedure: Partial<Procedure>): Partial<ApiModels.Procedure> => {
    const model = ApiModels.Procedure.DTO.toApi(procedure as any);
    return compactObject(model, ['note']);
};

/**
 * Transforms to partial entry control
 *
 * @param entryControl Partial<EntryControl>
 */
export const transformToPartialApiEntryControl = (
    entryControl: Partial<EntryControl>,
): Partial<ApiModels.EntryControl> => {
    const model = ApiModels.EntryControl.DTO.toApi(entryControl as any);
    const { id, careChecks, glasgowComaScale } = entryControl;
    if (!id) {
        model['id'] = uuidv4();
        if (!glasgowComaScale?.length) {
            model.glasgow_coma_scale = [];
        }

        if (!careChecks?.length) {
            model.care_checks = [];
        }
    }
    // format date format for BE
    model?.care_checks?.forEach(cc => {
        cc.installation_date = format(new Date(cc.installation_date), 'yyyy-MM-dd');
        cc.last_change = format(new Date(cc.last_change), 'yyyy-MM-dd');
    });

    return model;
};

/**
 * Transforms to partial workflow
 *
 * @param {Partial<Workflow>} workflow - workflow
 */
export const transformToPartialApiWorkflow = (
    workflow: Partial<Workflow>,
): Partial<ApiModels.WorkflowQuestionnaireResponse> => {
    return ApiModels.WorkflowQuestionnaireResponse.DTO.toApi(workflow as ApiModels.WorkflowQuestionnaireResponse.Model);
};

/**
 * Transforms to partial daily goal
 *
 * @param dailyGoal
 */
export const transformToPartialDailyGoal = (dailyGoal: DailyGoal): Partial<ApiModels.DailyGoal> => {
    return ApiModels.DailyGoal.DTO.toApi(dailyGoal as ApiModels.DailyGoal.Model);
};

/**
 * Transforms to change log api entry
 *
 * @param entry ChangeLogEntry<ChangeLogModel>
 * @param encounterId EntityId<Encounter>
 */
export const transformToApiChangeLogEntry = (
    entry: ChangeLogEntry<ChangeLogModel>,
    encounterId: EntityId<Encounter>,
): SetOptional<ApiModels.ChangeLogEntry, 'id' | 'created_at'> => {
    const { model, payload } = entry;
    if (!isEmpty(payload)) {
        const payloadTransformerMap: Record<ChangeLogModelKey, AnyFn> = {
            Patient: transformToPartialApiPatient,
            AdditionalDevice: transformToPartialApiAdditionalDevice,
            Anamnesis: transformToPartialApiAnamnesis,
            VitalSign: transformToPartialApiVitalSign,
            AllergyIntolerance: transformToPartialApiAllergy,
            InfectionStatus: transformToPartialApiInfection,
            Valuables: transformToPartialApiValuable,
            WoundStatus: transformToPartialApiWound,
            VaccinationStatus: transformToPartialApiVaccinations,
            SurgeryPrescription: transformToPartialApiSurgeryPrescription,
            TherapyLimitations: transformToPartialApiTherapyLimitations,
            Diagnosis: transformToPartialApiDiagnosis,
            PreMedication: transformToPartialApiPreMedication,
            PhysicalExamination: transformToPartialApiPhysicalExamination,
            PatientHistory: transformToPartialApiHistory,
            Encounter: transformToPartialApiEncounter,
            LabValue: transformToPartialApiLabValue,
            VentilationParameter: transformToPartialApiVentilationParameter,
            VentilationProcedure: transformToPartialApiVentilationProcedure,
            MedicationPrescription: transformToPartialApiMedicationPrescription,
            ProcedurePrescription: transformToPartialApiProcedurePrescription,
            Output: transformToPartialApiOutput,
            MedicationAdministration: transformToPartialApiMedicationAdministration,
            BalanceTarget: transformToPartialApiBalanceTarget,
            BasicCare: transformToPartialApiBasicCareProcedure,
            Procedure: transformToPartialApiProcedure,
            EntryControl: transformToPartialApiEntryControl,
            WorkflowQuestionnaire: null,
            WorkflowQuestionnaireResponse: transformToPartialApiWorkflow,
            DailyGoal: transformToPartialDailyGoal,
        };
        // transform payload
        entry.payload = isFunction(payloadTransformerMap[model]) ? payloadTransformerMap[model](payload) : null;
    }

    const apiChange: SetOptional<ApiModels.ChangeLogEntry, 'id' | 'created_at'> = {
        model: entry.model,
        model_id: entry.modelId,
        operation: entry.operation,
        payload: entry.payload,
        encounter_id: payload.encounterId,
        practitioner_id: payload.lastChangedBy,
    };

    return apiChange;
};
