import { InjectionToken } from '@angular/core';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, ActionReducerMap, combineReducers, createReducer, on } from '@ngrx/store';
import { isValid, startOfDay, startOfToday } from 'date-fns';
import { MedicationAdministration } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { isNullOrUndefined } from '@mona/shared/utils';
import { makeEntitiesReducer } from '@mona/store';
import {
    AllergiesState,
    AnamnesisState,
    BalanceState,
    BalanceTableEnum,
    CareReportsState,
    DiagnosesState,
    EncounterFeatureState,
    PhysicalExaminationState,
    PreMedicationsState,
} from '../../entities';
import {
    AllergiesAction,
    AnamnesisAction,
    BalanceActions,
    CareReportsAction,
    DiagnosesAction,
    EncounterAction,
    PhysicalExaminationAction,
    PreMedicationsAction,
    setEncounterSelectedDate,
} from '../actions';
import { additionalDeviceReducer } from './additional-device.reducer';
import { balanceLoadingReducer, balanceValuesReducer } from './balance.reducer';
import { basicCareProceduresMapReducer } from './basic-care-procedures-map.reducer';
import { careReportsEndDateReducer } from './care-reports-end-date.reducer';
import { careReportsIntervalReducer } from './care-reports-interval.reducer';
import { dateShiftEntryControlsReducer, entryControlFormReducer } from './date-shift-entry-controls.reducer';
import { historyFilterReducer } from './history-filter.reducer';
import { infectionStatusReducer } from './infection-status.reducer';
import { labValuesReducer } from './lab-values.reducer';
import { outputsMapReducer } from './outputs-map.reducer';
import { surgeryPrescriptionReducer } from './surgery-prescription.reducer';
import { therapyLimitationsReducer } from './therapy-limitations.reducer';
import { vaccinationStatusReducer } from './vaccinations.reducer';
import { valuablesReducer } from './valuables.reducer';
import { ventilationReducer } from './ventilation.reducer';
import { vitalSignsReducer } from './vital-signs.reducer';
import { woundStatusReducer } from './wound-status.reducer';

const INITIAL_DATE = startOfToday();

// =============================================================================
// ENCOUNER DATA REDUCER - MAIN REDUCER
// =============================================================================

/**
 * Allergies reducers
 */
const allergiesReducerMap: ActionReducerMap<AllergiesState> = {
    entities: makeEntitiesReducer(AllergiesAction.loadAllergiesAction),
};

/**
 * Anamnesis reducers
 */
const anamnesisReducerMap: ActionReducerMap<AnamnesisState> = {
    entities: makeEntitiesReducer(AnamnesisAction.loadAnamnesisAction),
};

/**
 * Diagnoses reducers
 */
const diagnosesReducerMap: ActionReducerMap<DiagnosesState> = {
    entities: makeEntitiesReducer(DiagnosesAction.loadDiagnosesAction),
};

/**
 * Pre medications reducers
 */
const preMedicationsReducerMap: ActionReducerMap<PreMedicationsState> = {
    entities: makeEntitiesReducer(PreMedicationsAction.loadPreMedicationsAction),
};

/**
 * Physical examination reducers
 */
const physicalExaminationReducerMap: ActionReducerMap<PhysicalExaminationState> = {
    entities: makeEntitiesReducer(PhysicalExaminationAction.loadPhysicalExaminationAction),
};

/** medication administartion state */
export interface MedicationsAdministrationState extends EntityState<MedicationAdministration> {
    /**
     * Error
     */
    error: any;
}

export const medicationAdministrationAdapter: EntityAdapter<MedicationAdministration> =
    createEntityAdapter<MedicationAdministration>();

export const medAdmInitialState: MedicationsAdministrationState = medicationAdministrationAdapter.getInitialState({
    error: null,
});

/**
 * Balance reducers
 */
const balanceReducerMap: ActionReducerMap<BalanceState> = {
    interval: createReducer(
        EntriesInterval.HOUR_1 as EntriesInterval,
        on(BalanceActions.setIntervalAction, (state, { interval }) => interval),
    ),
    activeTable: createReducer(
        BalanceTableEnum.Inputs as BalanceTableEnum,
        on(BalanceActions.setActiveTable, (state, { idx }) => idx),
    ),

    isLoading: balanceLoadingReducer,
    outputs: makeEntitiesReducer(BalanceActions.loadOutputsAction),
    outputsMap: outputsMapReducer,
    balanceValues: balanceValuesReducer,
    // medicationAdministrations: makeEntitiesReducer(BalanceActions.loadMedicationAdministrationsAction),

    medicationAdministrations: createReducer(
        medAdmInitialState,
        on(BalanceActions.loadMedicationAdministrationsSuccess, (state, action) =>
            medicationAdministrationAdapter.setAll(action.medicationAdministrations, state),
        ),
        on(BalanceActions.upsertMedicationAdministration, (state, action) => {
            return medicationAdministrationAdapter.updateOne(
                {
                    id: action.medicationAdministration.id,
                    changes: action.medicationAdministration,
                },
                state,
            );
        }),
        on(BalanceActions.addMedicationAdministration, (state, action) => {
            return medicationAdministrationAdapter.addOne(action.medicationAdministration, state);
        }),
        on(BalanceActions.clearMedicationAdministrations, (state, action) =>
            medicationAdministrationAdapter.removeAll(state),
        ),
        on(BalanceActions.loadMedicationAdministrationsFailed, (state, action) => ({
            ...state,
            error: action.error,
        })),
    ),
};

/**
 * Care reports reducers
 */
const careReportsReducerMap: ActionReducerMap<CareReportsState> = {
    interval: careReportsIntervalReducer,
    basicCareProcedures: makeEntitiesReducer(CareReportsAction.loadBasicCareProceduresAction),
    basicCareProceduresMap: basicCareProceduresMapReducer,
    entryControls: makeEntitiesReducer(CareReportsAction.loadEntryControlsAction),
    dateShiftEntryControls: dateShiftEntryControlsReducer,
    endDate: careReportsEndDateReducer,
    entryControlForm: entryControlFormReducer,
};

/**
 * Encounter feature reducer map
 */
export const encounterFeatureReducer: ActionReducerMap<EncounterFeatureState> = {
    selectedDate: createReducer(
        INITIAL_DATE,
        on(setEncounterSelectedDate, (prevDate, { selectedDate }) => {
            if (isNullOrUndefined(selectedDate)) {
                return null;
            }
            if (!isValid(selectedDate) || selectedDate.getDate() == prevDate?.getDate()) {
                return prevDate;
            }
            return startOfDay(selectedDate);
        }),
    ),
    encounterHistory: makeEntitiesReducer(EncounterAction.loadEncounterHistoryAction),
    encounterHistoryFilter: historyFilterReducer,
    isReviewMode: createReducer(
        false,
        on(EncounterAction.setEncounterReviewMode, (): boolean => true),
        on(EncounterAction.unsetEncounterReviewMode, (): boolean => false),
    ),
    additionalDevices: additionalDeviceReducer,
    allergies: combineReducers(allergiesReducerMap),
    infections: infectionStatusReducer,
    vaccinations: vaccinationStatusReducer,
    valuables: valuablesReducer,
    woundStatus: woundStatusReducer,
    therapyLimitations: therapyLimitationsReducer,
    surgeryPrescription: surgeryPrescriptionReducer,
    anamnesis: combineReducers(anamnesisReducerMap),
    diagnoses: combineReducers(diagnosesReducerMap),
    preMedications: combineReducers(preMedicationsReducerMap),
    physicalExamination: combineReducers(physicalExaminationReducerMap),
    vitalSigns: vitalSignsReducer,
    ventilation: ventilationReducer,
    labValues: labValuesReducer,
    balance: combineReducers(balanceReducerMap),
    careReports: combineReducers(careReportsReducerMap),
};

/**
 * Reducer injection token definition
 */
export const ENCOUNTER_FEATURE_REDUCER = new InjectionToken<ActionReducerMap<EncounterFeatureState, Action>>(
    'ENCOUNTER_FEATURE_REDUCER',
);

export * from './balance.reducer';
export * from './lab-values.reducer';
export * from './vital-signs.reducer';
