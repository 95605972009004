// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * Serializer for Medication Model Structure
 * @see #/components/schemas/Medication - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface Medication {
    /**
     * The Medication Mona unique code
     * @dataFormat uuid
     */
    readonly code: string;
    /**
     * The medication category code(Id)
     *
     */
    category_code: string;
    readonly display_name: string;
    /**
     * The MedicationGroup Id.
     * @dataFormat uuid
     */
    group?: string | null;
    is_user_defined?: boolean;
    /**
     * Practitioner-ID - Synced with ID of Practitioner in CDR (FHIR)
     * @dataFormat uuid
     */
    last_changed_by?: string | null;
    readonly last_changed_at: string;
    is_deleted?: boolean;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for Medication
 */
export namespace Medication {
    /**
     * Medication Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * The Medication Mona unique code
         * @dataFormat uuid
         */
        readonly code: string;
        /**
         * The medication category code(Id)
         * @type string (`dataFormat` is missing)
         */
        categoryCode: string;
        readonly displayName: string;
        /**
         * The MedicationGroup Id.
         * @dataFormat uuid
         */
        group?: string | null;
        isUserDefined?: boolean;
        /**
         * Practitioner-ID - Synced with ID of Practitioner in CDR (FHIR)
         * @dataFormat uuid
         */
        lastChangedBy?: string | null;
        @TransformDate() readonly lastChangedAt: Date;
        isDeleted?: boolean;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * Medication DTO
     * Transforms Medication model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend Medication model */
        static toModel(obj: Medication): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend Medication model */
        static toApi(obj: Model): Medication {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as Medication;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
