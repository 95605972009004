import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdditionalDevice } from '@mona/models';
import { EntriesInterval } from '@mona/shared/date';
import { groupBy } from '@mona/shared/utils';
import { encounterDataFeatureKey, EncounterFeatureState } from '../../entities';
import { AdditionalDeviceMap } from '../../entities/models/additional-devices-map.model';
import { additionalDevicesAdapter } from '../reducers/additional-device.reducer';

const selectEncounterState = createFeatureSelector(encounterDataFeatureKey);

export const selectAdditionalDevicesState = createSelector(
    selectEncounterState,
    (state: EncounterFeatureState) => state.additionalDevices,
);

export const {
    selectIds: selectAllAdditionalDevicesIds,
    selectEntities: selectAllAdditionalDevicesMap,
    selectAll: selectAllAdditionalDevices,
    selectTotal: selectAllAdditionalDevicesTotal,
} = additionalDevicesAdapter.getSelectors(selectAdditionalDevicesState);

export const selectAdditionalDevicesInterval = createSelector(
    selectAdditionalDevicesState,
    (state: { interval: EntriesInterval }) => state.interval,
);

export const selectAdditionalDevicesIsLoading = createSelector(selectAdditionalDevicesState, state => state.isLoading);

export const selectAllAdditionalDevicesMapByCode = createSelector(selectAllAdditionalDevices, additionalDevices => {
    if (!additionalDevices?.length) {
        return {};
    }
    return groupBy(additionalDevices, (v: AdditionalDevice) => v.code, null, null) as AdditionalDeviceMap;
});

export const selectAdditionalDevicesCodesToShow = createSelector(
    selectAdditionalDevicesState,
    data => data?.codes || [],
);
