// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';
import { WorkflowQuestionnaireQuestionUserDefined } from './workflowQuestionnaireQuestionUserDefined';

/**
 * Serializer used to get fields to update WorkflowQuestionnaire.
 * @see #/components/schemas/WorkflowQuestionnaireUserDefined - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface WorkflowQuestionnaireUserDefined {
    /**
     * Workflow-Questionnaire unique ID.
     *
     */
    id: string;
    /**
     * The title of the Questionnaire.
     *
     */
    title: string;
    /**
     * An array of Workflow-Questions.
     *
     */
    questions: Array<WorkflowQuestionnaireQuestionUserDefined>;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for WorkflowQuestionnaireUserDefined
 */
export namespace WorkflowQuestionnaireUserDefined {
    /**
     * WorkflowQuestionnaireUserDefined Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Workflow-Questionnaire unique ID.
         * @type string (`dataFormat` is missing)
         */
        id: string;
        /**
         * The title of the Questionnaire.
         * @type string (`dataFormat` is missing)
         */
        title: string;
        /**
         * An array of Workflow-Questions.
         * @type Array<WorkflowQuestionnaireQuestionUserDefined> (`dataFormat` is missing)
         */
        @Type(() => WorkflowQuestionnaireQuestionUserDefined.Model)
        questions: Array<WorkflowQuestionnaireQuestionUserDefined.Model>;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * WorkflowQuestionnaireUserDefined DTO
     * Transforms WorkflowQuestionnaireUserDefined model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend WorkflowQuestionnaireUserDefined model */
        static toModel(obj: WorkflowQuestionnaireUserDefined): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend WorkflowQuestionnaireUserDefined model */
        static toApi(obj: Model): WorkflowQuestionnaireUserDefined {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as WorkflowQuestionnaireUserDefined;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
