import { CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    HostBinding,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GIT_VERSION } from '@environment';
import { combineLatest, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { ApiHealthFacade, WrongUserPersistDialogComponent } from '@mona/api';
import { ConfigService } from '@mona/config';
import { ClockService } from '@mona/shared/date';
import { AppError, PLATFORM, Platform, takeUntilDestroy, TakeUntilDestroy } from '@mona/shared/utils';
import { DialogService } from '@mona/ui';
import { AuthFlow, AuthTypeEnum, User } from '../../models';
import { AuthService, ConfirmLockScreenService } from '../../services';
import { LockScreenPortalService } from './lock-screen-portal.service';
import { LockScreenViewState } from './models';
/**
 * Lock screen overlay
 */
@TakeUntilDestroy
@Component({
    selector: 'mona-lock-screen',
    templateUrl: './lock-screen.component.html',
    styleUrls: ['./lock-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockScreenComponent implements OnDestroy, OnInit {
    /**
     * Git version
     */
    version = (GIT_VERSION as any)?.tag;
    /**
     * Host css class
     */
    @HostBinding('class.mona-lock-screen') cmpClass = true;

    /**
     * Current time
     */
    time$: Observable<string> = this.clockService.getFormatted('shortTime');

    /**
     * Current date
     */
    date$: Observable<string> = this.clockService.getFormatted('fullDate');

    /**
     * Current time
     */
    currentTime$: Observable<Date> = this.clockService.now$;

    isCoreInMaintenance$: Observable<boolean> = this.apiHealthFacade.isInMaintenance$(
        this.configService.get('api.baseUrl'),
    );

    /**
     * Available view stated
     */
    viewStates = LockScreenViewState;

    /**
     * Defines which control view is shown
     */
    viewState$ = combineLatest([
        this.authFacade.error$,
        this.authFacade.activatedRFIDCard$,
        this.isCoreInMaintenance$,
    ]).pipe(
        map(([error, activatedRFIDCard, isCoreInMaintenance]) => {
            return this.getCurrentViewState(activatedRFIDCard, error, isCoreInMaintenance);
        }),
    );

    /**
     * Is PDMS enabled (Hides CE label if not)
     */
    isPdmsEnabled = this.configService.get('features.pdms');

    isLoading$: Observable<boolean> = this.authFacade.isLoading$;

    user$: Observable<User> = this.authFacade.user$;

    // Latest scanned RFID card
    latestRFID: string;

    /**
     * Creates an instance of LockScreenComponent.
     *
     * @param {Platform} platform
     * @param {Document} document
     * @param {ApiHealthFacade} apiHealthFacade
     * @param {ClockService} clockService
     * @param {AuthService} authFacade
     * @param {ConfirmLockScreenService} confirmLockScreenService
     * @param {ConfigService} configService
     * @param {LockScreenPortalService} lockScreenPortalService
     * @param {Renderer2} renderer
     * @param {ActivatedRoute} route
     * @param {DialogService} dialogService
     */
    constructor(
        @Inject(PLATFORM) public platform: Platform,
        @Inject(DOCUMENT) public document: Document,
        private apiHealthFacade: ApiHealthFacade,
        private clockService: ClockService,
        private authFacade: AuthService,
        private confirmLockScreenService: ConfirmLockScreenService,
        private configService: ConfigService,
        public lockScreenPortalService: LockScreenPortalService,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private dialogService: DialogService,
    ) {}

    /**
     * Handle login with rfid or username/password
     */
    ngOnInit() {
        this.authFacade.logOutSuccess();

        if (this.platform.isElectron) {
            this.authFacade.rfidScanned$.pipe(throttleTime(500), takeUntilDestroy(this)).subscribe(rfid => {
                this.latestRFID = rfid;
                this.authFacade.loginWithRfid(rfid);
            });
        } else {
            this.openSignInDialog();
        }

        if (this.route.snapshot.queryParams['different-user-persist']) {
            const subtitleMap = {
                persisted: 'apps.settings.differentUserPersist.descriptionSuccess',
                failed: 'apps.settings.differentUserPersist.descriptionFailed',
                rfid: 'apps.userSettings.rfid.rfidActionFailed.subtitle',
            };
            const data = {
                title:
                    this.route.snapshot.queryParams['different-user-persist'] === 'rfid'
                        ? 'apps.userSettings.rfid.rfidActionFailed.title'
                        : 'apps.settings.differentUserPersist.title',
                subtitle: subtitleMap[this.route.snapshot.queryParams['different-user-persist']],
            };
            this.dialogService.open(WrongUserPersistDialogComponent, data);
        }

        this.renderer.addClass(this.document.body, 'mona-lock-screen--opened');
    }

    /**
     * Handle submit of practitioner PIN
     * @param pin
     */
    async onPinSubmit(pin: string) {
        this.authFacade.registerRfid(this.latestRFID, pin);
    }

    /**
     * NG Hook
     */
    ngOnDestroy() {
        this.confirmLockScreenService.confirmLockScreen();
        this.renderer.removeClass(this.document.body, 'mona-lock-screen--opened');
    }

    /**
     * Opens diagnostics dialog
     */
    onTimeDoubleClick(): void {
        /* this.dialogService.open(
            DiagnosticsComponent,
            {},
            {
                height: '80vh',
                hasBackdrop: true,
                disableClose: false,
                panelClass: ['mona-diagnostics-dialog'],
                closeOnNavigation: true,
            },
        ); */
    }

    /**
     * Opens sign-in dialog
     */
    openSignInDialog(): void {
        this.authFacade.authenticate(AuthFlow.signin, AuthTypeEnum.Credentials);
    }

    /**
     * Returns the current view state based on the register and verify action state
     *
     * @param activatedRFIDCard
     * @param error AsyncActionState<VerifyRfidResult>
     * @param isCoreInMaintenance boolean
     */
    getCurrentViewState(
        activatedRFIDCard = false,
        error: AppError = null,
        isCoreInMaintenance = false,
    ): LockScreenViewState {
        if (isCoreInMaintenance) {
            // System is in maintenance
            return LockScreenViewState.MAINTENANCE;
        }

        if (activatedRFIDCard) {
            // User has registered with a pin code
            return LockScreenViewState.WELCOME;
        }

        if (error?.errorCode === 'practitioner.rfid_not_found' || error?.errorCode === 'practitioner.pin_not_found') {
            // This is a new rfid card
            return LockScreenViewState.PIN;
        }

        if (!this.platform.isElectron) {
            return LockScreenViewState.UNKNOWN;
        }

        return LockScreenViewState.RFID;
    }

    /**
     * Set notification data
     *
     * @tutorial https://stackoverflow.com/a/62062202/4115894
     * @param portalOutletRef
     * @example ```
     * <ng-container [cdkPortalHost]="lockScreenPortalService.lockScreenInfoPortal$ | async" (attached)="setNotificationData($event)"></ng-container>
     * ```
     */
    setNotificationData(portalOutletRef: CdkPortalOutletAttachedRef) {
        (portalOutletRef as ComponentRef<any>).instance.notificationsCount = 1;
    }
}
