import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AdditionalDevice, DataUpdateMessage, ParamsModel } from '@mona/models';
import { ChangeLogService } from '@mona/pdms/data-access-changelog';
import { AdditionalDeviceMap, EncounterDataState } from '../entities';
import {
    AdditionalDeviceAction,
    selectAdditionalDevicesCodesToShow,
    selectAdditionalDevicesIsLoading,
    selectAllAdditionalDevicesMapByCode,
} from '../state';

/**
 * Additional Devices service
 */
@Injectable({ providedIn: 'root' })
export class AdditionalDevicesService {
    isLoading$: Observable<boolean> = this.store.select(selectAdditionalDevicesIsLoading);
    /**
     * Constructor
     *
     * @param store Store
     * @param changeLogService ChangeLogService
     */
    constructor(private store: Store<EncounterDataState>, private changeLogService: ChangeLogService) {}

    /**
     * Set Loading
     *
     * @param isLoading
     */
    setLoading(isLoading: boolean): void {
        this.store.dispatch(AdditionalDeviceAction.setLoadingAction({ isLoading }));
    }

    /**
     * Loads additional devices from the backend
     *
     * @param params Optional parameters to filter and paginate the results
     * @param url Optional URL to load from a different endpoint
     */
    loadAdditionalDevices(params?: ParamsModel, url?: string): void {
        this.store.dispatch(AdditionalDeviceAction.loadAdditionalDevices({ params, url }));
    }

    /** Clear additional devices */
    clearAdditionalDevices(): void {
        this.store.dispatch(AdditionalDeviceAction.clearAdditionalDevices());
    }

    /**
     * Ventilation parameters grouped map
     */
    getAdditionalDeviceMapByCode(): Observable<AdditionalDeviceMap> {
        return this.store.select(selectAllAdditionalDevicesMapByCode);
    }

    /** Additional devices codes to show in the table */
    getAdditionalDeviceCodesToShow(): Observable<string[]> {
        return this.store.select(selectAdditionalDevicesCodesToShow);
    }

    /**
     * Update additional devices
     *
     * @param updateMessage
     */
    updateAdditionalDevices(updateMessage: DataUpdateMessage<AdditionalDevice>): void {
        const dataItem = {
            ...updateMessage.payload,
            id: updateMessage.modelId,
        } as AdditionalDevice;
        const data = [dataItem];
        this.store.dispatch(AdditionalDeviceAction.upsertAdditionalDevices({ data }));
    }
}
