/* eslint-disable no-restricted-syntax */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouterState } from '@mona/store';
import { NotificationsState } from '../../entities';
import { notificationsFeatureKey } from '../reducers/notifications.reducer';

export const selectNotificationsState = createFeatureSelector<NotificationsState>(notificationsFeatureKey);

/**
 * Get Unread notification count
 */
export const selectUnreadNotificationsCount = createSelector(
    selectNotificationsState,
    (state: NotificationsState) =>
        state.notifications.rawEntities.filter(notification => !notification.readStatus).length
);

/**
 * Get Notification Actions
 */
export const selectLoadNotificationsAction = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.notifications.loadAction,
);

/**
 * Get resolve notification async action
 */
export const selectResolveNotificationAction = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.resolveNotificationAction,
);

/**
 * Get notifications
 */
export const selectNotifications = createSelector(
    selectNotificationsState,
    (state: NotificationsState) => state.notifications.rawEntities,
);

/**
 * Get notifications of encounter
 *
 * @param encounterId EntityId<Encounter>
 */
export const selectEncounterNotifications = createSelector(
    selectRouterState,
    selectNotifications,
    (routerState, notifications) => {
        const encounterId = routerState.params?.encounterId;
        if (routerState.url.includes('encounter') && encounterId) {
            notifications.filter(notification => notification.streamId === encounterId);
        }
        return null;
    },
);

export const selectEncounterNotificationsCount = createSelector(
    selectRouterState,
    selectNotifications,
    (routerState, notifications) => {
        const encounterId = routerState.params?.encounterId;
        if (routerState.url.includes('encounter') && encounterId) {
            return notifications.filter(notification => notification.streamId === encounterId)?.length;
        }
        return null;
    },
);
