import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { ReportLocation, ReportType } from '@mona/models';
import * as ReportsActions from '../state';
import * as ReportsSelectors from '../state';

/**
 * Reports facade service
 */
@Injectable({
    providedIn: 'root',
})
export class ReportsFacade {
    /** Is loading */
    isLoading$: Observable<boolean> = merge(
        this.store.select(ReportsSelectors.selectReportTypesIsLoading),
        this.store.select(ReportsSelectors.selectLocationsIsLoading),
    );

    /**
     * Get report types
     */
    reportTypes$: Observable<ReportType[]> = this.store.select(ReportsSelectors.selectReportTypes);
    /**
     * Get report locations
     */
    reportLocations$: Observable<ReportLocation[]> = this.store.select(ReportsSelectors.selectLocations);
    /**
     * Constructor
     *
     * @param store store
     */
    constructor(private store: Store<any>) {}

    /**
     * Dispatch load report types
     */
    loadReports(): void {
        this.store.dispatch(ReportsActions.loadReportTypes());
    }

    /**
     * Dispatch load report locations
     */
    loadReportLocations(): void {
        this.store.dispatch(ReportsActions.loadReportsExportLocations());
    }

    /**
     * Dispatch full export report
     *
     * @param reportType
     * @param reportLocation
     */
    exportReport(reportType: string, reportLocation: string): void {
        this.store.dispatch(ReportsActions.exportReport({ reportType, reportLocation }));
    }

    /**
     * Clear report
     */
    clearReportTypes(): void {
        this.store.dispatch(ReportsActions.clearReportTypes());
    }
}
