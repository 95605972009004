<div class="mona-update-rfid__container" #dialog>
    <div mat-dialog-content>
        <h2 mat-dialog-title class="mona-update-rfid__title">
            {{ 'apps.userSettings.rfid.updateRfid.registerTitle' | translate }}
        </h2>
        <p class="mona-update-rfid__description">
            {{ 'apps.userSettings.rfid.updateRfid.registerDescription' | translate }}
        </p>
        <p class="mona-update-rfid__description">
            {{ 'apps.userSettings.rfid.updateRfid.pleaseScanCard' | translate }}
        </p>

        <mat-icon [inline]="true" class="mona-update-rfid__icon text-accent">wifi_tethering</mat-icon>
        <button mat-icon-button type="button" class="mona-update-rfid__close" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
