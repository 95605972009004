import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigService } from '@mona/config';
import { EntriesInterval } from '@mona/shared/date';
import { EncounterDataState } from '../entities';
import { AdditionalDeviceAction, selectAdditionalDevicesInterval } from '../state';

/**
 * Treatment Interventions service
 */
@Injectable({ providedIn: 'root' })
export class TreatmentInterventionsService {
    /**
     * Constructor
     *
     * @param store Store
     * @param configService ConfigService
     */
    constructor(private store: Store<EncounterDataState>, private configService: ConfigService) {
        const defaultInterval = configService.get('defaultInterval');
        if (defaultInterval) {
            this.setInterval(EntriesInterval[defaultInterval]);
        }
    }

    /**
     * Get selected interval
     */
    getInterval(): Observable<EntriesInterval> {
        return this.store.select(selectAdditionalDevicesInterval);
    }

    /**
     * Sets interval
     *
     * @param interval EntriesInterval
     */
    setInterval(interval: EntriesInterval): void {
        this.store.dispatch(AdditionalDeviceAction.setIntervalAction({ interval }));
    }
}
