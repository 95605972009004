import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform, PLATFORM } from '@mona/shared/utils';

/**
 * Detects if user using device
 */
@Injectable()
export class PlatformElectronGuard {
    /**
     * INFO: add comment
     * @param platform
     * @param router
     */
    constructor(@Inject(PLATFORM) private platform: Platform, private router: Router) {}

    /**
     * Allow only if platform is electron
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.platform.isElectron;
    }
}
