import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiModels } from '@mona/api';
import { ConfigService } from '@mona/config';
import { AppError, AuthErrors, isEmptyObject, isNonNullable, Platform, PLATFORM } from '@mona/shared/utils';
import { DialogService } from '@mona/ui';
import { SignInComponent } from '../../components';
import { AuthFlow, AUTH_LOCKSCREEN_DIALOG_ID, AUTH_SIGNIN_DIALOG_ID, TokenResponse, User } from '../../models';
import { JwtHelperService, TokenStorageService } from '../../services';
import { AuthActions } from '../actions';
import * as AuthSelectors from '../selectors';
import { BaseAuthEffects } from './base-auth.effects';

/**
 * Effect class for auth effects
 */
@Injectable({ providedIn: 'root' })
export class LoginEffects extends BaseAuthEffects implements OnInitEffects {
    authenticateOpen$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.authenticateOpen),
                tap(({ authType, authFlow, additionalMessage, shouldSetRfid }) => {
                    this.dialogService.getDialogById(AUTH_SIGNIN_DIALOG_ID)?.close();
                    this.dialogService.open(
                        SignInComponent,
                        { enableClose: true, authType, authFlow, additionalMessage, shouldSetRfid },
                        {
                            id: AUTH_SIGNIN_DIALOG_ID,
                            backdropClass: 'mona-dialog__backdrop--transparent',
                            panelClass: ['mona-dialog', 'mona-auth-dialog', `mona-auth-dialog--${authFlow}`],
                            closeOnNavigation: true,
                        },
                    );
                }),
            );
        },
        { dispatch: false },
    );

    authenticateClose$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.authenticateClose),
                tap(() => {
                    this.dialogService.getDialogById(AUTH_SIGNIN_DIALOG_ID)?.close();
                    this.store.dispatch(AuthActions.clearAuthError());
                }),
            );
        },
        { dispatch: false },
    );

    // re-login with last scanned rfid
    relogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.relogIn),
            concatLatestFrom(() => this.store.select(AuthSelectors.selectUserRfid)),
            map(([, rfid]) => {
                return AuthActions.logInWithRfid({ payload: { rfid }, isRelogin: true });
            }),
        );
    });

    loginOrVerify$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.logInWithCredentials, AuthActions.logInWithRfid),
                exhaustMap(({ payload, authFlow, shouldSetRfid }) => {
                    const device_id = this.platform.isElectron
                        ? this.tokenStorage.getItem('mona.device_id')
                        : undefined;
                    if (BaseAuthEffects.isLoginPayloadRfid(payload)) {
                        return this.authApi.loginWithRfid(payload.rfid, device_id).pipe(
                            map(data => ({ ...data, rfid: payload.rfid, authFlow })),
                            catchError(error => of({ error, authFlow })),
                        );
                    } else {
                        return this.authApi.loginWithCreds(payload.username, payload.password, device_id).pipe(
                            map(data => ({
                                ...data,
                                authFlow,
                            })),
                            catchError(error => of({ error, authFlow, shouldSetRfid })),
                        );
                    }
                }),
                concatLatestFrom(() => this.store.select(AuthSelectors.selectUserRfid)),
                switchMap(
                    ([data]: [TokenResponse & { error: any; authFlow: AuthFlow; shouldSetRfid: boolean }, string]) => {
                        if (data.error) {
                            this.store.dispatch(
                                AuthActions.loginFailure({ error: data.error, authFlow: data.authFlow }),
                            );
                        } else {
                            // save tokens
                            this.tokenStorage.saveTokens(data.token, data.refreshToken);

                            return this.authApi.getPermissions().pipe(
                                tap(permissions => {
                                    // save permissions into ngrx
                                    this.permissionsService.loadPermissions(permissions);
                                    this.store.dispatch(AuthActions.loadPermissionsSuccess({ permissions }));

                                    this.store.dispatch(
                                        AuthActions.loginSuccess({
                                            authFlow: data.authFlow,
                                            shouldSetRfid: data.shouldSetRfid,
                                        }),
                                    );
                                }),
                            );
                        }
                        return of(null); // switchMap should return something, also for test
                    },
                ),
            );
        },
        { dispatch: false },
    );

    loginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            mergeMap(({ authFlow, shouldSetRfid }) => {
                this.closeOpenedDialogs();
                // TODO: check token integrity sooner
                const token = this.tokenStorage.getAccessToken();
                const id = this.jwtHelper.getTokenPayloadByPath<string>(token, 'sub');
                const token_user = this.jwtHelper.getTokenPayloadByPath<ApiModels.Practitioner>(token, 'user');
                if (!id || !token_user) {
                    const error = new AppError({ code: AuthErrors.TOKEN_INVALID });
                    return [AuthActions.loginFailure({ error })];
                }
                const user = new User(token_user);

                return [AuthActions.setAuthUser({ user, skipWelcome: authFlow === AuthFlow.verify, shouldSetRfid })];
            }),
        );
    });

    /** Get roles from user object & load permissions with user rfid  */
    setUser$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.setAuthUser),
                map(({ user, skipWelcome }) => {
                    // register roles & permissions with `Ngx-Permissions`
                    const roles = user.role.reduce((acc, curr) => ({ [curr]: [] }), {});
                    this.rolesService.addRoles(roles);
                    // Shows a welcome toast message when the active user has changed
                    if (user.id && !skipWelcome) {
                        this.messageService.successToast('shell.welcomeMessage', {
                            displayName: user.displayName,
                        });
                    }
                }),
            );
        },
        { dispatch: false },
    );

    /**
     * Load permissions by user's rfid from current user in store
     *
     * @memberof AuthEffects
     */
    loadPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadPermissions),
            switchMap(() => this.authApi.getPermissions()),
            map(permissions => {
                if (isEmptyObject(permissions)) {
                    throw new AppError('Permissions empty');
                }
                // register roles & permissions with `Ngx-Permissions`
                this.permissionsService.loadPermissions(permissions);
                return AuthActions.loadPermissionsSuccess({ permissions });
            }),
            catchError((error: unknown) => of(AuthActions.loadPermissionsFailed({ error }))),
        ),
    );

    refreshToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.refreshTokenRequest),
            exhaustMap(() => {
                const refreshToken = this.tokenStorage.getRefreshToken();
                const accessToken = this.tokenStorage.getAccessToken();
                if (!isNonNullable(refreshToken)) {
                    const error = new AppError({ code: AuthErrors.TOKEN_INVALID });
                    return of(AuthActions.refreshTokenFailure({ error }));
                }
                const expired = this.jwtHelper.isTokenExpired(refreshToken);
                if (expired) {
                    const error = new AppError({ code: AuthErrors.TOKEN_EXPIRED });
                    return of(AuthActions.refreshTokenFailure({ error }));
                }
                const device_id = this.platform.isElectron ? this.tokenStorage.getItem('mona.device_id') : undefined;
                const token_user = this.jwtHelper.getTokenPayloadByPath<ApiModels.Practitioner & { rfid: string }>(
                    accessToken,
                    'user',
                );
                if (!token_user) {
                    const error = new AppError({ code: AuthErrors.TOKEN_INVALID });
                    return of(AuthActions.refreshTokenFailure({ error }));
                }
                return this.authApi.refreshToken(refreshToken, token_user.rfid, device_id).pipe(
                    map(data => {
                        // save tokens
                        this.tokenStorage.saveTokens(data.token);
                        // trigger refresh token success action
                        return AuthActions.refreshTokenSuccess(data);
                    }),
                    catchError(error => of(AuthActions.refreshTokenFailure({ error }))),
                );
            }),
        );
    });

    // always refresh token with page reload for browser
    checkTokenAfterRefresh = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.checkToken),
                tap(() => {
                    if (this.platform.isElectron) {
                        this.flushUserData();
                        return;
                    }
                    const token = this.tokenStorage.getAccessToken();
                    if (!isNonNullable(token)) {
                        return;
                    }
                    const token_user = this.jwtHelper.getTokenPayloadByPath<ApiModels.Practitioner>(token, 'user');
                    if (!token_user) {
                        return;
                    }
                    this.store.dispatch(AuthActions.loadPermissions());
                    this.store.dispatch(AuthActions.loginSuccess({}));
                    this.store.dispatch(AuthActions.refreshTokenRequest());
                }),
            ),
        { dispatch: false },
    );

    onLoginFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.loginFailure),
                tap(({ error, authFlow }: { error: AppError; authFlow?: AuthFlow }) => {
                    if (error.errorCode === 'practitioner.rfid_not_found') {
                        const message =
                            error.originalError.error.title || 'No practitioner with the given RFID could be found.';
                        this.messageService.warnToast(message);
                        return;
                    }
                    if (authFlow !== AuthFlow.verify) {
                        this.flushUserData();
                    }
                }),
            );
        },
        { dispatch: false },
    );

    onRefreshTokenFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.refreshTokenFailure),
                tap(() => {
                    this.flushUserData();
                    this.router.navigateByUrl('auth/lock-screen?full=true');
                }),
            );
        },
        { dispatch: false },
    );

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.changePassword),
            exhaustMap(({ oldPassword, newPassword, username }) => {
                return this.authApi.changePassword(oldPassword, newPassword, username).pipe(
                    switchMap(() => of(AuthActions.changePasswordSuccess())),
                    catchError((error: unknown) => of(AuthActions.changePasswordFailure({ error }))),
                );
            }),
        );
    });

    changePasswordSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.changePasswordSuccess),
                tap(() => {
                    this.messageService.successToast('apps.userSettings.password.success');
                }),
            );
        },
        { dispatch: false },
    );

    changePasswordFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.changePasswordFailure),
                tap(() => this.messageService.errorToast('apps.userSettings.password.failure')),
            );
        },
        { dispatch: false },
    );

    /**
     * Constructor
     *
     * @param router
     * @param permissionsService
     * @param rolesService
     * @param dialogService
     * @param tokenStorage
     * @param jwtHelper
     * @param platform
     */
    constructor(
        private router: Router,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService,
        private dialogService: DialogService,
        private tokenStorage: TokenStorageService,
        private jwtHelper: JwtHelperService,
        @Inject(PLATFORM) private platform: Platform,
    ) {
        super();
    }

    /**
     * ngrxOnInitEffects
     */
    ngrxOnInitEffects(): Action {
        this.logger.log('should init ckeck token');
        return AuthActions.checkToken();
    }

    /** Clears tokens and permissions if present */
    private flushUserData() {
        this.logger.log('should flush stored user data');
        this.tokenStorage.removeTokens();
        this.permissionsService.flushPermissions();
        this.rolesService.flushRoles();
    }

    /** Close dialos with id 'lock-screen' if present */
    private closeOpenedDialogs() {
        this.dialogService.getDialogById(AUTH_LOCKSCREEN_DIALOG_ID)?.close();
        this.dialogService.getDialogById(AUTH_SIGNIN_DIALOG_ID)?.close();
    }
}
