// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

/**
 * ClinicalImpression Serializer.
 * @see #/components/schemas/ClinicalImpression - reference to schema in open-api-spec.yaml
 * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
 * @version 1.6.65
 *
 * NOTE: This class is auto generated by OpenAPI Generator
 * Do not edit this manually.
 */
export interface ClinicalImpression {
    /**
     * Practitioner unique id.
     * @dataFormat uuid
     */
    last_changed_by: string | null;
    /**
     * The identifier of the author - a practitioner.
     * @dataFormat uuid
     */
    author_id?: string | null;
    /**
     * Clinical Impression unique id.
     * @dataFormat uuid
     */
    id: string;
    /**
     * The identifier of the related Patient.
     * @dataFormat uuid
     */
    patient_id: string;
    /**
     * The identifier of the related Encounter.
     * @dataFormat uuid
     */
    encounter_id: string;
    /**
     * The value of Senior Physician Order.
     *
     */
    value: string;
    /**
     * The date when value was created.
     * @dataFormat date-time
     */
    date: string;
    /**
     * The clinical impression type.  * `physician-report` - PHYSICIAN_REPORT * `senior-physician-order` - SENIOR_PHYSICIAN_ORDER * `event` - EVENT * `speech-therapy-report` - SPEECH_THERAPY_REPORT * `physical-therapy-report` - PHYSICAL_THERAPY_REPORT * `neurology-report` - NEUROLOGY_REPORT * `care-report` - CARE_REPORT
     *
     */
    type: ClinicalImpression.TypeEnum;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for ClinicalImpression
 */
export namespace ClinicalImpression {
    export type TypeEnum =
        | 'physician-report'
        | 'senior-physician-order'
        | 'event'
        | 'speech-therapy-report'
        | 'physical-therapy-report'
        | 'neurology-report'
        | 'care-report';
    export const TypeEnum = {
        PhysicianReport: 'physician-report' as TypeEnum,
        SeniorPhysicianOrder: 'senior-physician-order' as TypeEnum,
        Event: 'event' as TypeEnum,
        SpeechTherapyReport: 'speech-therapy-report' as TypeEnum,
        PhysicalTherapyReport: 'physical-therapy-report' as TypeEnum,
        NeurologyReport: 'neurology-report' as TypeEnum,
        CareReport: 'care-report' as TypeEnum,
    };

    /**
     * ClinicalImpression Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Practitioner unique id.
         * @dataFormat uuid
         */
        lastChangedBy: string | null;
        /**
         * The identifier of the author - a practitioner.
         * @dataFormat uuid
         */
        authorId?: string | null;
        /**
         * Clinical Impression unique id.
         * @dataFormat uuid
         */
        id: string;
        /**
         * The identifier of the related Patient.
         * @dataFormat uuid
         */
        patientId: string;
        /**
         * The identifier of the related Encounter.
         * @dataFormat uuid
         */
        encounterId: string;
        /**
         * The value of Senior Physician Order.
         * @type string (`dataFormat` is missing)
         */
        value: string;
        /**
         * The date when value was created.
         * @dataFormat date-time
         */
        @TransformDate() date: Date;
        /**
         * The clinical impression type.  * `physician-report` - PHYSICIAN_REPORT * `senior-physician-order` - SENIOR_PHYSICIAN_ORDER * `event` - EVENT * `speech-therapy-report` - SPEECH_THERAPY_REPORT * `physical-therapy-report` - PHYSICAL_THERAPY_REPORT * `neurology-report` - NEUROLOGY_REPORT * `care-report` - CARE_REPORT
         * @type string (`dataFormat` is missing)
         */
        type: ClinicalImpression.TypeEnum;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * ClinicalImpression DTO
     * Transforms ClinicalImpression model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend ClinicalImpression model */
        static toModel(obj: ClinicalImpression): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend ClinicalImpression model */
        static toApi(obj: Model): ClinicalImpression {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as ClinicalImpression;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
