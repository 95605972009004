import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiModels, HttpService } from '@mona/api';
import { Encounter, MonaNotification, NotificationResolution } from '@mona/models';
import { transformApiNotification, transformApiNotifications } from './transforms';

/**
 * API abstraction layer for the notifications API
 */
@Injectable({
    providedIn: 'root',
})
export class NotificationsApi {
    apiBase = '/notifications/';

    /**
     * Constructor
     *
     * @param http HttpClient
     */
    constructor(private http: HttpService) {}

    /**
     * Get notifications
     *
     * @param encounterId EntityId<Encounter>
     */
    getNotifications(encounterId?: EntityId<Encounter>): Observable<MonaNotification[]> {
        return this.http
            .get<ApiModels.Notification[]>(this.apiBase, {
                params: {
                    stream_id: `${encounterId}`,
                },
            })
            .pipe(map(notification => transformApiNotifications(notification)));
    }

    /**
     * Resolves notification
     *
     * @param id EntityId<MonaNotification>
     * @param resolution NotificationResolution
     */
    resolveNotification(id: EntityId<MonaNotification>, resolution: NotificationResolution): Observable<void> {
        return this.http.post<void>(`${this.apiBase}/${id}/resolve/`, {
            resolution,
        });
    }
    /**
     * Changes the read_status of a notification
     *
     * @param id string
     * @param readStatus boolean
     */
    changeNotificationReadStatus(id: string, readStatus: boolean): Observable<MonaNotification> {
        return this.http
            .post<ApiModels.Notification>(`${this.apiBase}/${id}/change_read_status/`, {
                read_status: readStatus,
            })
            .pipe(map(notification => transformApiNotification(notification)));
    }
    }
