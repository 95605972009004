/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
    AdditionalDeviceType,
    BasicCareProcedureType,
    BloodAdministrationType,
    CareCheckType,
    DischargeReason,
    GlasgowComaScaleDataFlowsValues,
    LabValueType,
    Medication,
    MedicationAdministrationMethod,
    MedicationCategory,
    MedicationDosageForm,
    MedicationGroup,
    MedicationSolution,
    MedicationUnit,
    OutputFactor,
    PractitionerShift,
    PrescriptionFrequency,
    PrescriptionFrequencyTime,
    PrescriptionNotGivenReason,
    ProcedureCategory,
    SearchDiagnosisResult,
    TaskListFilter,
    TerminologyDailyGoal,
    VaccineCode,
    VentilationMode,
    VentilationParameterType,
    VitalSignType,
} from '@mona/models';
import { AsyncActionState } from '@mona/store';
import { TerminologyState } from '../entities';
import { TerminologyApi } from '../infrastructure';
import { TerminologyActions } from '../state';

type State = { terminology: TerminologyState };

/**
 * Terminology Service
 */
@Injectable({
    providedIn: 'root',
})
export class TerminologyService {
    /**
     * Constructor
     *
     * @param store Store<State>
     * @param terminologyApiService TerminologyApi
     */
    constructor(private store: Store<State>, private terminologyApiService: TerminologyApi) {}

    /** Invalidate terminology cache */
    invalidateCache() {
        this.store.dispatch(TerminologyActions.invalidateCache());
    }

    //#region selectors

    /**
     * Get search diagnosis async action state
     */
    getSearchDiagnosisAction(): Observable<AsyncActionState<SearchDiagnosisResult[]>> {
        return this.store.select((state: State) => state.terminology.searchDiagnosisAction);
    }

    /**
     * Get load vital sign types async action state
     */
    getLoadVitalSignTypesAction(): Observable<AsyncActionState<VitalSignType[]>> {
        return this.store.select((state: State) => state.terminology.vitalSignTypes.loadAction);
    }

    /**
     * Get load ventilation parameter types async action state
     */
    getLoadVentilationParameterTypesAction(): Observable<AsyncActionState<VentilationParameterType[]>> {
        return this.store.select((state: State) => state.terminology.ventilationParameterTypes.loadAction);
    }

    /**
     * Get load ventilation modes async action state
     */
    getLoadVentilationModesAction(): Observable<AsyncActionState<VentilationMode[]>> {
        return this.store.select((state: State) => state.terminology.ventilationModes.loadAction);
    }

    /**
     * Get load medication categories async action state
     */
    getLoadMedicationCategoriesAction(): Observable<AsyncActionState<MedicationCategory[]>> {
        return this.store.select((state: State) => state.terminology.medicationCategories.loadAction);
    }

    /**
     * Get load procedure categories async action state
     */
    getLoadProcedureCategoriesAction(): Observable<AsyncActionState<ProcedureCategory[]>> {
        return this.store.select((state: State) => state.terminology.procedureCategories.loadAction);
    }

    /**
     * Get load prescription frequencies async action state
     */
    getLoadPrescriptionFrequenciesAction(): Observable<AsyncActionState<PrescriptionFrequency[]>> {
        return this.store.select((state: State) => state.terminology.prescriptionFrequencies.loadAction);
    }

    /**
     * Get load prescription frequency times async action state
     */
    getLoadPrescriptionFrequencyTimesAction(): Observable<AsyncActionState<PrescriptionFrequencyTime[]>> {
        return this.store.select((state: State) => state.terminology.prescriptionFrequencyTimes.loadAction);
    }

    /**
     * Get load medication administration methods async action state
     */
    getLoadMedicationAdministrationMethodsAction(): Observable<AsyncActionState<MedicationAdministrationMethod[]>> {
        return this.store.select((state: State) => state.terminology.medicationAdministrationMethods.loadAction);
    }

    /**
     * Get load medication administration methods async action state
     */
    getLoadPractitionerShiftsAction(): Observable<AsyncActionState<PractitionerShift[]>> {
        return this.store.select((state: State) => state.terminology.practitionerShifts.loadAction);
    }

    /**
     * Get load CareCheckType async action state
     */
    getLoadCareCheckTypes(): Observable<AsyncActionState<CareCheckType[]>> {
        return this.store.select((state: State) => state.terminology.careCheckTypes.loadAction);
    }

    /**
     * Returns the searchMedicationsAction state
     */
    getSearchMedicationsAction(): Observable<AsyncActionState<Medication[]>> {
        return this.store.select(state => state.terminology.searchMedicationsAction);
    }

    /**
     * Returns the createMedicationsAction state
     */
    getCreateMedicationAction(): Observable<AsyncActionState<Medication>> {
        return this.store.select(state => state.terminology.createMedicationAction);
    }

    /**
     * Get available vital sign types
     */
    getVitalSignTypes(): Observable<VitalSignType[]> {
        return this.store.select((state: State) => state.terminology.vitalSignTypes.rawEntities);
    }

    /**
     * Get vital sign types map
     */
    getVitalSignTypesMap(): Observable<EntityMap<VitalSignType>> {
        return this.store.select((state: State) => state.terminology.vitalSignTypes.entities);
    }

    /**
     * Get daily goals terminology
     */
    getDailyGoalsTerminology(): Observable<TerminologyDailyGoal[]> {
        return this.store.select((state: State) => state.terminology.dailyGoalsTerminologyValues);
    }

    /**
     * Get available ventilation parameter types
     */
    getVentilationParameterTypes(): Observable<VentilationParameterType[]> {
        return this.store.select((state: State) => state.terminology.ventilationParameterTypes.rawEntities);
    }

    /**
     * Get ventilation parameter types map
     */
    getVentilationParameterTypesMap(): Observable<EntityMap<VentilationParameterType>> {
        return this.store.select((state: State) => state.terminology.ventilationParameterTypes.entities);
    }

    /**
     * Get available ventilation modes
     */
    getVentilationModes(): Observable<VentilationMode[]> {
        return this.store.select((state: State) => state.terminology.ventilationModes.rawEntities);
    }

    /**
     * Get available ventilation modes map
     */
    getVentilationModesMap(): Observable<EntityMap<VentilationMode>> {
        return this.store.select((state: State) => state.terminology.ventilationModes.entities);
    }

    /**
     * Get available medication groups
     */
    getMedicationGroups(): Observable<MedicationGroup[]> {
        return this.store.select((state: State) => state.terminology.medicationGroups.rawEntities);
    }

    /**
     * Get available medication categories
     */
    getMedicationCategories(): Observable<MedicationCategory[]> {
        return this.store.select((state: State) => state.terminology.medicationCategories.rawEntities);
    }

    /**
     * Get available medication categories
     */
    getMedicationUnits(): Observable<MedicationUnit[]> {
        return this.store.select((state: State) => state.terminology.medicationUnits.rawEntities);
    }

    /**
     * Get available medication categories
     */
    getMedicationSolutions(): Observable<MedicationSolution[]> {
        return this.store.select((state: State) => state.terminology.medicationSolutions.rawEntities);
    }
    /**
     * Get available medication categories mao
     */
    getMedicationCategoriesMap(): Observable<EntityMap<MedicationCategory>> {
        return this.store.select((state: State) => state.terminology.medicationCategories.entities);
    }

    /**
     * Get available procedure categories
     */
    getProcedureCategories(): Observable<ProcedureCategory[]> {
        return this.store.select((state: State) => state.terminology.procedureCategories.rawEntities);
    }

    /**
     * Get available procedure categories map
     */
    getProcedureCategoriesMap(): Observable<EntityMap<ProcedureCategory>> {
        return this.store.select((state: State) => state.terminology.procedureCategories.entities);
    }

    /**
     * Get load lab value types async action state
     */
    getLoadLabValueTypesAction(): Observable<AsyncActionState<LabValueType[]>> {
        return this.store.select((state: State) => state.terminology.labValueTypes.loadAction);
    }

    /**
     * Get available lab value types
     */
    getLabValueTypes(): Observable<LabValueType[]> {
        return this.store.select((state: State) => state.terminology.labValueTypes.rawEntities);
    }

    /**
     * Get available lab value types map
     */
    getLabValueTypesMap(): Observable<EntityMap<LabValueType>> {
        return this.store.select((state: State) => state.terminology.labValueTypes.entities);
    }

    /**
     * Get available prescription frequencies
     */
    getPrescriptionFrequencies(): Observable<PrescriptionFrequency[]> {
        return this.store.select((state: State) => state.terminology.prescriptionFrequencies.rawEntities);
    }

    /**
     * Get available prescription frequencies map
     */
    getPrescriptionFrequenciesMap(): Observable<EntityMap<PrescriptionFrequency>> {
        return this.store.select((state: State) => state.terminology.prescriptionFrequencies.entities);
    }

    /**
     * Get available prescription frequency times
     */
    getPrescriptionFrequencyTimes(): Observable<PrescriptionFrequencyTime[]> {
        return this.store.select((state: State) => state.terminology.prescriptionFrequencyTimes.rawEntities);
    }

    /**
     * Get available medication administration methods
     */
    getMedicationAdministrationMethods(): Observable<MedicationAdministrationMethod[]> {
        return this.store.select((state: State) => state.terminology.medicationAdministrationMethods.rawEntities);
    }

    /**
     * Get available medication administration methods map
     */
    getMedicationAdministrationMethodsMap(): Observable<EntityMap<MedicationAdministrationMethod>> {
        return this.store.select((state: State) => state.terminology.medicationAdministrationMethods.entities);
    }

    /**
     * Get load medications async action state
     */
    getLoadMedicationsAction(): Observable<AsyncActionState<Medication[]>> {
        return this.store.select((state: State) => state.terminology.medications.loadAction);
    }

    /**
     * Get available medications
     */
    getMedications(): Observable<Medication[]> {
        return this.store.select((state: State) => state.terminology.medications.rawEntities);
    }

    /**
     * Get available medications map
     */
    getMedicationsMap(): Observable<EntityMap<Medication>> {
        return this.store.select((state: State) => state.terminology.medications.entities);
    }

    /**
     * Get load output factor types async action state
     */
    getLoadOutputFactorTypesAction(): Observable<AsyncActionState<OutputFactor[]>> {
        return this.store.select((state: State) => state.terminology.outputFactors.loadAction);
    }

    /**
     * Get output factors
     */
    getOutputFactors(): Observable<OutputFactor[]> {
        return this.store.select((state: State) => state.terminology.outputFactors.rawEntities);
    }

    /**
     * Get available output factors map
     */
    getOutputFactorsMap(): Observable<EntityMap<OutputFactor>> {
        return this.store.select((state: State) => state.terminology.outputFactors.entities);
    }

    /**
     * Get available basic care procedure types
     */
    getBasicCareProcedureTypes(): Observable<BasicCareProcedureType[]> {
        return this.store.select((state: State) => state.terminology.basicCareProcedureTypes.rawEntities);
    }

    /**
     * Get available basic care procedure types map
     */
    getBasicCareProcedureTypesMap(): Observable<EntityMap<BasicCareProcedureType>> {
        return this.store.select((state: State) => state.terminology.basicCareProcedureTypes.entities);
    }

    /**
     * Get available practitioner shifts
     */
    getPractitionerShifts(): Observable<PractitionerShift[]> {
        return this.store.select((state: State) => state.terminology.practitionerShifts.rawEntities);
    }

    /**
     * Get available care check types
     */
    getCareCheckTypes(): Observable<CareCheckType[]> {
        return this.store.select((state: State) => state.terminology.careCheckTypes.rawEntities);
    }

    /**
     * Get blood administartion reasons
     */
    getBloodAdministrations(): Observable<BloodAdministrationType[]> {
        return this.store.select((state: State) => state.terminology.bloodAdministrationTypes.rawEntities);
    }

    /**
     * Get available discharge reasons
     */
    getDischargeReasons(): Observable<DischargeReason[]> {
        return this.store.select((state: State) => state.terminology.dischargeReasons.rawEntities);
    }

    /**
     * Get prescription not given reasons
     */
    getPrescriptionNotGivenReasons(): Observable<PrescriptionNotGivenReason[]> {
        return this.store.select((state: State) => state.terminology.prescriptionNotGivenReason.rawEntities);
    }

    /**
     * Get vaccine codes
     */
    getVaccineCodes(): Observable<VaccineCode[]> {
        return this.store.select((state: State) => state.terminology.vaccineCodes);
    }

    /**
     * Get available additional device types
     */
    getAdditionalDeviceTypes(): Observable<AdditionalDeviceType[]> {
        return this.store.select((state: State) => state.terminology.additionalDeviceTypes.rawEntities);
    }

    /**
     * Get additional device types map
     */
    getAdditionalDeviceTypesMap(): Observable<EntityMap<AdditionalDeviceType>> {
        return this.store.select((state: State) => state.terminology.additionalDeviceTypes.entities);
    }

    /**
     * Get available dosage forms
     */
    getDosageForms(): Observable<MedicationDosageForm[]> {
        return this.store.select((state: State) => state.terminology.dosageForms.rawEntities);
    }

    /**
     * Get glasgow coma scale dataflows values
     */
    getGlasgowComaScaleDataflows(): Observable<GlasgowComaScaleDataFlowsValues> {
        return this.store.select((state: State) => state.terminology.glasgowComaScaleValues);
    }

    /**
     * Get task list shift filters
     */
    getTaskListShiftFilters(): Observable<TaskListFilter[]> {
        return this.store.select((state: State) => state.terminology.taskListShiftFilters);
    }

    //#endregion

    //#region actions

    /**
     * Searches for a diagnosis name
     *
     * @param term Search term
     */
    searchDiagnosis(term: string) {
        this.store.dispatch(TerminologyActions.searchDiagnosisAction.action({ term }));
    }

    /**
     * Clears the search results for diagnoses
     */
    clearSearchDiagnoses() {
        this.store.dispatch(TerminologyActions.searchDiagnosisAction.clearAction());
    }

    /**
     * Searches for a lab value type
     *
     * ℹ️ DIRECT API CALL, SKIP UNNECESSARY NGRX
     *
     * @param name Search term
     */
    searchLabValueTypes(name: string): Observable<LabValueType[]> {
        return this.terminologyApiService.getLabValueTypes({ name });
    }

    /**
     * Loads vital sign types if not loaded
     *
     * @deprecated use {@link loadVitalSignTypes}
     */
    loadVitalSignTypesIfNotLoaded(): void {
        this.getLoadVitalSignTypesAction()
            .pipe(take(1))
            .subscribe(action => {
                if (!action.finished && !action.inProgress) {
                    this.store.dispatch(TerminologyActions.loadVitalSignTypesAction.action({}));
                }
            });
    }

    /**
     * Loads vital sign types
     *
     * @param params
     */
    loadVitalSignTypes(params: any): void {
        this.store.dispatch(TerminologyActions.loadVitalSignTypesAction.action({ params }));
    }

    /**
     * Loads ventilation parameter types if not loaded
     *
     * @deprecated use {@link loadVentilationParameterTypes}
     */
    loadVentilationParameterTypesIfNotLoaded(): void {
        this.getLoadVentilationParameterTypesAction()
            .pipe(take(1))
            .subscribe(action => {
                if (!action.finished && !action.inProgress) {
                    this.store.dispatch(TerminologyActions.loadVentilationParameterTypesAction.action({}));
                }
            });
    }

    /**
     * Loads ventilation parameter types
     *
     * @param params
     */
    loadVentilationParameterTypes(params: any = {}): void {
        this.store.dispatch(TerminologyActions.loadVentilationParameterTypesAction.action({ params }));
    }

    /**
     * Loads ventilation modes if not loaded
     *
     * @deprecated use {@link loadVentilationModes}
     */
    loadVentilationModesIfNotLoaded(): void {
        this.getLoadVentilationModesAction()
            .pipe(take(1))
            .subscribe(action => {
                if (!action.finished && !action.inProgress) {
                    this.store.dispatch(TerminologyActions.loadVentilationModesAction.action({}));
                }
            });
    }

    /**
     * Loads ventilation modes
     *
     * @param params
     */
    loadVentilationModes(params: any = {}): void {
        this.store.dispatch(TerminologyActions.loadVentilationModesAction.action({ params }));
    }

    /**
     * Loads ventilation parameter types
     *
     * @param params
     */
    loadLabValueTypes(params: any = {}): void {
        this.store.dispatch(TerminologyActions.loadLabValueTypesAction.action({ params }));
    }

    /**
     * Loads medication groups
     */
    loadMedicationGroups(): void {
        this.store.dispatch(TerminologyActions.loadMedicationGroupsAction.action());
    }

    /**
     * Loads medication categories
     */
    loadMedicationCategories(): void {
        this.store.dispatch(TerminologyActions.loadMedicationCategoriesAction.action());
    }

    /**
     * Loads medication categories
     */
    loadMedicationUnits(): void {
        this.store.dispatch(TerminologyActions.loadMedicationUnitsAction.action());
    }

    /**
     * Loads medication categories
     */
    loadMedicationSolutions(): void {
        this.store.dispatch(TerminologyActions.loadMedicationSolutionsAction.action());
    }

    /**
     * Loads procedure categories
     */
    loadProcedureCategories(): void {
        this.store.dispatch(TerminologyActions.loadProcedureCategoriesAction.action());
    }

    /**
     * Loads prescription frequencies
     *
     * @param codes string[]
     */
    loadPrescriptionFrequencies(codes?: string[]): void {
        this.store.dispatch(TerminologyActions.loadPrescriptionFrequenciesAction.action({ codes }));
    }

    /**
     * Loads medication administration methods
     *
     * @param codes string[]
     */
    loadMedicationAdministrationMethods(codes?: string[]): void {
        this.store.dispatch(TerminologyActions.loadMedicationAdministrationMethodsAction.action({ codes }));
    }

    /**
     * Loads task list shift filters
     */
    loadTaskListShiftFilters(): void {
        this.store.dispatch(TerminologyActions.loadTaskListShiftFiltersAction.action());
    }

    /**
     * clears task list shift filters
     */
    clearTaskListShiftFilters(): void {
        this.store.dispatch(TerminologyActions.loadTaskListShiftFiltersAction.clearAction());
    }

    /**
     * Create medication
     *
     * @param medication Medication
     * @param createAs
     */
    createMedication(
        medication: Pick<Medication, 'categoryCode' | 'displayName'>,
        createAs: 'medications' | 'medication-groups' = 'medications',
    ) {
        this.store.dispatch(
            TerminologyActions.createMedicationAction.action({
                medication: medication as any,
                createAs,
            }),
        );

        return this.store
            .select(state => state.terminology.createMedicationAction)
            .pipe(
                filter(state => !state.inProgress && !!state.result),
                map(state => state.result),
            );
    }

    /**
     * Searches for a medication
     *
     * @param name Search term
     * @param searchBy
     */
    searchMedicationsByName(name: string, searchBy?: 'medications' | 'medication-groups'): Observable<Medication[]> {
        // Trigger search
        this.store.dispatch(TerminologyActions.searchMedicationsAction.action({ name, searchBy }));

        return this.store
            .select(state => state.terminology.searchMedicationsAction)
            .pipe(
                filter(state => !state.inProgress && !!state.result),
                map(state => state.result!),
            );
    }

    /**
     * Searches for a medication
     *
     * ℹ️ DIRECT API CALL, SKIP UNNECESSARY NGRX
     *
     * @param name
     * @param groupId
     * @param categoryCode
     */
    searchMedicationsApiByParams(name?: string, groupId?: string, categoryCode?: string): Observable<Medication[]> {
        const searchParams = { name, group_id: groupId, category_code: categoryCode };
        return this.terminologyApiService.searchMedicationsByParams(searchParams);
    }

    /**
     * Clears the medication search
     */
    clearSearchMedications() {
        this.store.dispatch(TerminologyActions.searchMedicationsAction.clearAction());
    }

    /**
     * Clears the medication creation action
     */
    clearCreateMedication() {
        this.store.dispatch(TerminologyActions.createMedicationAction.clearAction());
    }

    /**
     * Ensures that a list od medication codes is loaded
     *
     * @param codes string[]
     */
    loadMedications(codes: string[]) {
        this.store.dispatch(TerminologyActions.loadMedicationsAction.action({ codes }));
    }

    /**
     * Load output factor types
     */
    loadOutputFactors(): void {
        this.store.dispatch(TerminologyActions.loadOutputFactorsAction.action());
    }

    /**
     * Load prescription frequency times
     */
    loadPrescriptionFrequencyTimes(): void {
        this.store.dispatch(TerminologyActions.loadPrescriptionFrequencyTimesAction.action());
    }

    /**
     * Load basic care procedure types
     */
    loadBasicCareProcedureTypes(): void {
        this.store.dispatch(TerminologyActions.loadBasicCareProcedureTypesAction.action());
    }

    /**
     * Load practitioner shifts
     */
    loadPractitionerShifts(): void {
        this.store.dispatch(TerminologyActions.loadPractitionerShiftsAction.action());
    }

    /**
     * Load care check types
     *
     * @param params
     */
    loadCareCheckTypes(params?: any): void {
        this.store.dispatch(TerminologyActions.loadCareCheckTypesAction.action({ params }));
    }

    /**
     * Load blood administration types
     */
    loadBloodAdministrationTypes(): void {
        this.store.dispatch(TerminologyActions.loadBloodAdministrationTypesAction.action());
    }

    /**
     * Dispatch load all discharge reasons
     *
     */
    loadDischargeReasons(): void {
        this.store.dispatch(TerminologyActions.loadDischargeReasonsAction.action());
    }

    /**
     * Clear discharge reasons
     */
    clearDischargeReasons(): void {
        this.store.dispatch(TerminologyActions.loadDischargeReasonsAction.clearAction());
    }

    /**
     * Dispatch load all prescription not given reasons
     *
     */
    loadPrescriptionNotGivenReasons(): void {
        this.store.dispatch(TerminologyActions.loadPrescriptionNotGivenReasonAction.action());
    }

    /**
     * Dispatch load all vaccine codes
     *
     */
    loadVaccineCodes(): void {
        this.store.dispatch(TerminologyActions.loadVaccineCodesAction.action());
    }

    /**
     * Dispatch load all vaccine codes
     *
     */
    loadRelationshipRoleTypes(): void {
        this.store.dispatch(TerminologyActions.loadRelationshipRoleTypesAction.action());
    }

    /**
     * Dispatch load all vaccine codes
     *
     */
    loadAdditionalDevicesTypes(): void {
        this.store.dispatch(TerminologyActions.loadAdditionalDevicesTypesAction.action());
    }

    /**
     * Load dosage forms
     */
    loadDosageForms(): void {
        this.store.dispatch(TerminologyActions.loadDosageFormsAction.action());
    }

    /**
     * Load output factor types
     */
    loadDailyGoalsTerminology(): void {
        this.store.dispatch(TerminologyActions.loadDailyGoalsTerminologyAction.action());
    }

    /**
     * Load glasgow come scale values
     */
    loadGlasgowComaScaleValues(): void {
        this.store.dispatch(TerminologyActions.loadGlasgowComaScaleAction.action());
    }

    /**
     * Load terminology needed to display a prescription information
     */
    loadPrescriptionTerminology(): void {
        this.loadProcedureCategories();
        this.loadMedicationCategories();
        this.loadMedicationUnits();
        this.loadMedicationAdministrationMethods();
        this.loadMedicationSolutions();
        this.loadPrescriptionFrequencies();
        this.loadPrescriptionFrequencyTimes();
        this.loadDosageForms();
        this.loadBloodAdministrationTypes();
    }

    //#endregion
}
