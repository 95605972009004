import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ReportLocation } from '@mona/models';
import { ReportLocationsState } from '../../entities';
import * as ReportsActions from '../actions/reports.actions';

/**  EntityAdapter<Location> */
export const reportLocationsAdapter: EntityAdapter<ReportLocation> = createEntityAdapter<ReportLocation>({
    selectId: (location: ReportLocation) => location.value,
});

const initialState: ReportLocationsState = reportLocationsAdapter.getInitialState({
    error: null,
    isLoading: false,
});

export const locationsReducer = createReducer(
    initialState,
    on(ReportsActions.loadReportsExportLocations, (state, action) => ({ ...state, error: null, isLoading: true })),
    on(ReportsActions.loadReportsExportLocationsFailure, (state, { error }) => ({ ...state, error, isLoading: false })),
    on(ReportsActions.loadReportsExportLocationsSuccess, (state, action) => ({
        ...reportLocationsAdapter.setAll(action.locations, state),
        error: null,
        isLoading: false,
    })),
    on(ReportsActions.clearLocations, state => reportLocationsAdapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = reportLocationsAdapter.getSelectors();
