// =================================================================================================
// Mona Core API
// The version of the OpenAPI document: https://core.qa240.stage.monatest.icu/auto-open-api-spec.yaml 1.6.65
// Contact: https://www.clinomic.biz
//
// NOTE: This class is auto generated at 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
// by OpenAPI Generator https://openapi-generator.tech
// Do not edit the class manually.
// =================================================================================================

import camelcaseKeys from 'camelcase-keys';
import { instanceToPlain, plainToInstance, Type, Transform } from 'class-transformer';
import snakecaseKeys, { SnakeCaseKeys } from 'snakecase-keys';
import { TransformDate } from '../helpers';

export interface CodeSystemConfig {
    /**
     * Canonical url of the code system
     *
     */
    canonical_url: string;
    /**
     * Version of the code system
     *
     */
    system_version: string | null;
    /**
     * Singular name of the code system values
     *
     */
    display_name_singular: string;
    /**
     * Plural name of the code system values
     *
     */
    display_name_plural: string;
}
// -----------------------------------------------------------------------------
// Frontend Model
// NOTE: This class is auto generated by OpenAPI Generator
// Do not edit the class manually.
// -----------------------------------------------------------------------------

/**
 * Namespace that holds Frontend models/classes for CodeSystemConfig
 */
export namespace CodeSystemConfig {
    /**
     * CodeSystemConfig Frontend Model with camelCase properties
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class Model {
        /**
         * Canonical url of the code system
         * @type string (`dataFormat` is missing)
         */
        canonicalUrl: string;
        /**
         * Version of the code system
         * @type string (`dataFormat` is missing)
         */
        systemVersion: string | null;
        /**
         * Singular name of the code system values
         * @type string (`dataFormat` is missing)
         */
        displayNameSingular: string;
        /**
         * Plural name of the code system values
         * @type string (`dataFormat` is missing)
         */
        displayNamePlural: string;

        /** @hideconstructor */
        constructor(obj: Model) {
            Object.assign(this, camelcaseKeys(obj, { deep: true }));
        }
    }
    /**
     * CodeSystemConfig DTO
     * Transforms CodeSystemConfig model from/to API object
     * @since 2024-11-26T18:10:41.601818+02:00[Europe/Kiev]
     * @version 1.6.65
     *
     * NOTE: This class is auto generated by OpenAPI Generator
     * Do not edit the class manually.
     */
    export class DTO {
        /** Transforms to Frontend CodeSystemConfig model */
        static toModel(obj: CodeSystemConfig): Model {
            const newObj = camelcaseKeys(obj, { deep: true });
            return plainToInstance(Model, newObj);
        }
        /** Transforms to Backend CodeSystemConfig model */
        static toApi(obj: Model): CodeSystemConfig {
            const newObj = instanceToPlain(plainToInstance(Model, obj)) as CodeSystemConfig;
            return snakecaseKeys(newObj, { deep: true });
        }
    }
}
