import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { differenceInHours, isValid as isValidDate } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
    getBestMotorResponseOptions,
    getBestVerbalResponseOptions,
    getOpenEyesOptions,
    GlasgowComaScaleDataFlowsValues,
    Practitioner,
} from '@mona/models';
import { notEmpty } from '@mona/shared/utils';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { TerminologyService } from '../../application';
import { TerminologyState, TerminologyStateKeys } from '../../entities';
import { TerminologyApi } from '../../infrastructure';
import { setTerminologyLoadedSuccess, TerminologyActions } from '../actions/terminologyActions';

/**
 * Terminology effects
 */
@Injectable({
    providedIn: 'root',
})
export class TerminologyEffects {
    /**
     * Search diagnosis effect
     */

    searchDiagnosis$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.searchDiagnosisAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.searchDiagnosis(action.term),
                    TerminologyActions.searchDiagnosisAction,
                ),
            ),
        ),
    );

    /**
     * Load vital sign types effect
     */

    loadVitalSignTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadVitalSignTypesAction.action),
            this.getStoredEntity('vitalSignTypes'),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getVitalSignTypes(action.params),
                    TerminologyActions.loadVitalSignTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load lab value types effect
     */

    loadLabValueTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadLabValueTypesAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getLabValueTypes(action.params),
                    TerminologyActions.loadLabValueTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load lab value types effect
     */

    searchLabValueTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.searchLabValueTypesAction.action),
            switchMap(({ name, codes }) =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getLabValueTypes({ name, codes }),
                    TerminologyActions.searchLabValueTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load lab value types effect
     */

    searchLabValueTypesSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.searchLabValueTypesAction.succeededAction),
            switchMap(action => {
                return this.terminologyService.getLabValueTypes().pipe(
                    map(types => {
                        const additionalTypes = action.payload.filter(
                            (searchResult: any) => !types.find(t => t.code === searchResult.code),
                        );
                        return [...types, ...additionalTypes];
                    }),
                    take(1),
                );
            }),
            switchMap(types => {
                return [TerminologyActions.loadLabValueTypesAction.succeededAction({ payload: types })];
            }),
        ),
    );

    /**
     * Load ventilation parameter types effect
     */

    loadVentilationParameterTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadVentilationParameterTypesAction.action),
            this.getStoredEntity('ventilationParameterTypes'),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getVentilationParameterTypes(action.params),
                    TerminologyActions.loadVentilationParameterTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load ventilation modes effect
     */

    loadVentilationModes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadVentilationModesAction.action),
            this.getStoredEntity('ventilationModes'),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getVentilationModes(action.params),
                    TerminologyActions.loadVentilationModesAction,
                ),
            ),
        ),
    );

    /**
     * Load medication categories effect
     */

    loadMedicationCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationCategoriesAction.action),
            this.getStoredEntity('medicationCategories'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationCategories(),
                    TerminologyActions.loadMedicationCategoriesAction,
                ),
            ),
        ),
    );

    /**
     * Load medication Groups effect
     */

    loadMedicationGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationGroupsAction.action),
            this.getStoredEntity('medicationGroups'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationGroups(),
                    TerminologyActions.loadMedicationGroupsAction,
                ),
            ),
        ),
    );

    /**
     * Load medication categories effect
     */
    loadMedicationUnits$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationUnitsAction.action),
            this.getStoredEntity('medicationUnits'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationUnits(),
                    TerminologyActions.loadMedicationUnitsAction,
                ),
            ),
        ),
    );
    /**
     * Load medication categories effect
     */

    loadMedicationSolutions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationSolutionsAction.action),
            this.getStoredEntity('medicationSolutions'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationSolutions(),
                    TerminologyActions.loadMedicationSolutionsAction,
                ),
            ),
        ),
    );

    /**
     * Load procedure categories effect
     */

    loadProcedureCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadProcedureCategoriesAction.action),
            this.getStoredEntity('procedureCategories'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getProcedureCategories(),
                    TerminologyActions.loadProcedureCategoriesAction,
                ),
            ),
        ),
    );

    /**
     * Load prescription frequencies effect
     */

    loadPrescriptionFrequencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadPrescriptionFrequenciesAction.action),
            this.getStoredEntity('prescriptionFrequencies'),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getPrescriptionFrequencies(action.codes),
                    TerminologyActions.loadPrescriptionFrequenciesAction,
                ),
            ),
        ),
    );

    /**
     * Load medication administration methods effect
     */

    loadMedicationAdministrationMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationAdministrationMethodsAction.action),
            this.getStoredEntity('medicationAdministrationMethods'),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationAdministrationMethods(action.codes),
                    TerminologyActions.loadMedicationAdministrationMethodsAction,
                ),
            ),
        ),
    );

    /**
     * Create medication
     */

    createMedication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.createMedicationAction.action),
            concatLatestFrom(() => this.store.select((state: any) => state.auth.user as Practitioner)),
            switchMap(([action, user]) =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.createMedication(
                        { ...action.medication, lastChangedBy: user.id },
                        action.createAs,
                    ),
                    TerminologyActions.createMedicationAction,
                ),
            ),
        ),
    );

    /**
     * Load medications effect
     */

    loadMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadMedicationsAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi
                        .getMedications(undefined, action.codes)
                        .pipe(map(types => types.filter(type => action.codes.includes(type.code)))),
                    TerminologyActions.loadMedicationsAction,
                ),
            ),
        ),
    );

    /**
     * Load medications effect
     */

    searchMedications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.searchMedicationsAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getMedicationsSuggestions(action.name, action.searchBy),
                    TerminologyActions.searchMedicationsAction,
                ),
            ),
        ),
    );

    /**
     * Create medications succeeded effect
     * After createMedicationAction success we should dispatch loadMedicationsAction success
     */

    createMedicationSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.createMedicationAction.succeededAction),
            switchMap(action => {
                return this.terminologyService.getMedications().pipe(
                    map(medications => {
                        // Extend store medications with newly created
                        // medication in order to dispatch new succeeded load
                        // medications action with new extended medications
                        // list as payload to update the store
                        return [...medications, action.payload];
                    }),
                    take(1),
                );
            }),
            switchMap(medications => {
                return [TerminologyActions.loadMedicationsAction.succeededAction({ payload: medications })];
            }),
        ),
    );

    /**
     * Load output factors effect
     */

    loadOutputFactors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadOutputFactorsAction.action),
            this.getStoredEntity('outputFactors'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getOutputFactors(),
                    TerminologyActions.loadOutputFactorsAction,
                ),
            ),
        ),
    );

    /**
     * Load prescription frequency times effect
     */

    loadPrescriptionFrequencyTimes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadPrescriptionFrequencyTimesAction.action),
            this.getStoredEntity('prescriptionFrequencyTimes'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getPrescriptionFrequencyTimes(),
                    TerminologyActions.loadPrescriptionFrequencyTimesAction,
                ),
            ),
        ),
    );

    /**
     * Load basic care procedure types effect
     */

    loadBasicCareProcedureTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadBasicCareProcedureTypesAction.action),
            this.getStoredEntity('basicCareProcedureTypes'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getBasicCareProcedureTypes(),
                    TerminologyActions.loadBasicCareProcedureTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load practitioner shifts effect
     */

    loadPractitionerShifts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadPractitionerShiftsAction.action),
            this.getStoredEntity('practitionerShifts'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getPractitionerShifts(),
                    TerminologyActions.loadPractitionerShiftsAction,
                ),
            ),
        ),
    );

    /**
     * Load care check types effect
     */

    loadCareCheckTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadCareCheckTypesAction.action),
            this.getStoredEntity('careCheckTypes'),
            switchMap(({ params }) =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getCareCheckTypes(params),
                    TerminologyActions.loadCareCheckTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load blood administration types effect
     */

    loadBloodAdministrationTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadBloodAdministrationTypesAction.action),
            this.getStoredEntity('bloodAdministrationTypes'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getBloodAdministrationTypes(),
                    TerminologyActions.loadBloodAdministrationTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load discharge reasons list effect
     */
    loadDischargeReasonList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadDischargeReasonsAction.action),
            this.getStoredEntity('dischargeReasons'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getDischargeReasonsList(),
                    TerminologyActions.loadDischargeReasonsAction,
                ),
            ),
        ),
    );

    /**
     * Load glasgow coma scale values effect
     */
    loadGlasgowComaScaleValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadGlasgowComaScaleAction.action),
            switchMap(() =>
                // TODO: Change to real API call
                makeDefaultAsyncActionEffect(
                    of({
                        openEyesOptions: getOpenEyesOptions(),
                        bestVerbalResponseOptions: getBestVerbalResponseOptions(),
                        bestMotorResponseOptions: getBestMotorResponseOptions(),
                    } as GlasgowComaScaleDataFlowsValues),
                    TerminologyActions.loadGlasgowComaScaleAction,
                ),
            ),
        ),
    );

    /**
     * Load glasgow coma scale values effect
     */
    loadDailyGoalsTerminologyValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadDailyGoalsTerminologyAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getDailyGoalsTerminology(),
                    TerminologyActions.loadDailyGoalsTerminologyAction,
                ),
            ),
        ),
    );

    /**
     * Load dosage forms effect
     */
    loadDosageForms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadDosageFormsAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getDosageForms(),
                    TerminologyActions.loadDosageFormsAction,
                ),
            ),
        ),
    );

    /**
     * Load task list shifts filters
     */
    loadTaskListShiftsFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadTaskListShiftFiltersAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getTaskListShiftFilters(),
                    TerminologyActions.loadTaskListShiftFiltersAction,
                ),
            ),
        ),
    );

    /**
     * Load prescription not given reasons
     */
    loadPrescriptionNotGivenReasons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadPrescriptionNotGivenReasonAction.action),
            this.getStoredEntity('prescriptionNotGivenReason'),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getPrescriptionNotGivenReason(),
                    TerminologyActions.loadPrescriptionNotGivenReasonAction,
                ),
            ),
        ),
    );

    /**
     * Load vaccine codes
     */
    loadVaccineCodes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadVaccineCodesAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getVaccineCodes(),
                    TerminologyActions.loadVaccineCodesAction,
                ),
            ),
        ),
    );

    /**
     * Load vaccine codes
     */
    loadRelationshipRoleType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadRelationshipRoleTypesAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getRelationshipRoleTypes(),
                    TerminologyActions.loadRelationshipRoleTypesAction,
                ),
            ),
        ),
    );

    /**
     * Load additional devices
     */
    loadAdditionalDevicesType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TerminologyActions.loadAdditionalDevicesTypesAction.action),
            switchMap(() =>
                makeDefaultAsyncActionEffect(
                    this.terminologyApi.getAdditionalDevicesTypes(),
                    TerminologyActions.loadAdditionalDevicesTypesAction,
                ),
            ),
        ),
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param terminologyApi TerminologyApi
     * @param terminologyService TerminologyService
     */
    constructor(
        private store: Store<{ terminology: TerminologyState }>,
        private actions$: Actions,
        private terminologyApi: TerminologyApi,
        private terminologyService: TerminologyService,
    ) {}

    /**
     * Get Stored Entity
     *
     * @description Custom operator to take Terminology from the {@link TerminologyState} if they already loaded
     * and to be able to re-fetch them after some amount of time, {@link baseEnvironment.terminologyRefreshTime} be default 1 hour
     * @param terminologyName
     */
    private getStoredEntity<T>(terminologyName: TerminologyStateKeys) {
        return (source: Observable<T>): Observable<T> =>
            source.pipe(
                concatLatestFrom(() => [
                    this.store.select(state => state.terminology[terminologyName]),
                    this.store.select(state => state.terminology.lastUpdatedAt),
                ]),
                map(([action, entity, lastUpdatedAt]) => {
                    if (!isValidDate(lastUpdatedAt)) {
                        return action;
                    }
                    if (
                        differenceInHours(new Date(), lastUpdatedAt) >= environment.terminologyRefreshTime ||
                        !entity.rawEntities.length
                    ) {
                        return action;
                    }
                    this.store.dispatch(setTerminologyLoadedSuccess({ terminologyName }));
                    return null;
                }),
                notEmpty(),
            );
    }
}
